import React from "react";
import ButtonWidget from "../../../../CommonComponents/UI/ButtonWidget/ButtonWidget";
import { ContentExplorerViewModel } from "../ContentExplorerViewModel";

interface Props {
    viewModel: ContentExplorerViewModel
}

export default class ContentExplorerFilterModalWidget extends React.Component<Props> {
    render() {  
        const viewModel = this.props.viewModel;

        return <>
            {
                viewModel.isFilterModalShown &&
                <div className="SemiTransparentBackground" onClick={() => viewModel.changeShowFilterModalStatus(false) }/>
            }
            <div id="ContentExplorerContentModalContainer" className={viewModel.showModalStatus}>
                <div>
                    <p>Filter</p>
                    <button
                        onClick={() => {
                            viewModel.emit(() => {
                                if (viewModel.tempFilterBasedOnProductModel) {
                                    viewModel.tempFilterBasedOnProductModel.components.forEach(
                                        (component) => component.options.forEach(
                                            (option) => option.isSelected = false 
                                        )
                                    );
                                }
                            })
                        }}
                    >Reset</button>
                </div>
                <div>
                    {
                        viewModel.tempFilterBasedOnProductModel &&
                        viewModel.tempFilterBasedOnProductModel.components.map((component) => 
                            <div id="ContentExplorerFilterComponentContainer">
                                <p>{component.title}</p>
                                <div>
                                    {
                                        component.options.map((option) => 
                                            <button 
                                                className={option.isSelected ? "SelectedTicker" : "UnselectedTicker"}
                                                onClick={() => viewModel.emit(() => option.isSelected = !option.isSelected)}
                                            >
                                                {option.title}
                                            </button>
                                        )
                                    }
                                </div>
                            </div>
                        )
                    }
                </div>
                <div>
                    <ButtonWidget 
                        width={"calc(50% - 8px)"} 
                        backgroundColor="transparent" 
                        titleColor="#585b63" 
                        borderColor="#585b63"
                        action={() => viewModel.changeShowFilterModalStatus(false)}
                    >
                        Cancel
                    </ButtonWidget>
                    <ButtonWidget 
                        width={"calc(50% - 8px)"}
                        action={() => {
                            viewModel.changeShowFilterModalStatus(false);
                            viewModel.saveTempFilterModel();
                        }}
                        isEnabled={viewModel.filterBasedOnProductModel !== viewModel.tempFilterBasedOnProductModel}
                    >
                        Apply
                    </ButtonWidget>
                </div>
            </div>
        </>
    }
}