const potentialProducts = [
  {
    id: 139156,
    resellerNoCommissionMessage: null,
    resellerPriceInfo: [],
    resellerPrice: null,
    restockAttributes: [],
    productImageUrls: [
      "https://images.thefepi.com/files/1/products/files/files/139156/NWR230821_203.jpg",
      "https://images.thefepi.com/files/1/products/files/files/139156/NWR230821_209.jpg",
      "https://images.thefepi.com/files/1/products/files/files/139156/NWR230821_204.jpg",
      "https://images.thefepi.com/files/1/products/files/files/139156/NWR230821_207.jpg",
      "https://images.thefepi.com/files/1/products/files/files/139156/NWR230821_205.jpg",
      "https://images.thefepi.com/files/1/products/files/files/139156/NWR230821_208.jpg",
      "https://images.thefepi.com/files/1/products/files/files/139156/NWR230821_206.jpg",
    ],
    sellerProfile: null,
    isReseller: false,
    productName: "MLB White on Black New York Yankees Ball Cap",
    brand: {
      imageUrl: "https://images.thefepi.com/file/brand_all/LOGO-BRAND-NEW-ERA.png",
      name: "New Era",
      id: "283",
    },
    isNew: false,
    isSoldOut: false,
    badgeUrls: [
      "https://images.app.thefepi.com/app/contents/badge-preorder-2x.jpg.webp",
      "https://images.app.thefepi.com/app/contents/badge-new-2x.jpg.webp",
      "https://images.app.thefepi.com/app/contents/badge-unisex-2x.jpg.webp",
    ],
    price: {
      netPrice: 760000,
      discount: 0,
      realPrice: 0,
    },
    commission: {
      rate: 6, // Specific value between 5 and 10 as a number
      nominal: 45600, // Calculated as netPrice * 6 / 100 and represented as a number
    },
    views: "2.3k", // Randomized between 1k and 4k views
    buys: 460, // Randomized between 10% to 20% of views
  },
  {
    id: 112003,
    resellerNoCommissionMessage: null,
    resellerPriceInfo: [],
    resellerPrice: null,
    restockAttributes: [],
    productImageUrls: [
      "https://images.thefepi.com/files/1/products/files/files/112003/MLB220311_001.jpg",
      "https://images.thefepi.com/files/1/products/files/files/112003/MLB220311_005.jpg",
      "https://images.thefepi.com/files/1/products/files/files/112003/MLB220311_003.jpg",
      "https://images.thefepi.com/files/1/products/files/files/112003/MLB220311_004.jpg",
      "https://images.thefepi.com/files/1/products/files/files/112003/MLB220311_006.jpg",
      "https://images.thefepi.com/files/1/products/files/files/112003/MLB220311_002.jpg",
    ],
    sellerProfile: null,
    isReseller: false,
    productName: "Monogram Jacquard Hobo Bag New York Yankees (3ABQS012N-50BKS)",
    brand: {
      imageUrl: "https://images.thefepi.com/file/brand_all/MLB.png",
      name: "MLB",
      id: "198",
    },
    isNew: false,
    isSoldOut: false,
    badgeUrls: [
      "https://images.app.thefepi.com/app/contents/badge-preorder-2x.jpg.webp",
      "https://images.app.thefepi.com/app/contents/badge-new-2x.jpg.webp",
      "https://images.app.thefepi.com/app/contents/badge-unisex-2x.jpg.webp",
    ],
    price: {
      netPrice: 1393000,
      discount: 30,
      realPrice: 1990000,
    },
    commission: {
      rate: 6, // Randomized between 5 and 10 as a number
      nominal: 83580, // Calculated as netPrice * rate / 100 and represented as a number
    },
    views: "2.75k", // Randomized between 1k and 4k views
    buys: 550, // Randomized between 10% to 20% of views
  },
  {
    id: 126943,
    resellerNoCommissionMessage: null,
    resellerPriceInfo: [],
    resellerPrice: null,
    restockAttributes: [],
    productImageUrls: [
      "https://images.thefepi.com/files/1/products/files/files/126943/LW1.jpg",
      "https://images.thefepi.com/files/1/products/files/files/126943/LW2.jpg",
      "https://images.thefepi.com/files/1/products/files/files/126943/LW4.jpg",
      "https://images.thefepi.com/files/1/products/files/files/126943/LW3.jpg",
      "https://images.thefepi.com/files/1/products/files/files/126943/LW5.jpg",
      "https://images.thefepi.com/files/1/products/files/files/126943/LW6.jpg",
    ],
    sellerProfile: null,
    isReseller: false,
    productName: "Big Radog Short Sleeve T-shirt",
    brand: {
      imageUrl: "https://images.thefepi.com/file/brand_all/Logo-Lifework-1.png",
      name: "LifeWork",
      id: "276",
    },
    isNew: false,
    isSoldOut: false,
    badgeUrls: ["https://images.app.thefepi.com/app/contents/badge-unisex-2x.jpg.webp"],
    price: {
      netPrice: 739000,
      discount: 0,
      realPrice: 0,
    },
    commission: {
      rate: 5, // Randomized between 5 and 10 as a number
      nominal: 36950, // Calculated as netPrice * rate / 100 and represented as a number
    },
    views: "3.2k", // Randomized between 1k and 4k views
    buys: 640, // Randomized between 10% to 20% of views
  },
  {
    id: 92086,
    resellerNoCommissionMessage: null,
    resellerPriceInfo: [],
    resellerPrice: null,
    restockAttributes: [],
    productImageUrls: [
      "https://images.thefepi.com/files/1/products/files/files/92086/ADLV_200225_313.jpg",
      "https://images.thefepi.com/files/1/products/files/files/92086/ADLV_200225_314.jpg",
      "https://images.thefepi.com/files/1/products/files/files/92086/ADLV_200225_315.jpg",
      "https://images.thefepi.com/files/1/products/files/files/92086/ADLV_200225_316.jpg",
      "https://images.thefepi.com/files/1/products/files/files/92086/ADLV_200225_317.jpg",
      "https://images.thefepi.com/files/1/products/files/files/92086/ADLV_200225_318.jpg",
    ],
    sellerProfile: null,
    isReseller: false,
    productName: "ADLV BABY FACE SHORT SLEEVE T-SHIRT BLACK DONUT 1",
    brand: {
      imageUrl: "https://images.thefepi.com/file/brand_all/adlv.png",
      name: "Acme de La Vie",
      id: "178",
    },
    isNew: false,
    isSoldOut: true,
    badgeUrls: [
      "https://images.app.thefepi.com/app/contents/badge-new-2x.jpg.webp",
      "https://images.app.thefepi.com/app/contents/badge-unisex-2x.jpg.webp",
    ],
    price: {
      netPrice: 799200,
      discount: 20,
      realPrice: 999000,
    },
    commission: {
      rate: 7, // Randomized between 5 and 10 as a number
      nominal: 55944, // Calculated as netPrice * rate / 100 and represented as a number
    },
    views: "2.75k", // Randomized between 1k and 4k views
    buys: 550, // Randomized between 10% to 20% of views
  },
  {
    id: 131120,
    resellerNoCommissionMessage: null,
    resellerPriceInfo: [],
    resellerPrice: null,
    restockAttributes: [],
    productImageUrls: [
      "https://images.thefepi.com/files/1/products/files/files/131120/MRD230330_059.jpg",
      "https://images.thefepi.com/files/1/products/files/files/131120/MRD230330_063.jpg",
      "https://images.thefepi.com/files/1/products/files/files/131120/MRD230330_062.jpg",
      "https://images.thefepi.com/files/1/products/files/files/131120/MRD230330_060.jpg",
      "https://images.thefepi.com/files/1/products/files/files/131120/MRD230330_061.jpg",
    ],
    sellerProfile: null,
    isReseller: false,
    productName: "SWEATSHIRT FLOWERMARDI BLOSSOM_NAVY WHITE",
    brand: {
      imageUrl: "https://images.thefepi.com/file/brand_all/20230411_094213_small.png",
      name: "mardi mercredi",
      id: "372",
    },
    isNew: false,
    isSoldOut: false,
    badgeUrls: [
      "https://images.app.thefepi.com/app/contents/badge-preorder-2x.jpg.webp",
      "https://images.app.thefepi.com/app/contents/badge-women-2x.jpg.webp",
    ],
    price: {
      netPrice: 1445000,
      discount: 15,
      realPrice: 1700000,
    },
    commission: {
      rate: 6, // Randomized between 5 and 10 as a number
      nominal: 86700, // Calculated as netPrice * rate / 100 and represented as a number
    },
    views: "2.5k", // Randomized between 1k and 4k views
    buys: 375, // Randomized between 10% to 20% of views
  },
  {
    id: 98868,
    resellerNoCommissionMessage: null,
    resellerPriceInfo: [],
    resellerPrice: null,
    restockAttributes: [],
    productImageUrls: [
      "https://images.thefepi.com/files/1/products/files/files/98868/31.jpg",
      "https://images.thefepi.com/files/1/products/files/files/98868/32.jpg",
      "https://images.thefepi.com/files/1/products/files/files/98868/23-65200929_157.jpg",
      "https://images.thefepi.com/files/1/products/files/files/98868/23-65200929_150.jpg",
      "https://images.thefepi.com/files/1/products/files/files/98868/23-65200929_153.jpg",
      "https://images.thefepi.com/files/1/products/files/files/98868/23-65200929_156.jpg",
      "https://images.thefepi.com/files/1/products/files/files/98868/23-65200929_154.jpg",
      "https://images.thefepi.com/files/1/products/files/files/98868/23-65200929_155.jpg",
    ],
    sellerProfile: null,
    isReseller: false,
    productName: "(Jungkook  BTS) V2 MULTI COLOR ",
    brand: {
      imageUrl: "https://images.thefepi.com/file/brand_all/LOGO-23.65.png",
      name: "23.65",
      id: "215",
    },
    isNew: false,
    isSoldOut: false,
    badgeUrls: ["https://images.app.thefepi.com/app/contents/badge-unisex-2x.jpg.webp"],
    price: {
      netPrice: 1170000,
      discount: 40,
      realPrice: 1950000,
    },
    commission: {
      rate: 8, // Randomized between 5 and 10 as a number
      nominal: 93600, // Calculated as netPrice * rate / 100 and represented as a number
    },
    views: "2.2k", // Randomized between 1k and 4k views
    buys: 352, // Randomized between 10% to 20% of views
  },
  {
    id: 129232,
    resellerNoCommissionMessage: null,
    resellerPriceInfo: [],
    resellerPrice: null,
    restockAttributes: [],
    productImageUrls: [
      "https://images.thefepi.com/files/1/products/files/files/129232/AMB_039.jpg",
      "https://images.thefepi.com/files/1/products/files/files/129232/AMB_040.jpg",
      "https://images.thefepi.com/files/1/products/files/files/129232/AMB_041.jpg",
      "https://images.thefepi.com/files/1/products/files/files/129232/AMB_034.jpg",
      "https://images.thefepi.com/files/1/products/files/files/129232/AMB_028.jpg",
      "https://images.thefepi.com/files/1/products/files/files/129232/AMB_032.jpg",
      "https://images.thefepi.com/files/1/products/files/files/129232/AMB_038.jpg",
    ],
    sellerProfile: null,
    isReseller: false,
    productName: "Ambler Unisex Headstand bear Overfit Jjuri Hooded T-shirt AHP1001 (Navy)",
    brand: {
      imageUrl: "https://images.thefepi.com/file/brand_all/brands_circle_AMBLER-min.png",
      name: "AMBLER",
      id: "199",
    },
    isNew: false,
    isSoldOut: false,
    badgeUrls: ["https://images.app.thefepi.com/app/contents/badge-unisex-2x.jpg.webp"],
    price: {
      netPrice: 785400,
      discount: 32,
      realPrice: 1155000,
    },
    commission: {
      rate: 6, // Specific value between 5 and 10 as a number
      nominal: 47124, // Calculated as netPrice * 6 / 100 and represented as a number
    },
    views: "3.1k", // Randomized between 1k and 4k views
    buys: 310, // Randomized between 10% to 20% of views
  },
  {
    id: 134253,
    resellerNoCommissionMessage: null,
    resellerPriceInfo: [],
    resellerPrice: null,
    restockAttributes: [],
    productImageUrls: [
      "https://images.thefepi.com/files/1/products/files/files/134253/VV230530_203.jpg",
      "https://images.thefepi.com/files/1/products/files/files/134253/VV230530_204.jpg",
      "https://images.thefepi.com/files/1/products/files/files/134253/VV230530_205.jpg",
      "https://images.thefepi.com/files/1/products/files/files/134253/VV230530_206.jpg",
      "https://images.thefepi.com/files/1/products/files/files/134253/VV230530_207.jpg",
      "https://images.thefepi.com/files/1/products/files/files/134253/VV230530_208.jpg",
      "https://images.thefepi.com/files/1/products/files/files/134253/VV230530_209.jpg",
    ],
    sellerProfile: null,
    isReseller: false,
    productName: "Vintage Shopping Bag",
    brand: {
      imageUrl: "https://images.thefepi.com/file/brand_all/Logo-Brand-Vivant.png",
      name: "Vivant",
      id: "261",
    },
    isNew: false,
    isSoldOut: false,
    badgeUrls: [
      "https://images.app.thefepi.com/app/contents/badge-preorder-2x.jpg.webp",
      "https://images.app.thefepi.com/app/contents/badge-new-2x.jpg.webp",
      "https://images.app.thefepi.com/app/contents/badge-women-2x.jpg.webp",
    ],
    price: {
      netPrice: 598600,
      discount: 18,
      realPrice: 730000,
    },
    commission: {
      rate: 9, // Specific value between 5 and 10 as a number
      nominal: 53874, // Calculated as netPrice * 9 / 100 and represented as a number
    },
    views: "2.5k", // Randomized between 1k and 4k views
    buys: 500, // Randomized between 10% to 20% of views
  },
  {
    id: 134874,
    resellerNoCommissionMessage: null,
    resellerPriceInfo: [],
    resellerPrice: null,
    restockAttributes: [],
    productImageUrls: [
      "https://images.thefepi.com/files/1/products/files/files/134874/80927_1682578373085.jpg",
      "https://images.thefepi.com/files/1/products/files/files/134874/46461_1682578373402.jpg",
      "https://images.thefepi.com/files/1/products/files/files/134874/45068_1682578373250.jpg",
      "https://images.thefepi.com/files/1/products/files/files/134874/57530_1682578373694.jpg",
    ],
    sellerProfile: null,
    isReseller: false,
    productName: "BLAZER LOW 77 JUMBO (DQ8768-100)",
    brand: {
      imageUrl: "https://images.thefepi.com/file/brand_all/nike.png",
      name: "NIKE",
      id: "158",
    },
    isNew: false,
    isSoldOut: true,
    badgeUrls: ["https://images.app.thefepi.com/app/contents/badge-men-2x.jpg.webp"],
    price: {
      netPrice: 1199400,
      discount: 40,
      realPrice: 1999000,
    },
    commission: {
      rate: 10, // Specific value between 5 and 10 as a number
      nominal: 119940, // Calculated as netPrice * 10 / 100 and represented as a number
    },
    views: "2.8k", // Randomized between 1k and 4k views
    buys: 560, // Randomized between 10% to 20% of views
  },
  {
    id: 135079,
    resellerNoCommissionMessage: null,
    resellerPriceInfo: [],
    resellerPrice: null,
    restockAttributes: [],
    productImageUrls: [
      "https://images.thefepi.com/files/1/products/files/files/135079/CDG230608_148.jpg",
      "https://images.thefepi.com/files/1/products/files/files/135079/CDG230608_149.jpg",
      "https://images.thefepi.com/files/1/products/files/files/135079/CDG230608_152.jpg",
      "https://images.thefepi.com/files/1/products/files/files/135079/CDG230608_150.jpg",
      "https://images.thefepi.com/files/1/products/files/files/135079/CDG230608_151.jpg",
    ],
    sellerProfile: null,
    isReseller: false,
    productName: "Stripe Logo Long Sleeve T-shirt AZ-T052-051-1 (P1T052)",
    brand: {
      imageUrl: "https://images.thefepi.com/file/brand_all/comme.png",
      name: "Comme Des Garcons",
      id: "160",
    },
    isNew: false,
    isSoldOut: false,
    badgeUrls: [
      "https://images.app.thefepi.com/app/contents/badge-preorder-2x.jpg.webp",
      "https://images.app.thefepi.com/app/contents/badge-unisex-2x.jpg.webp",
    ],
    price: {
      netPrice: 2380000,
      discount: 30,
      realPrice: 3400000,
    },
    commission: {
      rate: 9, // Specific value between 5 and 10 as a number
      nominal: 214200, // Calculated as netPrice * 9 / 100 and represented as a number
    },
    views: "2.3k", // Randomized between 1k and 4k views
    buys: 460, // Randomized between 10% to 20% of views
  },
  {
    id: 111719,
    resellerNoCommissionMessage: null,
    resellerPriceInfo: [],
    resellerPrice: null,
    restockAttributes: [],
    productImageUrls: [
      "https://images.thefepi.com/files/1/products/files/files/111719/CH220306_210.jpg",
      "https://images.thefepi.com/files/1/products/files/files/111719/CH220306_213.jpg",
      "https://images.thefepi.com/files/1/products/files/files/111719/CH220306_215.jpg",
      "https://images.thefepi.com/files/1/products/files/files/111719/CH220306_214.jpg",
      "https://images.thefepi.com/files/1/products/files/files/111719/CH220306_212.jpg",
      "https://images.thefepi.com/files/1/products/files/files/111719/CH220306_211.jpg",
    ],
    sellerProfile: null,
    isReseller: false,
    productName: "SIGNATURE SLEEVE LOGO SWTSH HDD (K288-BLK)",
    brand: {
      imageUrl: "https://images.thefepi.com/file/brand_all/LOGO-CARHARTT2.png",
      name: "carhartt",
      id: "204",
    },
    isNew: false,
    isSoldOut: false,
    badgeUrls: [
      "https://images.app.thefepi.com/app/contents/badge-preorder-2x.jpg.webp",
      "https://images.app.thefepi.com/app/contents/badge-new-2x.jpg.webp",
      "https://images.app.thefepi.com/app/contents/badge-unisex-2x.jpg.webp",
    ],
    price: {
      netPrice: 1272000,
      discount: 47,
      realPrice: 2400000,
    },
    commission: {
      rate: 6, // Specific value between 5 and 10 as a number
      nominal: 76320, // Calculated as netPrice * 6 / 100 and represented as a number
    },
    views: "2.4k", // Randomized between 1k and 4k views
    buys: 480, // Randomized between 10% to 20% of views
  },
  {
    id: 120213,
    resellerNoCommissionMessage: null,
    resellerPriceInfo: [],
    resellerPrice: null,
    restockAttributes: [],
    productImageUrls: [
      "https://images.thefepi.com/files/1/products/files/files/120213/CRC220928_168.jpg",
      "https://images.thefepi.com/files/1/products/files/files/120213/CRC220928_170.jpg",
      "https://images.thefepi.com/files/1/products/files/files/120213/CRC220928_169.jpg",
      "https://images.thefepi.com/files/1/products/files/files/120213/CRC220928_171.jpg",
      "https://images.thefepi.com/files/1/products/files/files/120213/CRC220928_172.jpg",
      "https://images.thefepi.com/files/1/products/files/files/120213/CRC220928_173.jpg",
    ],
    sellerProfile: null,
    isReseller: false,
    productName: "CLASSIC MEGA CRUSH CLOG BEIGE",
    brand: {
      imageUrl: "https://images.thefepi.com/file/brand_all/20221017_101718_small.png",
      name: "Crocs",
      id: "334",
    },
    isNew: false,
    isSoldOut: false,
    badgeUrls: [
      "https://images.app.thefepi.com/app/contents/badge-preorder-2x.jpg.webp",
      "https://images.app.thefepi.com/app/contents/badge-unisex-2x.jpg.webp",
    ],
    price: {
      netPrice: 1890000,
      discount: 0,
      realPrice: 0,
    },
    commission: {
      rate: 6, // Specific value between 5 and 10 as a number
      nominal: 113400, // Calculated as netPrice * 6 / 100 and represented as a number
    },
    views: "2.7k", // Randomized between 1k and 4k views
    buys: 540, // Randomized between 10% to 20% of views
  },
];
// const potentialProducts = [
//     {
//         id: 104080,
//         productImageUrls: [
//             "https://images.thefepi.com/files/1/products/files/files/104080/3.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/104080/4.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/104080/ADLV210527_015.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/104080/ADLV210527_014.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/104080/ADLV210527_018.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/104080/ADLV210527_016.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/104080/ADLV210527_021.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/104080/ADLV210527_025.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/104080/ADLV210527_022.jpg",
//         ],
//         sellerProfile: null,
//         isAddToShop: null,
//         isReseller: false,
//         productName: "ADLV BABY FACE SHORT SLEEVE T-SHIRT BLACK BINOCULARS",
//         brand: {
//             imageUrl: "https://images.thefepi.com/file/brand_all/adlv.png",
//             name: "Acme de La Vie",
//             id: "178",
//         },
//         isNew: false,
//         isSoldOut: false,
//         badgeUrls: ["https://images.app.thefepi.com/app/contents/badge-unisex-2x.jpg.webp"],
//         price: {
//             netPrice: 799200,
//             discount: 20,
//             realPrice: 999000,
//         },
//     },
//     {
//         id: 112003,
//         restockAttributes: [],
//         productImageUrls: [
//             "https://images.thefepi.com/files/1/products/files/files/112003/MLB220311_001.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/112003/MLB220311_005.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/112003/MLB220311_003.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/112003/MLB220311_004.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/112003/MLB220311_006.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/112003/MLB220311_002.jpg",
//         ],
//         sellerProfile: null,
//         isReseller: true,
//         productName: "Monogram Jacquard Hobo Bag New York Yankees (3ABQS012N-50BKS)",
//         brand: {
//             imageUrl: "https://images.thefepi.com/file/brand_all/MLB.png",
//             name: "MLB",
//             id: "198",
//         },
//         isNew: false,
//         isSoldOut: false,
//         badgeUrls: [
//             "https://images.app.thefepi.com/app/contents/badge-preorder-2x.jpg.webp",
//             "https://images.app.thefepi.com/app/contents/badge-unisex-2x.jpg.webp",
//         ],
//         price: {
//             netPrice: 1393000,
//             discount: 30,
//             realPrice: 1990000,
//         },
//     },
//     {
//         id: 96244,
//         restockAttributes: [],
//         productImageUrls: [
//             "https://images.thefepi.com/files/1/products/files/files/96244/CR200711_001.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/96244/CR200711_002.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/96244/CR200711_003.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/96244/CR200711_004.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/96244/CR200711_005.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/96244/CR200711_006.jpg",
//         ],
//         sellerProfile: null,
//         isReseller: true,
//         productName: "WORKWEAR POCKET SHORTSLEEVE T-SHIRT (K87-BLK)",
//         brand: {
//             imageUrl: "https://images.thefepi.com/file/brand_all/LOGO-CARHARTT2.png",
//             name: "carhartt",
//             id: "204",
//         },
//         isNew: false,
//         isSoldOut: false,
//         badgeUrls: ["https://images.app.thefepi.com/app/contents/badge-unisex-2x.jpg.webp"],
//         price: {
//             netPrice: 508250,
//             discount: 50,
//             realPrice: 1016500,
//         },
//     },
//     {
//         id: 128875,
//         restockAttributes: [],
//         productImageUrls: [
//             "https://images.thefepi.com/files/1/products/files/files/128875/NWR230217_111.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/128875/NWR230217_113.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/128875/NWR230217_118.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/128875/NWR230217_117.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/128875/NWR230217_116.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/128875/NWR230217_112.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/128875/NWR230217_115.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/128875/NWR230217_114.jpg",
//         ],
//         sellerProfile: null,
//         isReseller: true,
//         productName: "MLB Coopers Town Chain New York Yankees Unstructured Ball Cap Navy (13549195)",
//         brand: {
//             imageUrl: "https://images.thefepi.com/file/brand_all/LOGO-BRAND-NEW-ERA.png",
//             name: "New Era",
//             id: "283",
//         },
//         isNew: false,
//         isSoldOut: false,
//         badgeUrls: [
//             "https://images.app.thefepi.com/app/contents/badge-preorder-2x.jpg.webp",
//             "https://images.app.thefepi.com/app/contents/badge-unisex-2x.jpg.webp",
//         ],
//         price: {
//             netPrice: 890000,
//             discount: 0,
//             realPrice: 0,
//         },
//     },
//     {
//         id: 131978,
//         restockAttributes: [],
//         productImageUrls: [
//             "https://images.thefepi.com/files/1/products/files/files/131978/ADLV201231_218.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/131978/43.jpg",
//         ],
//         sellerProfile: null,
//         isReseller: true,
//         productName: "[Defect] ADLV BABY FACE SHORT SLEEVE T-SHIRT BLACK BUBBLE",
//         brand: {
//             imageUrl: "https://images.thefepi.com/file/brand_all/adlv.png",
//             name: "Acme de La Vie",
//             id: "178",
//         },
//         isNew: false,
//         isSoldOut: true,
//         badgeUrls: ["https://images.app.thefepi.com/app/contents/badge-unisex-2x.jpg.webp"],
//         price: {
//             netPrice: 669330,
//             discount: 33,
//             realPrice: 999000,
//         },
//     },
//     {
//         id: 133832,
//         restockAttributes: [],
//         productImageUrls: [
//             "https://images.thefepi.com/files/1/products/files/files/133832/68da4612972b9821c89195509f5db390.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/133832/176169fb14250e7fad8ce26ab42618eb.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/133832/0e258d18ee98e60a48aecc0398804816.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/133832/aea38729e91b40934eb08b021c446165.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/133832/edaa03b22c166b578ebf77da46babcd6.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/133832/4311dd94771c0d51a786c104e35c3795.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/133832/sz.jpg",
//         ],
//         sellerProfile: null,
//         isReseller: true,
//         productName: "ADLV BABY FACE SHORT SLEEVE T-SHIRT BLACK DONUTS 1 R",
//         brand: {
//             imageUrl: "https://images.thefepi.com/file/brand_all/adlv.png",
//             name: "Acme de La Vie",
//             id: "178",
//         },
//         isNew: false,
//         isSoldOut: false,
//         badgeUrls: ["https://images.app.thefepi.com/app/contents/badge-unisex-2x.jpg.webp"],
//         price: {
//             netPrice: 779220,
//             discount: 22,
//             realPrice: 999000,
//         },
//     },
//     {
//         id: 125158,
//         restockAttributes: [],
//         productImageUrls: [
//             "https://images.thefepi.com/files/1/products/files/files/125158/LW_001.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/125158/LW_002.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/125158/LW_003.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/125158/LW_004.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/125158/LW_005.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/125158/LW_006.jpg",
//         ],
//         sellerProfile: null,
//         isReseller: true,
//         productName: "Venturi Side String Short Sleeve T-shirt",
//         brand: {
//             imageUrl: "https://images.thefepi.com/file/brand_all/Logo-Lifework-1.png",
//             name: "LifeWork",
//             id: "276",
//         },
//         isNew: false,
//         isSoldOut: false,
//         badgeUrls: ["https://images.app.thefepi.com/app/contents/badge-unisex-2x.jpg.webp"],
//         price: {
//             netPrice: 1144800,
//             discount: 28,
//             realPrice: 1590000,
//         },
//     },
//     {
//       id: 126943,
//       "restockAttributes": [],
//         "productImageUrls": [
//             "https:\/\/images.thefepi.com\/files\/1\/products\/files\/files\/126943\/LW1.jpg",
//             "https:\/\/images.thefepi.com\/files\/1\/products\/files\/files\/126943\/LW2.jpg",
//             "https:\/\/images.thefepi.com\/files\/1\/products\/files\/files\/126943\/LW4.jpg",
//             "https:\/\/images.thefepi.com\/files\/1\/products\/files\/files\/126943\/LW3.jpg",
//             "https:\/\/images.thefepi.com\/files\/1\/products\/files\/files\/126943\/LW5.jpg",
//             "https:\/\/images.thefepi.com\/files\/1\/products\/files\/files\/126943\/LW6.jpg"
//         ],
//         "sellerProfile": null,
//         "isReseller": true,
//         "productName": "Big Radog Short Sleeve T-shirt",
//         "brand": {
//             "imageUrl": "https:\/\/images.thefepi.com\/file\/brand_all\/Logo-Lifework-1.png",
//             "name": "LifeWork",
//             "id": "276"
//         },
//         "isNew": false,
//         "isSoldOut": false,
//         "badgeUrls": [
//             "https:\/\/images.app.thefepi.com\/app\/contents\/badge-unisex-2x.jpg.webp"
//         ],
//         "price": {
//             "netPrice": 694660,
//             "discount": 6,
//             "realPrice": 739000
//         },
//     },
//         {
//         id: 104080,
//         productImageUrls: [
//             "https://images.thefepi.com/files/1/products/files/files/104080/3.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/104080/4.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/104080/ADLV210527_015.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/104080/ADLV210527_014.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/104080/ADLV210527_018.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/104080/ADLV210527_016.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/104080/ADLV210527_021.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/104080/ADLV210527_025.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/104080/ADLV210527_022.jpg",
//         ],
//         sellerProfile: null,
//         isAddToShop: null,
//         isReseller: false,
//         productName: "ADLV BABY FACE SHORT SLEEVE T-SHIRT BLACK BINOCULARS",
//         brand: {
//             imageUrl: "https://images.thefepi.com/file/brand_all/adlv.png",
//             name: "Acme de La Vie",
//             id: "178",
//         },
//         isNew: false,
//         isSoldOut: false,
//         badgeUrls: ["https://images.app.thefepi.com/app/contents/badge-unisex-2x.jpg.webp"],
//         price: {
//             netPrice: 799200,
//             discount: 20,
//             realPrice: 999000,
//         },
//     },
//     {
//         id: 112003,
//         restockAttributes: [],
//         productImageUrls: [
//             "https://images.thefepi.com/files/1/products/files/files/112003/MLB220311_001.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/112003/MLB220311_005.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/112003/MLB220311_003.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/112003/MLB220311_004.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/112003/MLB220311_006.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/112003/MLB220311_002.jpg",
//         ],
//         sellerProfile: null,
//         isReseller: true,
//         productName: "Monogram Jacquard Hobo Bag New York Yankees (3ABQS012N-50BKS)",
//         brand: {
//             imageUrl: "https://images.thefepi.com/file/brand_all/MLB.png",
//             name: "MLB",
//             id: "198",
//         },
//         isNew: false,
//         isSoldOut: false,
//         badgeUrls: [
//             "https://images.app.thefepi.com/app/contents/badge-preorder-2x.jpg.webp",
//             "https://images.app.thefepi.com/app/contents/badge-unisex-2x.jpg.webp",
//         ],
//         price: {
//             netPrice: 1393000,
//             discount: 30,
//             realPrice: 1990000,
//         },
//     },
//     {
//         id: 96244,
//         restockAttributes: [],
//         productImageUrls: [
//             "https://images.thefepi.com/files/1/products/files/files/96244/CR200711_001.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/96244/CR200711_002.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/96244/CR200711_003.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/96244/CR200711_004.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/96244/CR200711_005.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/96244/CR200711_006.jpg",
//         ],
//         sellerProfile: null,
//         isReseller: true,
//         productName: "WORKWEAR POCKET SHORTSLEEVE T-SHIRT (K87-BLK)",
//         brand: {
//             imageUrl: "https://images.thefepi.com/file/brand_all/LOGO-CARHARTT2.png",
//             name: "carhartt",
//             id: "204",
//         },
//         isNew: false,
//         isSoldOut: false,
//         badgeUrls: ["https://images.app.thefepi.com/app/contents/badge-unisex-2x.jpg.webp"],
//         price: {
//             netPrice: 508250,
//             discount: 50,
//             realPrice: 1016500,
//         },
//     },
//     {
//         id: 128875,
//         restockAttributes: [],
//         productImageUrls: [
//             "https://images.thefepi.com/files/1/products/files/files/128875/NWR230217_111.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/128875/NWR230217_113.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/128875/NWR230217_118.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/128875/NWR230217_117.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/128875/NWR230217_116.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/128875/NWR230217_112.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/128875/NWR230217_115.jpg",
//             "https://images.thefepi.com/files/1/products/files/files/128875/NWR230217_114.jpg",
//         ],
//         sellerProfile: null,
//         isReseller: true,
//         productName: "MLB Coopers Town Chain New York Yankees Unstructured Ball Cap Navy (13549195)",
//         brand: {
//             imageUrl: "https://images.thefepi.com/file/brand_all/LOGO-BRAND-NEW-ERA.png",
//             name: "New Era",
//             id: "283",
//         },
//         isNew: false,
//         isSoldOut: false,
//         badgeUrls: [
//             "https://images.app.thefepi.com/app/contents/badge-preorder-2x.jpg.webp",
//             "https://images.app.thefepi.com/app/contents/badge-unisex-2x.jpg.webp",
//         ],
//         price: {
//             netPrice: 890000,
//             discount: 0,
//             realPrice: 0,
//         },
//     },
// ];

export default potentialProducts;
