import React from "react";
import ViewController from "../../CommonComponents/Base/ViewController";
import { LoadingWidget } from "../../CommonComponents/UI/LoadingWidget/LoadingWidget";
import Scaffold from "../../CommonComponents/UI/Scaffold/Scaffold";
import SizedDiv from "../../CommonComponents/UI/SizedDiv";
import BrandListsViewModel from "./BrandListsViewModel";
import "./BrandListsViewController.css";
import chevronLeftIcon from "../../Assets/Images/ic_chevron_left.svg";
import { DivWithIntersectionObserver } from "../../CommonComponents/UI/DivWithIntersectionObserver/DivWithIntersectionObserver";
import StoreTopTabbarWidget from "../CommonComponents/Widgets/StoreTopTabbarWidget";
import SearchBarWidget from "./Widgets/SearchBarWidget/SearchBarWidget";
import NotFoundWidget from "../../CommonComponents/UI/NotFound/NotFoundWidget";
import { ImageWidget } from "../../CommonComponents/UI/ImageWidget/ImageWidget";
import { Link } from "react-router-dom";

export type BrandListsViewControllerProps = {};

export default class BrandListsViewController extends ViewController<
  BrandListsViewControllerProps,
  BrandListsViewModel
> {
  query?: string;

  constructor(props: any) {
    super(props, new BrandListsViewModel());
    if (this.viewModel.fresh) {
      this.viewModel.loadBrands();
    }
    // this.viewModel.loadBrands();

    const params = new URLSearchParams(window.location.search);
    this.query = params.get("query") || undefined;
    this.onEndReached = this.onEndReached.bind(this);
  }

  onEndReached() {
    this.viewModel.loadBrands();
  }

  componentWillUnmount(): void {
    this.viewModel.persist();
  }

  renderHeader() {
    return (
      <div id="BrandListsViewControllerBrandListHeaderContainer">
        <div
          style={{
            position: "absolute",
            top: 52,
            backgroundColor: "white",
            // left: '50%',
            left: 0,
            right: 0,
            zIndex: 5,
            // maxWidth: "600px",
            // transform: 'translateX(-50%)'
          }}
        >
          <SizedDiv height={16} />
          <StoreTopTabbarWidget
            shouldShowBottomBorder={false}
            selectedId={
              this.viewModel.selectedCategoryId ||
              this.viewModel.categories?.[0]?.id
            }
            renderTabButton={(props) => {
              return this.renderPill(props);
            }}
            labels={(this.viewModel.categories || []).map((x) => ({
              ...x,
              onTap: () => {
                // console.log(x.id);
                this.viewModel.selectCategoryId(x.id);
              },
            }))}
          />
          <SearchBarWidget
            query={this.viewModel.query}
            onQueryChange={(q) => this.viewModel.changeQuery(q)}
            onCancelClick={() => {
              this.viewModel.changeQuery("");
            }}
          />
        </div>
        <a href={`/Store/Search?query=${this.query}&defaultTabKey=products`}>
          <img
            src={chevronLeftIcon}
            id="BrandListsViewControllerBrandListHeaderBackIcon"
            alt="back to previous screen"
          />
        </a>
        <p id="BrandListsViewControllerBrandListHeaderTitle">Brands</p>
      </div>
    );
  }

  renderBrandList(
    m: {
      [key: string]: {
        name?: string;
        imageUrl?: string;
        id: string;
      }[];
    },
    q?: string
  ) {
    const filtered = Object.keys(m)
      .sort()
      .map((k) => {
        return {
          key: k,
          list: m[k].filter((x) => x.name?.toLowerCase().includes(q || "")),
        };
      });
      
    const isEmpty = filtered.filter((x) => x.list.length > 0).length === 0;

    if (isEmpty) return <NotFoundWidget />;

    return (
      <>
        {filtered.map((f) => {
          return (
            <div>
              {f.list.length > 0 && (
                <p
                  style={{
                    fontWeight: 700,
                    fontSize: "16px",
                    lineHeight: "24px",
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    marginBottom: "28px",
                  }}
                >
                  {f.key}
                </p>
              )}
              {f.list.map((b) => {
                const parts = b.name?.split(new RegExp(`(${q})`, "gi"));
                return (
                  <Link
                    key={b.name}
                    id="BrandListsViewControllerBrandListItem"
                    to={`/Brand/${b.id}`}
                  >
                    {b.imageUrl ? (
                      <img
                        src={b.imageUrl}
                        id="BrandListsViewControllerBrandListItemImage"
                        alt={``}
                      />
                    ) : (
                      <div
                        id="BrandListsViewControllerBrandListItemImage"
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: 600,
                          fontSize: 30,
                          border: "0.5px solid #C0C0C0",
                        }}
                      >
                        {b.name?.charAt(0)}
                      </div>
                    )}
                    <p id="BrandListsViewControllerBrandListItemLabel">
                      <span>
                        {(parts || []).map((part) =>
                          part.toLowerCase() === (q || "").toLowerCase() ? (
                            <span
                              style={{
                                color: "var(--purple)",
                              }}
                            >
                              {part}
                            </span>
                          ) : (
                            part
                          )
                        )}
                      </span>
                    </p>
                  </Link>
                );
              })}
              {f.list.length > 0 && (
                <div
                  style={{
                    border: "1px solid #F2F2F2",
                    marginLeft: "16px",
                    marginRight: "16px",
                    marginBottom: "12px",
                  }}
                />
              )}
            </div>
          );
        })}
      </>
    );
  }

  renderPill(props: {
    onClick?: () => void;
    label?: string;
    isActive?: boolean;
  }) {
    const { isActive = false, label, onClick } = props;

    return (
      <button
        onClick={() => void onClick?.()}
        style={{
          marginRight: "-8px",
          padding: "4px 12px",
          background: isActive ? "#383838" : "#FFFFFF",
          borderRadius: "20px",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "18px",
          textAlign: "center",
          color: isActive ? "#FFFFFF" : "#000",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: isActive ? "unset" : "0.5px solid #383838",
        }}
      >
        {label}
      </button>
    );
  }


  render() {
    return (
      <Scaffold topBar={this.renderHeader()}>
        {/* <div
          style={{
            position: "absolute",
            // top: 52,
            backgroundColor: "white",
            // left: '50%',
            left:0,
            right: 0,
            zIndex: 5,
            // maxWidth: "600px",
            // transform: 'translateX(-50%)'
          }}
        >
          <SizedDiv height={16} />
          <StoreTopTabbarWidget
            shouldShowBottomBorder={false}
            selectedId={
              this.viewModel.selectedCategoryId ||
              this.viewModel.categories?.[0]?.id
            }
            renderTabButton={(props) => {
              return this.renderPill(props);
            }}
            labels={(this.viewModel.categories || []).map((x) => ({
              ...x,
              onTap: () => {
                // console.log(x.id);
                this.viewModel.selectCategoryId(x.id);
              },
            }))}
          />
          <SearchBarWidget
            query={this.viewModel.query}
            onQueryChange={(q) => this.viewModel.changeQuery(q)}
            onCancelClick={() => {
              this.viewModel.changeQuery("");
            }}
          />
        </div> */}
        <div id="BrandListItemsContainer">
          {this.renderBrandList(
            // @ts-ignore
            this.viewModel.brands.reduce((acc, val) => {
              // @ts-ignore
              if (!acc[val.name.charAt(0).toUpperCase()]) {
                // @ts-ignore
                acc[val.name.charAt(0).toUpperCase()] = [val];
              } else {
                // @ts-ignore
                acc[val.name.charAt(0).toUpperCase()].push(val);
              }

              return acc;
            }, {}),
            this.viewModel.query?.toLowerCase()
          )}
        </div>
      </Scaffold>
    );
  }
}
