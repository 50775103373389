import React from "react";
import OrderReceiptResponse from "../Models/OrderReceiptResponse";

type OrderReceiptPaymentInfoWidgetProps = {
    orderReceiptData: OrderReceiptResponse | null
};

export default class OrderReceiptPaymentInfoWidget extends React.Component<OrderReceiptPaymentInfoWidgetProps> {
    render() {
        const orderReceiptData = this.props.orderReceiptData;
        return(
            <div className="row">
                <div className="col no-padding">
                    <div className='box_white_cart'>
                        <div className="border_bottom">
                            <p><b>Payment Info</b></p>
                        </div>
                        <div className="row">
                            <div className='col-12'>
                                <br></br>
                                <img width="80" src={orderReceiptData?.paymentInfo.imageUrl} alt="bank"></img>
                                <span className="font14 color585b63"><b>&nbsp;&nbsp;&nbsp;&nbsp;{orderReceiptData?.paymentInfo.cardNumberDetail}</b></span>
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-12'>
                                <br></br>
                                {
                                orderReceiptData?.paymentInfo.paymentMethodName !== 'BCA' ?
                                    <p className="font12">{orderReceiptData?.paymentInfo.paymentMethodName}</p>
                                    :
                                    <p className="font12">Direct Bank Transfer ke BCA<br></br>
                                    7140-788-788<br></br>
                                    Nama Rekening PT IDE PICUP GROUP<br></br>
                                    Harap Melakukan pembayaran ke rekening kami dalam jangka waktu 1 hari kerja.</p>
                                    }
                            </div>
                            
                            <div className="">
                                {
                                orderReceiptData?.paymentInfo.paymentMethodName === 'BCA' ?
                                    <div className="div_beforepay">
                                        <div className="row">
                                            <div className="col-2 text-end">
                                                <img className='img-fluid' src={global.ImgUrl+'shopable/ic-notpay@2x.png'} alt="img_notpay"></img>
                                            </div>
                                            <div className="col-10">
                                                <p><b>Before payment</b></p>
                                                <p className="mtopm10"><b>Please make payment within 24 hours.</b></p>
                                            </div>
                                        </div>
                                    </div>
                                :
                                    orderReceiptData?.paymentInfo.alertMessage && <div className="div_beforepay_midtrans">
                                        <div className="row">
                                            <div className="col-2 text-end">
                                                <img src={global.ImgUrl+'shopable/ic-notpay@2x.png'} alt="img_notpay"></img>
                                            </div>
                                            <div className="col-10">
                                                <p>{orderReceiptData?.paymentInfo.alertMessage}</p>
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className="get_voucher">
                                    <div className="row">
                                        <div className="col-12">
                                            <p className="font14"><b>Get Voucher</b></p>
                                            <p className="font12">There are Vouchers of Rp. 30.000 are waiting for you here.</p>
                                            <p className="font12">Make a payment within 2hours and you can get the voucher!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}