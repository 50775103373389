import React from "react";
import BaseUIComponent from "../../CommonComponents/Base/BaseUIComponent"

import Scaffold, { ShowSnackbarModel } from "../../CommonComponents/UI/Scaffold/Scaffold";

import ForgotPasswordViewModel from "./ForgotPasswordViewModel";

import { Navigate  } from "react-router-dom";
import "./ForgotPassword.css";

import ButtonWidget, { ButtonWidgetType } from "../../CommonComponents/UI/ButtonWidget/ButtonWidget";
import InputFieldWidget from "../../CommonComponents/UI/InputFieldWidget/InputFieldWidget";

export default class ForgotPasswordScreen extends BaseUIComponent<{}, ForgotPasswordViewModel> {
    constructor(props: {}) {
        super(props, new ForgotPasswordViewModel());
    }

    handleSubmit = (event: any) => {
        this.viewModel().onSubmit();
    }

    handleChangeEmailTelp = (event: React.ChangeEvent<HTMLInputElement>) =>{
        this.viewModel().onEmailOrPhoneNumberChanged(event.target.value);
    }

    render() {
        if (this.viewModel().isSuccess) {
            return <Navigate  to='/LoginSso'/>;
        }

        return(
            <Scaffold 
                showSnackbar={
                    this.viewModel().errorMessage ? 
                    new ShowSnackbarModel({
                        message: this.viewModel().errorMessage ?? "",
                    }) : undefined
                }>
                
                <div id="ForgotPasswordContainer" className="container">
                    <div className="row">
                        <div className="col">
                            <div>
                                <InputFieldWidget
                                    title="Email / Phone Number"
                                    inputType="text"
                                    isEnabled={true}
                                    placeholder={"Enter Email or Phone number"}
                                    errorMessage={this.viewModel().errorMessageEmailTelp}
                                    action={(event) => {
                                        this.handleChangeEmailTelp(event);
                                    }} 
                                ></InputFieldWidget>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col mtop22 text-center">
                            <ButtonWidget 
                                buttonType={ButtonWidgetType.primary} 
                                isLoading={this.viewModel().isLoadingButtonSubmit ? true : false}
                                isEnabled={this.viewModel().input_email_telp === "" ? false : true}
                                width="calc(100% - 6px)"
                                action={() => {
                                    this.handleSubmit('');
                                }} 
                            >
                                Reset Password
                            </ButtonWidget>
                        </div>
                    </div>
                </div>
            </Scaffold>
        );
    }
} 