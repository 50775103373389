import mixpanel from "mixpanel-browser";
import { getDeviceId, requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import { getDeviceBrandAndModel } from "../../CommonComponents/Utils";

class MixpanelManager {
  private STAGING_MIXPANEL_TOKEN = "fef25edf829c8fbf61ad559d9b2e580c";
  private PROD_MIXPANEL_TOKEN = "afcf6bd26ab723a4df7091e40e5ea16b";
  debug = process.env.REACT_APP_ENV !== "production";
  private mixpanelToken = this.debug ? this.STAGING_MIXPANEL_TOKEN : this.PROD_MIXPANEL_TOKEN;

  init() {
    // console.log("init mixpanel with token",this.mixpanelToken)
    mixpanel.init(this.mixpanelToken, { debug: false, track_pageview: false, persistence: "localStorage" });
    // console.log("super property cn", mixpanel.get_property("distinct_id"));
    if (mixpanel.get_property("distinct_id") !== this.getCn()) {
      this.ensureSuperProperties();
    }
  }

  track(eventName: string, properties: any) {
    console.log("mixpanel firing event", eventName, properties);
    mixpanel.track(eventName, properties);
  }

  ensurePeople() {}

  ensureSuperProperties() {
    // Retrieve attributes from local storage
    const superProperties = JSON.parse(localStorage.getItem("mixpanelSuperProperties") || "{}");
    // console.log("super properties", superProperties);

    // Check if any attribute is missing
    if (
      !(
        superProperties.distinct_id &&
        superProperties.registered_user !== undefined &&
        superProperties.platform &&
        superProperties.device_id &&
        superProperties.device_model &&
        superProperties.app_version &&
        superProperties.user_type &&
        superProperties.language
      )
    ) {
      // Fetch and update missing attributes
      const updatedSuperProperties = {
        ...superProperties,
        distinct_id: this.getCn(),
        registered_user: false,
        platform: "Web",
        device_id: getDeviceId(),
        device_model: getDeviceBrandAndModel(),
        app_version: "1.0.0",
        user_type: "customer_semo",
        language: "id",
      };
      // console.log("super properties to assign", updatedSuperProperties);

      // Update the local storage with the updated attributes
      localStorage.setItem("mixpanelSuperProperties", JSON.stringify(updatedSuperProperties));

      // Update Mixpanel super properties
      mixpanel.register(updatedSuperProperties);
    }
  }

  getCn(): string | undefined {
    // Check if 'cn' is in localStorage
    const cnFromLocalStorage = localStorage.getItem("cn");

    if (cnFromLocalStorage) {
      return cnFromLocalStorage;
    } else {
      // Fetch preloading data from the backend
      requestAPI(Endpoints.preloading, {
        onSuccess: (response: any) => {
          if (response.data.distinctId) {
            // console.log("raw",response.data.distinctId)
            // Decode distinctId using base64
            const decodedDistinctId = atob(response.data.distinctId);
            // console.log("decodedDistinctId",decodedDistinctId)
            // console.log("deviceid",getDeviceId())
            // Extract the 'cn' portion (assuming 'cn' is separated by a delimiter)
            const cn = decodedDistinctId.slice(0, decodedDistinctId.indexOf(getDeviceId() || ""));
            // Store 'cn' in localStorage for future use
            localStorage.setItem("cn", cn);
            return cn;
          } else {
            // Use getDeviceId from Utils.tsx and store 'cn' in localStorage
            const deviceId = getDeviceId() || "";
            localStorage.setItem("cn", deviceId);
            return deviceId;
          }
        },
        onFailure: (error) => {
          console.warn("error retrieving cn");
          return null;
        },
      });
    }
  }
}

export default MixpanelManager;
