import { useParams } from "react-router-dom";
import { ProductBadgeType } from "../CommonTypes";
import { requestAPI } from "./ApiRequest/APIRequest";
import BaseResponse from "./ApiRequest/BaseResponse";
import { Endpoints } from "./ApiRequest/Endpoints";

export type Size = {
    width: number,
    height: number
};

export function isResellerDomain() {
  // return false;
  return !Boolean(window.location.hostname.includes("thefepi.com"));
}

export function getResellerPhoneNumber(resellerLink?: string) {
    // retrieve from localStorage
    var resellerPhoneNumber = getPhoneNumberByResellerName(resellerLink || "")
    if(resellerPhoneNumber !== -1){
      return ensureCountryCode(resellerPhoneNumber)
    }

    // requesting number
    try {
        requestAPI(Endpoints.resellerPhoneNumber, {
            parameters: {
                resellerLink: resellerLink,
            },
            onSuccess: (response: BaseResponse<{ phoneNumber: string }>) => {
                //store to localStorage
                setResellerPhoneNumber(resellerLink || "", ensureCountryCode(response.data?.phoneNumber))
                return ensureCountryCode(response.data?.phoneNumber);
            },
            onFailure: (error) => {
                console.log("error getting reseller phone number:", error);
                return null;
            },
        });
    } catch {
        return "-1";
    }
}

function ensureCountryCode(phoneNumber: string): string {
  const countryCode = '62';
  
  // Remove any non-numeric characters from the phone number
  const numericPhoneNumber = phoneNumber.replace(/\D/g, '');

  // Check if the phone number already starts with the country code
  if (numericPhoneNumber.startsWith(countryCode)) {
    return numericPhoneNumber;
  } else {
    // Add the country code in front of the phone number
    return countryCode + numericPhoneNumber;
  }
}

interface Reseller {
  resellerName: string;
  phoneNumber: string;
}

function getPhoneNumberByResellerName(resellerName: string): string | -1 {
  // Get the JSON string from localStorage
  const resellerData = localStorage.getItem('resellerContactNumber');

  // Check if resellerData is null (not found in localStorage)
  if (resellerData === null) {
    return -1;
  }

  try {
    // Parse the JSON string into an array of objects
    const resellerArray: Reseller[] = JSON.parse(resellerData);

    // Find the object with the matching resellerName
    const reseller = resellerArray.find((item) => item.resellerName === resellerName);

    // Check if the reseller was found
    if (reseller) {
      // Return the phoneNumber
      return reseller.phoneNumber;
    } else {
      // Return -1 if the resellerName doesn't exist
      return -1;
    }
  } catch (error) {
    // Handle JSON parsing errors
    console.error('Error parsing JSON:', error);
    return -1;
  }
}

function setResellerPhoneNumber(resellerName: string, phoneNumber: string): void {
  // Get the JSON string from localStorage
  const resellerData = localStorage.getItem('resellerContactNumber');

  try {
    let resellerArray: Reseller[] = [];

    if (resellerData) {
      // Parse the existing JSON string into an array of objects
      resellerArray = JSON.parse(resellerData);
    }

    // Check if the resellerName already exists in the array
    const existingResellerIndex = resellerArray.findIndex((item) => item.resellerName === resellerName);

    if (existingResellerIndex === -1) {
      // If it doesn't exist, add a new entry
      resellerArray.push({ resellerName, phoneNumber });
    } else {
      // If it exists, update the phoneNumber
      resellerArray[existingResellerIndex].phoneNumber = phoneNumber;
    }

    // Store the updated array back in localStorage
    localStorage.setItem('resellerContactNumber', JSON.stringify(resellerArray));
  } catch (error) {
    // Handle JSON parsing errors or other exceptions
    console.error('Error:', error);
  }
}

export function getDeviceModel(version: number = 1) {
    const ua = navigator.userAgent;
    if (!ua) {
        return;
    }

    if (/android/i.test(ua)) {
        return version === 1 ? "android" : isResellerDomain() ? "SemoAndroid" : "WebAndroid";
    } else if (
        /iPad|iPhone|iPod|Macintosh/.test(ua) ||
        (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
    ) {
        if (/iPad|iPhone|iPod/.test(ua)) {
            // Code for iOS devices (excluding MacBooks)
            return version === 1 ? "ios" : isResellerDomain() ? "SemoiOS" : "WebiOS";
        } else {
            // Code for MacBooks
            return version === 1 ? "ios" : isResellerDomain() ? "SemoDesktop" : "DesktopWeb";
        }
    }
    return version === 1 ? "android" : isResellerDomain() ? "SemoDesktop" : "DesktopWeb";
}

export function getAppLink() {
  return getDeviceModel() === 'android' ? "https://play.google.com/store/apps/details?id=com.thefepi.shopable" : 
  "https://apps.apple.com/id/app/thefepi/id1590816062"
}

export function uploadCommonFile(file: File) {

  let formData = new FormData();
  formData.append("image", file, file.name);
  localStorage.getItem("fpAuth") &&
    formData.append(
      "fpAuth",
      localStorage.getItem("fpAuth")!!
    );

  return fetch(
    "https://manage.file.thefepi.com/app/UploadImage/common.php",
    {
      method: "POST",
      body: formData,
      headers: {
        // "X-API-Key":
        //   "Mnx6wGe8l3RFBj5fbemYtVd7jXnh6f2mqHDK0wvS29t1iB4RVygGg4ySjjiMoTDP",
      },
      redirect: "follow",
    }
  )
    .then((response) => response.json())
    .then((data) => data.data.imageUrl as string)
}


export function isLoggedIn() {
  return Boolean(window.localStorage.getItem('fpAuth'))
}

export function getScreenSize(): Size {
    const { innerWidth: width, innerHeight: height} = window;
    return {width, height};
}

export function getScreenSizeWithMaxWidth(): Size {
    let { innerWidth: width, innerHeight: height} = window;
    width = (width > 600 ? 600 : width);
    return {width, height};
}

export const isValidPhoneNumber = (number?: string) => {
  if (!number) return false;

  return /^\+?[0-9]\d{6,16}$/.test(number);
}

export const isValidEmail = (email?: string) => {
  if (!email) return false;
  return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email)
}

export function productTypesForBadgeUrls(types: ProductBadgeType[] = [], map: {[key in ProductBadgeType]?: string  } ): string[] {
  return types.map(t => map[t]).filter(x => x) as string[];
}

export function mapBadgeTypes(values: {type: ProductBadgeType, url: string}[]) {
  return (values || []).reduce((acc, val) => {
              acc[val['type']] = val['url'];
              return acc;

            }, {} as {[key in ProductBadgeType]: string});
}

export function getPreloadProps(args: {
    onSuccess: (isCreator: boolean, creatorId: string, numberInCart: number, mappedProductBadgeUrls: {[key in ProductBadgeType]: string}) => void, 
    onFailure: (error: string) => void
}) {
    requestAPI(Endpoints.reusableProps, {
        onSuccess: (response: BaseResponse<{isCreator:boolean, creatorId:string, numberInCart:number, productBadgeImageUrls: {type: ProductBadgeType, url: string}[]}>) => {
            
            const mappedProductBadgeUrls = (response.data.productBadgeImageUrls || []).reduce((acc, val) => {
              acc[val['type']] = val['url'];
              return acc;

            }, {} as {[key in ProductBadgeType]: string});
            
            args.onSuccess(response.data.isCreator, response.data.creatorId, response.data.numberInCart, mappedProductBadgeUrls);
        },
        onFailure: (error: string) => {
            args.onFailure(error);
        }
    });
}

export function handleUnicode(str?: string | null): string {
    return JSON.parse(`"${(str ?? "").replaceAll(/\n/g, `<br>`)}"`).replaceAll(`<br>`, `\n`);
}

export function convertToRupiah(val?: number | null): string {
    let currencyNumberFormat = Intl.NumberFormat('en-US');
    let priceLabel = currencyNumberFormat.format(val ?? 0);

    return `Rp ${priceLabel}`
}

export function ValidateEmailTelp(val: string) {
    let re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if ( re.test(val) || (!isNaN(+val) && val.length > 9)) {
        return true;
    }
    else{
        return false;
    }
}

export function normalizeString(text: string, maxString: number = 9999999) {
  let p = 0;

  const x = text.split(' ');

  const _x = x.reduce((acc, val) => {
    if (acc.length < maxString) {
      acc.push(val);
    }
    return acc
  }, [] as string[]);

  return _x
    .map((item, idx, items) => {
      console.log({item})
      const ret =
        p < maxString ? 
         
            item.split(/\\n|\n/).map((i, idx, els) => {
              return (
                <span>
                  {JSON.parse('"' + i + '"')}
                  {" "}
                  {idx !== els.length - 1 && <br />}
                </span>
              );
            }) : undefined;
      p += item.length;
      return ret;
    })
    .filter((x) => x);
}

export function standarizeThefepiLink(link: string): string {
    if (link.includes("me.thefepi.com") || link.includes("m.thefepi.com") || link.includes("thefepi://") || link.includes("thefepi-staging://")) {
        link = link.replace("https://me.thefepi.com/", "");
        link = link.replace("https://m.thefepi.com/", "");
        link = link.replace("https://dev.thefepi.com/mobile", "");
        link = link.replace("thefepi://", "");
        link = link.replace("thefepi-staging://", "");
        
        return `/${link}`;
    }

    return link;
}

export function getLocationQueries(): { [key: string] : string } {
    let urlQueryString = window.location.search;
    let result: { [key: string]: string } = {};

    urlQueryString.replaceAll("?", "").replaceAll("%20", " ").split("&").forEach((value) => {
        const keyAndValue = value.split("=");

        if (keyAndValue[0].length > 0) {
            result[keyAndValue[0]] = keyAndValue[1];
        }
    });
    

    return result;
}

export function getRawLocationQueriesString(args?: { exceptionKeys: string[] }): string {
    let queries = getLocationQueries();
    let queryString = "";

    args?.exceptionKeys.forEach((value) => delete queries[value] );

    Object.keys(queries).forEach((key) => {
        queryString += (queryString.length === 0) ? "" : "&";
        queryString += `${key}=${queries[key]}`;
    })
    
    return queryString;
}


export const withSellerId = (BaseComponent: React.ComponentType<any>) => {
  const WrappedComponent = (props: any) => {

    const {creatorLink} = useParams();

    return <BaseComponent creatorLink={creatorLink} {...props}></BaseComponent>;
  };

  return WrappedComponent;
};

export type WithCreatorLinkProps = {
  creatorLink?: string;
}

export function getDeviceBrandAndModel(): string {
  const userAgent = navigator.userAgent;
  const brandSeriesRegex = /(\w+)\s+(\w+)/i;
  const match = userAgent.match(brandSeriesRegex);

  if (match && match.length === 3) {
    const brand = match[1];
    const series = match[2];
    return `${brand} ${series}`;
  } else {
    return "Unknown Device";
  }
}