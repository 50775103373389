import { eventManager } from "../../Analytics/Events/Common";
import ViewModel from "../../CommonComponents/Base/ViewModel";
import { ShareUrlModel } from "../../CommonComponents/UI/ShareUrlPopUp/Models/ShareUrlModel";
import CreatorSpaceViewModel from "../../Content/CreatorSpace/CreatorSpaceViewModel";


export default class ResellerStorefrontViewModel extends CreatorSpaceViewModel {
  constructor(props: any) {
    super(props)
  }

  onShareLink() {
    let splitted = window.location.href.split("/") || [];
    eventManager.flush();
    eventManager.add({
      content_type: "user",
      item_id: "user_" + this.detail?.sellerProfile.SellerID,
    });
    this.updateState({shareUrlModel: new ShareUrlModel({ urlString: window.location.href })})
  }

  emit: (action: () => void) => void = (_) => {
    this.states.push({ ...this });
    this.stabPostUpdateState();
  };
}

