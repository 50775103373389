import "./BottomBar.css"

import ic_unselected_feed from "../../../Assets/Images/BottomBar/ic_unselected_home.svg";
import ic_unselected_feed_dark from "../../../Assets/Images/BottomBar/ic_unselected_home_dark.svg";
import ic_selected_feed from "../../../Assets/Images/BottomBar/ic_selected_home.svg";
import ic_selected_store from "../../../Assets/Images/BottomBar/ic_selected_store.svg";
import ic_unselected_store from "../../../Assets/Images/BottomBar/ic_unselected_store.svg";
import ic_unselected_store_dark from "../../../Assets/Images/BottomBar/ic_unselected_store_dark.svg";
import ic_unselected_showcase from "../../../Assets/Images/BottomBar/ic_unselected_showcase.svg";
import ic_unselected_showcase_dark from "../../../Assets/Images/BottomBar/ic_unselected_showcase_dark.svg";
import ic_selected_showcase_dark from "../../../Assets/Images/BottomBar/ic_selected_showcase.svg";
import ic_selected_showcase_light from "../../../Assets/Images/BottomBar/ic_selected_showcase_light.svg";
import ic_selected_space from "../../../Assets/Images/BottomBar/ic_selected_space.svg";
import ic_unselected_space from "../../../Assets/Images/BottomBar/ic_unselected_space.svg";
import ic_unselected_space_dark from "../../../Assets/Images/BottomBar/ic_unselected_space_dark.svg";
import ic_selected_profile from "../../../Assets/Images/BottomBar/ic_selected_profile.svg";
import ic_unselected_profile from "../../../Assets/Images/BottomBar/ic_unselected_profile.svg";
import ic_unselected_profile_dark from "../../../Assets/Images/BottomBar/ic_unselected_profile_dark.svg";
import img_become_creator from "../../../Assets/Images/img_become_creator_illustration.webp";

import { getLocationQueries } from "../../Utils";
import { Link } from "react-router-dom";
import React from "react";
import PopUpWidgetModel from "../PopUp/Models/PopUpWidgetModel";

export type BottomBarItemType = "store" | "home" | "showcase" | "space" | "my-page";
type BottomBarWidgetProps = {
    selectedItem: BottomBarItemType,
    isCreator?: boolean,
    creatorId?: string,
    updatePopUpModel: (model: PopUpWidgetModel) => void
}

/**
 * There are some kinds of items:
 * - "showcase"
 * - "store"
 * - "home"
 * - "space"
 * - "my-page"
 * 
 * e.g:
 * `<BottomBarWidget selectedItem="home"/>`
 */
export class BottomBarWidget extends React.Component<BottomBarWidgetProps, {}> {
    render() {
        let showCaseIcon: string;
        let storeIcon: string;
        let feedIcon: string;
        let spaceIcon: string;
        let profileIcon: string;

        switch (this.props.selectedItem) {
            case "home":
                showCaseIcon = ic_unselected_showcase_dark;
                storeIcon = ic_unselected_store_dark;
                feedIcon = ic_selected_feed;
                spaceIcon = ic_unselected_space_dark;
                profileIcon = ic_unselected_profile_dark;
                break;
            case "store":
                showCaseIcon = ic_unselected_showcase;
                storeIcon = ic_selected_store;
                feedIcon = ic_unselected_feed;
                spaceIcon = ic_unselected_space;
                profileIcon = ic_unselected_profile;
                break;
            case "showcase":
                if (getLocationQueries()["theme"]?.toUpperCase() === "LIGHT") {
                    showCaseIcon = ic_selected_showcase_light;
                    storeIcon = ic_unselected_store;
                    feedIcon = ic_unselected_feed;
                    spaceIcon = ic_unselected_space;
                    profileIcon = ic_unselected_profile;
                } else {
                    showCaseIcon = ic_selected_showcase_dark;
                    storeIcon = ic_unselected_store_dark;
                    feedIcon = ic_unselected_feed_dark;
                    spaceIcon = ic_unselected_space_dark;
                    profileIcon = ic_unselected_profile_dark;
                }
                break;
            case "space":
                showCaseIcon = ic_unselected_showcase;
                storeIcon = ic_unselected_store;
                feedIcon = ic_unselected_feed;
                spaceIcon = ic_selected_space;
                profileIcon = ic_unselected_profile;
                break;
            case "my-page":
                showCaseIcon = ic_unselected_showcase;
                storeIcon = ic_unselected_store;
                feedIcon = ic_unselected_feed;
                spaceIcon = ic_unselected_space;
                profileIcon = ic_selected_profile;
                break;
        }

        return(
            <div id="ContentExplorerBottomNavigationContainer">
                <div id="ContentExplorerBottomNavigation" style={{
                    backgroundColor:this.props.selectedItem === "home" || 
                    (this.props.selectedItem === "showcase" && !(getLocationQueries()["theme"]?.toUpperCase() === "LIGHT")) 
                        ? "black" 
                        : "white", 
                }}>
                    <button className="BottomNavigationItem" onClick={() => {
                        window.location.href = "/Store";
                    }}>
                        <img alt="assets" src={storeIcon} />
                    </button>
                    <button className="BottomNavigationItem">
                        <Link to="/Discover">
                            <img alt="assets" src={feedIcon} />
                        </Link>
                    </button>
                    <button className="BottomNavigationItem" onClick={() => {
                        window.location.href = "/ShowCase";
                    }}>
                        <img alt="assets" src={showCaseIcon} />
                    </button>
                    <button className="BottomNavigationItem" onClick={() => {
                        if (localStorage.fpAuth) {
                            if (this.props.creatorId) {
                                window.location.href = `/${this.props.creatorId}`;
                            } else {
                                this.props.updatePopUpModel(new PopUpWidgetModel({
                                    imageUrl: img_become_creator,
                                    title: "Share your day, get side hustle 🤟",
                                    description: "Dapatkan komisi s/d 5% dari postingan daily lifestyle-mu yang menarik!",
                                    mainButtonTitle: "Install App",
                                    mainButtonAction: () => {
                                        window.location.href = window.navigator.userAgent.includes("Android") ? 
                                            "https://play.google.com/store/apps/details?id=com.thefepi.shopable" : 
                                            "https://apps.apple.com/id/app/thefepi/id1590816062";
                                    },
                                    desctructiveButtonTitle: "Detail",
                                    desctructiveButtonAction: () => {
                                        window.location.href = "/CreatorIntroduction?device=web";
                                    }
                                }));
                            }
                        } else {
                            this.props.updatePopUpModel(PopUpWidgetModel.loginRequestPopUpModel);
                        }
                    }}>
                        <img alt="assets" src={spaceIcon} />
                    </button>
                    <button className="BottomNavigationItem">
                        <Link to="/MyPage">
                            <img alt="assets" src={profileIcon} />
                        </Link>
                    </button>
                </div>
            </div>
        );
    }
}