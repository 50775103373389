import React from "react"
import Scaffold from "../../CommonComponents/UI/Scaffold/Scaffold"
import "./ResellerNotFound.css"
import ic_search_not_found from "./Assets/ic_search_not_found.svg"

const ResellerNotFoundPage = () => {
  return (
    <Scaffold>
      <div className="ResellerNotFoundPageContainer">
        <img src={ic_search_not_found} alt=":("></img>
        <b>404</b>
        <p>Halaman Tidak Ditemukan</p>
      </div>
    </Scaffold>
  )
}

export default ResellerNotFoundPage