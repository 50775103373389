import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import { isValidEmail, isValidPhoneNumber } from "../../CommonComponents/Utils";

export type ResellerRegisterFormData = {
    email?: string;
    name?: string;
    phoneNumber?: string;
    address?: any;
    province?: string;
    city?: string;
    postalCode?: string;
    subscription?: number;
    age?: number;
    gender?: number;
    occupation?: string;
    pinpoint?: {
      title: string;
      addressLabel: string;
      latitude: number;
      longitude: number;
    }
};

export type ResellerRegisterResponse = {
    paymentCode: string;
};

export default class ResellerRegisterPageViewModel {
    email = "";
    name = "";
    phoneNumber = "";
    address = "";
    province = "";
    city = "";
    postalCode = "";
    subscription = -1;
    age: string | number = -1
    gender = -1;
    occupation = "";
    isSubmittable = false;
    isLoading = false;
    errorMessages: any = {};

    submitFormData = (
        formData: ResellerRegisterFormData,
        onSuccess: (response: BaseResponse<ResellerRegisterResponse>) => void,
        onFailure: (error: any) => void
    ) => {
        requestAPI(Endpoints.resellerRegister, {
            parameters: {
                email: formData.email,
                name: formData.name,
                waNumber: formData.phoneNumber,
                address: formData.address,
                province: formData.province,
                city: formData.city,
                postalCode: formData.postalCode,
                subscription: formData.subscription,
                age: formData.age,
                gender: formData.gender,
                occupation: formData.occupation,
                pinpoint: formData.pinpoint
            },
            onSuccess: (response: BaseResponse<ResellerRegisterResponse>) => {
                onSuccess(response);
            },
            onFailure: (error) => {
                onFailure(error);
            },
        });
    };

    validateFormData = (formData: ResellerRegisterFormData) => {
        this.errorMessages = {};

        this.errorMessages.email = formData.email ? (isValidEmail(formData.email) ? "" : "Email tidak valid") : "";
        this.errorMessages.phoneNumber = formData.phoneNumber
            ? isValidPhoneNumber(formData.phoneNumber)
                ? ""
                : "Nomor tidak valid"
            : "";
        this.errorMessages.age = formData.age ? (formData.age > 100 || formData.age < 15 ? "Umur harus di antara 15 dan 100" : "") : ""

        return this.errorMessages;
    };

    checkIfSubmittable = (formData: ResellerRegisterFormData) => {
        if (this.errorMessages.email || this.errorMessages.phoneNumber) {
            return false;
        }
        if (
            formData.email &&
            formData.name &&
            formData.phoneNumber &&
            formData.address &&
            formData.province &&
            formData.city &&
            formData.postalCode &&
            formData.subscription &&
            formData.subscription > 0 &&
            formData.pinpoint &&
            formData.age &&
            typeof formData.gender !== 'undefined' &&
            formData.gender >= 0 &&
            formData.occupation
        ) {
            // if(areAllPropertiesNotEmpty(formData)){
            return true;
        } else {
            return false;
        }
    };

    getSubscriptionIndex(value: number): number {
        for (let i = 0; i < this.subscriptionPlans.length; i++) {
            if (this.subscriptionPlans[i].value === value) {
                return i;
            }
        }
        return -1;
    }

    onEmailChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.email = event.target.value;
    };

    onNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.name = event.target.value;
    };

    onPhoneNumberChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.phoneNumber = event.target.value;
    };

    onAddressChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.address = event.target.value;
    };

    onProvinceChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.province = event.target.value;
    };

    onCityChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.city = event.target.value;
    };

    onPostalCodeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.postalCode = event.target.value;
    };

    onSubscriptionChanged = (subscription: number) => {
        this.subscription = subscription;
    };

    onAgeChanged = (ageGroup: number) => {
        this.age = ageGroup.toString()
    };

    onGenderChanged = (gender: number) => {
        this.gender = gender;
    };

    onOccupationChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.occupation = event.target.value;
    };

    ageGroups = [
        {
            min: 18,
            max: 22,
            text: "18-22 tahun",
        },
        {
            min: 23,
            max: 27,
            text: "23-27 tahun",
        },
        {
            min: 28,
            max: 32,
            text: "28-32 tahun",
        },
        {
            min: 33,
            max: 37,
            text: "32-37 tahun",
        },
        {
            min: 38,
            max: 123,
            text: ">37 tahun",
        },
    ];

    subscriptionPlans = [
        {
            value: 1,
            name: "1 bulan",
            price: {
                netPrice: "99K",
                original: "249K",
            },
        },
        {
            value: 3,
            name: "3 bulan",
            price: {
                netPrice: "199K",
                original: "399K",
            },
        },
        {
            value: 6,
            name: "6 bulan",
            price: {
                netPrice: "699K",
                original: "399K",
            },
        },
        {
            value: 12,
            name: "12 bulan",
            price: {
                netPrice: "499K",
                original: "999K",
            },
        },
    ];
}
