import ic_cart from "../../Assets/Images/ic_cart_dark.svg";
import ic_search from "../../Assets/Images/ic_search_dark.svg";

import "./StoreMainHome.css";

import { useParams } from "react-router-dom";
import ViewController from "../../CommonComponents/Base/ViewController";
import Scaffold from "../../CommonComponents/UI/Scaffold/Scaffold";
import StoreSectionFeedViewController from "../StoreSectionFeed/StoreSectionFeedViewController";
import StoreMainHomeViewModel from "./StoreMainHomeViewModel";
import NavigationButton from "../../CommonComponents/UI/ButtonWidget/NavigationButton";
import StoreTopTabbarWidget from "../CommonComponents/Widgets/StoreTopTabbarWidget";
import ProductGroupMainSubCategoryButton from "../ProductGroupMain/widgets/ProductGroupMainSubCategoryButton";
import JastipScreen from "../Jastip/JastipScreen";
import {
  productTypesForBadgeUrls,
  standarizeThefepiLink,
} from "../../CommonComponents/Utils";
import { ImageWidget } from "../../CommonComponents/UI/ImageWidget/ImageWidget";
import React from "react";

export default function StoreMainHomeScreen() {
  const { groupId } = useParams();

  return <StoreMainHomeViewController groupId={groupId} />;
}

type StoreMainHomeViewControllerProps = {
  groupId?: string;
};

class StoreMainHomeViewController extends ViewController<
  StoreMainHomeViewControllerProps,
  StoreMainHomeViewModel
> {
  constructor(props: StoreMainHomeViewControllerProps) {
    super(props, new StoreMainHomeViewModel(props.groupId ?? ""));
    
    this.viewModel.loadPreloadingProps();
    this.viewModel.loadPromotionBanner();

    this.handleScrolling = this.handleScrolling.bind(this);
    
    window.addEventListener("scroll", this.handleScrolling);
    this.lastScrollYPos = React.createRef(); 
    this.lastScrollYPos.current = -1;
  }

  lastScrollYPos: any;
  

  handleScrolling(e: any) {
    try {
      
    const bannerEl = document.getElementById('promotional-banner');
    if (bannerEl) {
      // @ts-ignore
      var top = ((window.pageYOffset || document.scrollTop)  - (document.clientTop || 0)) || 0;    
      // console.log({this.lastScrollYPos.current, top})
      if (top < 108) return;
      
      if (this.lastScrollYPos.current > top) {
        // up
        bannerEl.style.display = "block"
      } else {
        // down
        
        bannerEl.style.display = "none"
      }

      this.lastScrollYPos.current = top;
    }
    } catch (err) {
      
    }
  }

  componentWillUnmount(): void {    
    window.removeEventListener("scroll", this.handleScrolling);
  }


  renderPromotionalBanner(props?: {
    imageUrl?: string;
    deeplink?: string;
    isLoading?: boolean;
  }) {
    const aspectRatio = 0.125;
    if (!props?.deeplink) return null;

    return (
      <div id="promotional-banner" style={{ padding: "8px 16px 8px 16px" }}>
        <a
          href={standarizeThefepiLink(props?.deeplink || '')}
          style={{
            height: this.state.width * aspectRatio,
            borderRadius: 8,
            overflow: "hidden",
            display: "block",
          }}
        >
          {props?.isLoading ? (
            <div
              className="ShimmerAnimation"
              style={{ height: "100%", width: "100%" }}
            />
          ) : (
            <ImageWidget
              imageUrl={props?.imageUrl}
            />
          )}
        </a>
      </div>
    );
  }
  
  

  render() {
    const model = this.viewModel.model;
    const productGroups = this.viewModel.model?.productGroups;
    const categories = this.viewModel.model?.categories;
    const subCategories = this.viewModel.model?.subCategories;
    const subCategoryButtonWidth = (this.state.width - 68) / 4;

    return (
      <Scaffold
        isShowLoading={this.viewModel.isLoading}
        creatorId={this.viewModel.creatorId}
        isCreator={this.viewModel.isCreator}
        showErrorModel={this.viewModel.errorMessage}
        selectedBottomBarItem="store"
        topBar={
          <>
            <p style={{ fontSize: "24px", fontWeight: "bold" }}>Shop</p>
            <div className="FlexFillSpacing"></div>
            <NavigationButton
              src={ic_search}
              onClick={() => {
                window.location.href = "/Store/Search";
              }}
            />
            <NavigationButton
              src={ic_cart}
              numberOnRightTop={this.viewModel.numberInCart}
              onClick={() => {
                window.location.href = "/Cart";
              }}
            />
          </>
        }
      >
        <div id="StoreMainHomeMainContentContainer">
          <div>
            {this.renderPromotionalBanner({
              imageUrl: this.viewModel.promotionBanner.imageUrl,
              deeplink: this.viewModel.promotionBanner.deeplink,
              isLoading: this.viewModel.isLoadingPromotionBanner,
            })}
            {productGroups && (
              <StoreTopTabbarWidget
                selectedId={this.viewModel.groupId}
                labels={productGroups.map((model) => {
                  return {
                    id: model.id,
                    label: model.label,
                    onTap: () => (window.location.href = `/Store/${model.id}`),
                  };
                })}
              />
            )}
          </div>
          {this.viewModel.groupId.toLowerCase() === "jastip" ? (
            <JastipScreen isPromotionBannerActive={Boolean(this.viewModel.promotionBanner.deeplink)}/>
          ) : (
            <div
              id="StoreProductScrollableContainer"
              style={{ marginTop: productGroups ? 32 + (Boolean(this.viewModel.promotionBanner.deeplink)  ? 90 : 0) : "0px" }}
            >
              {categories && categories.length > 0 && (
                <div id="ProductGroupMainSubCategoryContainer">
                  {categories.map((model) => {
                    return (
                      <ProductGroupMainSubCategoryButton
                        key={`ProductGroupMainSubCategoryButton_${model.id}`}
                        buttonWidth={subCategoryButtonWidth}
                        imageUrl={model.imageUrl ?? ""}
                        groupId={this.viewModel.groupId}
                        categoryId={""}
                        subCategoryId={model.id}
                        title={model.label}
                      />
                    );
                  })}
                </div>
              )}
              {subCategories && subCategories.length > 0 && (
                <div id="ProductGroupMainSubCategoryContainer">
                  {subCategories.map((model) => {
                    return (
                      <ProductGroupMainSubCategoryButton
                        key={`ProductGroupMainSubCategoryButton_${model.id}`}
                        buttonWidth={subCategoryButtonWidth}
                        imageUrl={model.imageUrl ?? ""}
                        groupId={this.viewModel.groupId}
                        categoryId={""}
                        shouldIgnoreCategoryId
                        subCategoryId={model.id}
                        title={model.label}
                      />
                    );
                  })}
                </div>
              )}
              {(model?.feeds ?? []).map((feed, index) => {
                feed.contents.bannersProducts =
                  feed.contents.bannersProducts.map((bp) => ({
                    ...bp,
                    products: (bp.products || []).map((p) => ({
                      ...p,
                      badgeUrls: productTypesForBadgeUrls(
                        p.badgeTypes,
                        this.viewModel.badgeUrlsMap || {}
                      ),
                    })),
                  }));

                if (feed.contents.singleBannerProducts) {
                  feed.contents.singleBannerProducts.products =
                    feed.contents.singleBannerProducts?.products.map((p) => ({
                      ...p,
                      badgeUrls: productTypesForBadgeUrls(
                        p.badgeTypes,
                        this.viewModel.badgeUrlsMap || {}
                      ),
                    }));
                }

                feed.contents.products = feed.contents.products.map((p) => ({
                  ...p,
                  badgeUrls: productTypesForBadgeUrls(
                    p.badgeTypes,
                    this.viewModel.badgeUrlsMap || {}
                  ),
                }));
                // console.log(this.viewModel.badgeUrlsMap)
                return (
                  <StoreSectionFeedViewController
                    badgeUrlsMap={this.viewModel.badgeUrlsMap}
                    key={`StoreSectionViewController12_${index}`}
                    model={feed}
                    id={`StoreSection_${index}`}
                  />
                );
              })}
            </div>
          )}
        </div>
      </Scaffold>
    );
  }
}
