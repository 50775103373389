import React from 'react';

import ic_chat_gray from '../../Assets/Images/ic_chat_gray.svg'

import Scaffold from '../../CommonComponents/UI/Scaffold/Scaffold';
import './Channel.css'
import ButtonWidget from '../../CommonComponents/UI/ButtonWidget/ButtonWidget';
import SizedDiv from '../../CommonComponents/UI/SizedDiv';

class ChannelSinglePageScreen extends React.Component {
    render() {
        return (
            // <div className="container h-100 d-flex" style={{ backgroundColor: 'aliceblue' }}>
            //     <div className="row align-self-center" style={{ width: '105%' }}>
            //         <div className="col-12 mx-auto text-center">
            //             <h5><b>Hello, Selamat datang. Ada yang bisa kami bantu?</b></h5>
            //             <h6>Silahkan klik logo dibawah</h6><br></br>
            //             <a rel="noreferrer" id="custom-button-1" href="#;"><img width="80" src={global.ImgUrl + 'shopable/9937B910-0DF2-452B-A6DE-F5C47529F3CD.png'} alt="io" /></a>
            //         </div>
            //     </div>
            // </div>
            <Scaffold>
              <div className="WebChatComingSoonPage">
                <div className="WebChatComingSoonContent">
                  <div className="WebChatComingSoonImage">
                    <img alt="chat" src={ic_chat_gray} />
                  </div>
                  <SizedDiv height={8} />
                  <h4 style={{fontSize: "18px"}}>Coming Soon</h4>
                  <p style={{fontSize: "14px"}}>Web Chat unavailable for now</p>
                </div>
                <div className="WebChatComingSoonButton">
                  <ButtonWidget 
                    action={()=>window.open(`https://wa.me/6282288886784?text=${"Halo thefepi"}`,"_blank")}
                  >
                    Contact through Whatsapp
                  </ButtonWidget>
                </div>
              </div>
            </Scaffold>
        );
    }
}

export default ChannelSinglePageScreen;