import React from "react";
import { 
    EmailShareButton, EmailIcon, 
    FacebookShareButton, FacebookIcon, 
    LinkedinShareButton, LinkedinIcon, 
    LineShareButton, LineIcon, 
    TelegramShareButton, TelegramIcon, 
    TwitterShareButton, TwitterIcon, 
    WhatsappShareButton, WhatsappIcon 
} from "react-share";
import ButtonWidget, { ButtonWidgetType } from "../ButtonWidget/ButtonWidget";
import { ShareUrlModel } from "./Models/ShareUrlModel";

import "./ShareUrlPopUpWidget.css";

import ic_copy_link from "../../../Assets/Images/SocialMedia/ic_custom_link_round.png";
import { eventManager } from "../../../Analytics/Events/Common";
import { ShareEvent } from "../../../Analytics/Events/GA4/ShareEvent";
import { isResellerDomain } from "../../Utils";
import mixpanel from "mixpanel-browser";

type ShareUrlPopUpWidgetProps = {
    model: ShareUrlModel,
    onCopyUrlTapped: () => void
}

export default class ShareUrlPopUpWidget extends React.Component<ShareUrlPopUpWidgetProps, {isShowing: boolean}> {
    constructor(props: ShareUrlPopUpWidgetProps) {
        super(props);

        this.state = { isShowing: true };
    }

    onMethodShareButtonClicked(method: string) {
        eventManager.add({
          method: method
        });
        eventManager.fire(ShareEvent);
        // eventManager.flush();

        // MIXPANEL TRACKING: semo share
        if(isResellerDomain()){
          mixpanel.track("share", JSON.parse(localStorage.getItem("__EVENT_MANAGER_STATE__") || "{}"))
        }
    }

    render() {
        const urlString = this.props.model.urlString;
        return(
            <>
                {
                    this.state.isShowing ?
                    <div id="ShareUrlPopUpBackground">
                        <div>
                            <p>Share to:</p>
                            <div>
                                <button onClick={() => {
                                    navigator.clipboard.writeText(urlString);
                                    this.setState({isShowing: false});
                                    this.props.onCopyUrlTapped();
                                    this.onMethodShareButtonClicked("Link")
                                }}>
                                    <div id="ShareButtonContainer">
                                        <img src={ic_copy_link} alt="" style={{height:"44px", width:"44px"}}/>
                                        <p>Copy Link</p>
                                    </div>
                                </button>
                                <EmailShareButton url={urlString} subject="Let's explore more in thefepi" onClick={()=>this.onMethodShareButtonClicked("Email")}>
                                    <div id="ShareButtonContainer">
                                        <EmailIcon size={44} round={true}/>
                                        <p>Email</p>
                                    </div>
                                </EmailShareButton>
                                <FacebookShareButton url={urlString} quote="Let's explore more in thefepi" onClick={()=>this.onMethodShareButtonClicked("Facebook")}>
                                    <div id="ShareButtonContainer">
                                        <FacebookIcon size={44} round={true}/>
                                        <p>Facebook</p>
                                    </div>
                                </FacebookShareButton>
                                <LinkedinShareButton url={urlString} onClick={()=>this.onMethodShareButtonClicked("LinkedIn")}>
                                    <div id="ShareButtonContainer">
                                        <LinkedinIcon size={44} round={true}/>
                                        <p>LinkedIn</p>
                                    </div>
                                </LinkedinShareButton>
                                <LineShareButton url={urlString} onClick={()=>this.onMethodShareButtonClicked("Line")}>
                                    <div id="ShareButtonContainer">
                                        <LineIcon size={44} round={true}/>
                                        <p>Line</p>
                                    </div>
                                </LineShareButton>
                                <TelegramShareButton url={urlString} onClick={()=>this.onMethodShareButtonClicked("Telegram")}>
                                    <div id="ShareButtonContainer">
                                        <TelegramIcon size={44} round={true}/>
                                        <p>Telegram</p>
                                    </div>
                                </TelegramShareButton>
                                <TwitterShareButton url={urlString} onClick={()=>this.onMethodShareButtonClicked("Twitter")}>
                                    <div id="ShareButtonContainer">
                                        <TwitterIcon size={44} round={true}/>
                                        <p>Twitter</p>
                                    </div>
                                </TwitterShareButton>
                                <WhatsappShareButton url={urlString} onClick={()=>this.onMethodShareButtonClicked("WhatsApp")}>
                                    <div id="ShareButtonContainer">
                                        <WhatsappIcon size={44} round={true}/>
                                        <p>WhatsApp</p>
                                    </div>
                                </WhatsappShareButton>
                            </div>
                            <ButtonWidget buttonType={ButtonWidgetType.primary} action={() => this.setState({isShowing: false})}>Cancel</ButtonWidget>
                        </div>
                    </div> :
                    <></>
                }
            </>
        );
    }
}