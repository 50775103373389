import React from "react";
import ViewController from "../../CommonComponents/Base/ViewController";
import TrackResellersOrderViewModel from "./TrackResellersOrderViewModel";
import Scaffold from "../../CommonComponents/UI/Scaffold/Scaffold";
import "./TrackResellersOrderViewController.css";
import ic_back from "../../Assets/Images/ic_chevron_left.svg";
import NavigationButton from "../../CommonComponents/UI/ButtonWidget/NavigationButton";
import InputFieldWidget from "../../CommonComponents/UI/InputFieldWidget/InputFieldWidget";
import TrackIdField from "./Widgets/TrackIdField";
import SizedDiv from "../../CommonComponents/UI/SizedDiv";
import ButtonWidget from "../../CommonComponents/UI/ButtonWidget/ButtonWidget";
import TrackingDetails from "./Widgets/TrackingDetails";
import { LoadingWidget } from "../../CommonComponents/UI/LoadingWidget/LoadingWidget";
import { useParams } from "react-router-dom";
import { isResellerDomain } from "../../CommonComponents/Utils";
import mixpanel from "mixpanel-browser";

function TrackResellersOrderScreen(props: any){
  let { creatorLink, orderId } = useParams();
  return <TrackResellersOrderViewController {...props} id={orderId} creatorLink={creatorLink} />
}

class TrackResellersOrderViewController extends ViewController<
  { id?: string, creatorLink?: string},
  TrackResellersOrderViewModel
> {
  constructor(args: any) {
    super(args, new TrackResellersOrderViewModel());
    const params = new URLSearchParams(window.location.search);
    this.viewModel.id = this.props.id || params.get("id") || "";
    if (this.viewModel.id) {
      this.viewModel.isLoadingTrackingResult = true;
      this.viewModel.loadTracking();
    }

    //MIXPANEL TRACKING: semo_page_view
    if (isResellerDomain()) {
      mixpanel.track("semo_page_view", {
        page_name: "TrackMyOrder",
      });
    }
  }

  render(): React.ReactNode {
    return (
      <Scaffold
        showSnackbar={this.viewModel.snackbar}
        topBar={
          <>
            <NavigationButton
              src={ic_back}
              onClick={() => (window.location.href = "/"+this.props.creatorLink)}
            />
            <p
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                marginLeft: "8px",
              }}
            >
              Track Order
            </p>
          </>
        }
      >
        <SizedDiv height={8} color="#f5f5f5" />
        <div style={{ padding: 24 }}>
          <p style={{ fontWeight: 500, fontSize: 12 }}>Order ID</p>
          <SizedDiv height={8} />
          <TrackIdField
            value={this.viewModel.id}
            onChange={(val) => {
              this.viewModel.changeId(`${val}` as string);
            }}
            placeholder="Enter your order id here"
          />
          <SizedDiv height={16} />
          <ButtonWidget
            isLoading={this.viewModel.isLoadingTrackingResult}
            action={() => {
              this.viewModel.loadTracking();
            }}
          >
            Track Order
          </ButtonWidget>
        </div>
        <SizedDiv height={8} color="#f5f5f5" />
        <div style={{ padding: 24 }}>
          {this.viewModel.isLoadingTrackingResult ? (
            <LoadingWidget />
          ) : this.viewModel.loadTrackingResult ? (
            <TrackingDetails
              orderDate={this.viewModel.loadTrackingResult?.orderTime}
              orderId={this.viewModel.id}
              trackingCount={
                this.viewModel.loadTrackingResult?.orderShipments.length
              }
              trackings={this.viewModel.loadTrackingResult?.orderShipments}
            />
          ) : (
            <></>
          )}
          {/* <TrackingDetails
            orderDate="31/08/2021 13:21:22"
            orderId="2105505055"
            trackingCount={3}
            trackings={[
              {
                courierDetail: {
                  imageUrl: "https://jnewsonline.com/wp-content/uploads/2021/11/Foto-2-Naskah-Mengenal-Sosok-Kreator-Logo-%E2%80%98Biru-Tua-Merah-JNE.jpg",
                  label: "JNE YES (1+3) DAYS",
                  receipt: "02183812038123001238",
                },
                trackingDetailList: [
                  {
                    description: "Delivery Complete",
                    timeLabel: "15/09/2021 13:20:21",
                  },
                  {
                    description: "Delivery Complete",
                    timeLabel: "15/09/2021 13:20:21",
                  },
                  {
                    description: "Delivery Complete",
                    timeLabel: "15/09/2021 13:20:21",
                  },
                  {
                    description: "Delivery Complete",
                    timeLabel: "15/09/2021 13:20:21",
                  },
                  {
                    description: "Delivery Complete",
                    timeLabel: "15/09/2021 13:20:21",
                  },
                  {
                    description: "Delivery Complete",
                    timeLabel: "15/09/2021 13:20:21",
                  },
                ],
                products: [
                  {
                    attributes: "Navy, Blue S",
                    brand: "VANS",
                    name: "VANS X One Piece - Electric Chair",
                    imageUrl: "https://images.thefepi.com/files/1/products/files/files/133083/133083-697685.webp",
                  },
                  {
                    attributes: "Navy, Blue S",
                    brand: "VANS",
                    name: "VANS X One Piece - Electric Chair",
                    imageUrl: "https://images.thefepi.com/files/1/products/files/files/133083/133083-697685.webp",
                  },
                  {
                    attributes: "Navy, Blue S",
                    brand: "VANS",
                    name: "VANS X One Piece - Electric Chair",
                    imageUrl: "https://images.thefepi.com/files/1/products/files/files/133083/133083-697685.webp",
                  },
                  {
                    attributes: "Navy, Blue S",
                    brand: "VANS",
                    name: "VANS X One Piece - Electric Chair",
                    imageUrl: "https://images.thefepi.com/files/1/products/files/files/133083/133083-697685.webp",
                  },
                  {
                    attributes: "Navy, Blue S",
                    brand: "VANS",
                    name: "VANS X One Piece - Electric Chair",
                    imageUrl: "https://images.thefepi.com/files/1/products/files/files/133083/133083-697685.webp",
                  },
                  {
                    attributes: "Navy, Blue S",
                    brand: "VANS",
                    name: "VANS X One Piece - Electric Chair",
                    imageUrl: "https://images.thefepi.com/files/1/products/files/files/133083/133083-697685.webp",
                  },
                ],
              },
              {
                courierDetail: {
                  imageUrl: "https://jnewsonline.com/wp-content/uploads/2021/11/Foto-2-Naskah-Mengenal-Sosok-Kreator-Logo-%E2%80%98Biru-Tua-Merah-JNE.jpg",
                  label: "AnterAja (1+3) DAYS",
                  receipt: "0912038910283",
                },
                trackingDetailList: [
                  {
                    description: "Paket Hilang",
                    timeLabel: "15/09/2021 13:20:21",
                  },
                ],
                products: [
                  {
                    attributes: "Navy, Blue S",
                    brand: "VANS",
                    name: "VANS X One Piece - Electric Chair",
                    imageUrl: "https://images.thefepi.com/files/1/products/files/files/133083/133083-697685.webp",
                  },
                  {
                    attributes: "Navy, Blue S",
                    brand: "VANS",
                    name: "VANS X One Piece - Electric Chair",
                    imageUrl: "https://images.thefepi.com/files/1/products/files/files/133083/133083-697685.webp",
                  },
                ],
              },
            ]}
          /> */}
        </div>
      </Scaffold>
    );
  }
}

// export default TrackResellersOrderViewController;
export default TrackResellersOrderScreen;