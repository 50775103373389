import { useEffect } from 'react';
import './App.css';
import { FepiRouter } from './Router/FepiRouter';
import 'bootstrap/dist/css/bootstrap.css';
import { isResellerDomain } from './CommonComponents/Utils';
import { mixpanelManager } from './Analytics/Mixpanel/Common';

global.ImgUrl = 'https://images.thefepi.com/file/';
global.ApiServerLive = 'https://staging.shopable.co.id/mobile/';

function App() {
  if (isResellerDomain()) {
    mixpanelManager.init();
  }

  return (
    <FepiRouter />
  );
}

export default App;
