import React from "react";
import ViewController from "../../CommonComponents/Base/ViewController";
import ResellerStorefrontViewModel from "./ResellerStorefrontViewModel";
import Scaffold from "../../CommonComponents/UI/Scaffold/Scaffold";
import "./ResellerStorefrontViewController.css";
import { ImageWidget } from "../../CommonComponents/UI/ImageWidget/ImageWidget";

import ic_instagram from "../../Assets/Images/SocialMedia/ic_instagram_round.png";
import ic_share from "../../Assets/Images/ic_share_box.svg";
import ic_youtube from "../../Assets/Images/SocialMedia/ic_youtube_round.png";
import ic_tiktok from "../../Assets/Images/SocialMedia/ic_tiktok_round.png";
import ic_link from "../../Assets/Images/SocialMedia/ic_custom_link_round.png";
import ic_shop from "../../Assets/Images/ic_shop_icon.svg";
import ic_shop_not_found from "./Assets/ic_shop_not_found.svg";
import ic_back from "../../Assets/Images/ic_chevron_left.svg";
import ic_chevron_left from "../../Assets/Images/ic_chevron_left.svg";
import ic_cart from "../../Assets/Images/ic_cart.svg";
import ic_cart_black from "../../Assets/Images/ic_cart_black.svg";
import ic_chat from "../../Assets/Images/ic_chat.svg"

import SizedDiv from "../../CommonComponents/UI/SizedDiv";
import BaseUIComponent from "../../CommonComponents/Base/BaseUIComponent";
import ProductSummaryItemCellWidget from "../../CommonComponents/UI/Cells/ProductSummaryItemCellWidget";
import { DivWithIntersectionObserver } from "../../CommonComponents/UI/DivWithIntersectionObserver/DivWithIntersectionObserver";
import { LoadingWidget } from "../../CommonComponents/UI/LoadingWidget/LoadingWidget";
import { useParams } from "react-router-dom";
import { LoadingView } from "../../CommonComponents/UI/LoadingWidget/LoadingView";
import BackButton from "../../CommonComponents/UI/BackButton/BackButton";
import { getResellerPhoneNumber, isResellerDomain } from "../../CommonComponents/Utils";
import { eventManager } from "../../Analytics/Events/Common";
import { ShareUrlModel } from "../../CommonComponents/UI/ShareUrlPopUp/Models/ShareUrlModel";
import { mixpanelManager } from "../../Analytics/Mixpanel/Common";
import mixpanel from "mixpanel-browser";

class ResellerStorefrontViewController extends BaseUIComponent<{ id?: string }, ResellerStorefrontViewModel> {
  constructor(args: any) {
    super(args, new ResellerStorefrontViewModel(args.id));
    this.viewModel().refresh();

    if (this.viewModel().fresh) {
      // this.viewModel().loadSpaceDetail();
      this.viewModel().loadPreloadingProps();

      // MIXPANEL TRACKING: view_reseller
      mixpanelManager.track("view_reseller", {
        reseller_username: this.props.id
      });
      
      //MIXPANEL TRACKING: semo_page_view
      mixpanel.track("semo_page_view", {
        page_name: "ResellerShop"
      })
    }

    this.onEndReached = this.onEndReached.bind(this);
    window.addEventListener("scroll", () => this.handleScrolling());
  }

  componentWillUnmount(): void {
    this.viewModel().persist();
    window.removeEventListener("scroll", () => this.handleScrolling());
  }

  onEndReached() {
    if (!this.viewModel().isLoading) {
      this.viewModel().loadProducts();
    }
  }

  render(): React.ReactNode {
    const vm = this.viewModel();

    if(vm.detail?.sellerProfile.name) localStorage.setItem("resellerName", vm.detail?.sellerProfile.name)

    const sellerDescription = this.viewModel().detail?.sellerProfile.description
      ? JSON.parse(`"${this.viewModel().detail?.sellerProfile.description.replaceAll(/\n/g, `<br>`)}"`).replaceAll(
          `<br>`,
          `\n`
        )
      : "";

    const shouldLoadMore = (this.viewModel().products?.length || 0) < this.viewModel().totalProducts;

    // console.log({pl: this.viewModel().products})

    return (
      <Scaffold
        // isShowLoading={this.viewModel().isLoading}
        // showErrorModel={this.viewModel().errorMessage}
        showSnackbar={this.viewModel().snackbarMessage}
        creatorId={this.viewModel().creatorId}
        isCreator={this.viewModel().isCreator}
        shareUrlModel={this.viewModel().shareUrlModel}
        showPopUpModel={this.viewModel().popUpModel}
      >
        {!this.viewModel().errorMessage ? (
          <div id="STOREFRONT_VIEW" onScroll={() => this.handleScrolling()}>
            <div className="ResellerStorefrontViewControllerImageCover">
              <div className="ResellerStorefrontViewControllerShareButtonPositioner">
                <button
                  style={{ padding: 16 }}
                  onClick={() => {
                    this.viewModel().onShareLink();
                  }}
                >
                  {/* <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M6.14286 7.5V7.5C4.95939 7.5 4 8.45939 4 9.64286V14.5C4 16.1569 5.34315 17.5 7 17.5H13C14.6569 17.5 16 16.1569 16 14.5V9.64286C16 8.45939 15.0406 7.5 13.8571 7.5V7.5"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path d="M7.5 5.5L10 3L12.5 5.5" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                    <line x1="10" y1="3" x2="10" y2="12" stroke="white" stroke-linecap="round" />
                  </svg> */}
                </button>
              </div>
              <ImageWidget objectFit="cover" imageUrl={vm.detail?.backgroundImageUrls?.[0]} />
            </div>
            <div className="ResellerStorefrontViewControllerContent">
              <div className="ResellerStorefrontViewControllerProfilePositioner">
                <div className="ResellerStorefrontViewControllerResellerProfileImage">
                  <ImageWidget objectFit="cover" imageUrl={vm.detail?.sellerProfile.imageUrl} />
                </div>
              </div>
              <SizedDiv height={48} />
              <div className="ResellerStorefrontViewControllerName">{vm.detail?.sellerProfile.name}</div>
              <div className="ResellerStorefrontViewControllerDescription">{sellerDescription}</div>
              <div className="ResellerStorefrontViewControllerSocialLinks">
                {vm.detail?.socialLinks.map((model, _) => {
                  let title: string;
                  let image: string;

                  switch (model.type) {
                    case "INSTAGRAM":
                      title = "Instagram";
                      image = ic_instagram;
                      break;
                    case "TIKTOK":
                      title = "TikTok";
                      image = ic_tiktok;
                      break;
                    case "YOUTUBE":
                      title = "YouTube";
                      image = ic_youtube;
                      break;
                    default:
                      title = model.url;
                      image = ic_link;
                      break;
                  }

                  return (
                    <button
                      onClick={() => {
                        window.location.href = model.url;
                      }}
                      key={model.url}
                      className="ResellerStorefrontViewControllerSocialLink"
                    >
                      <img
                        src={image}
                        className="ResellerStorefrontViewControllerSocialImageIcon"
                        alt=""
                        key={`${model.url}_image`}
                      />

                      <span className="ResellerStorefrontViewControllerSocialLinkLabel">{title}</span>
                    </button>
                  );
                })}
              </div>
              <div className="ResellerStorefrontViewControllerTitle">Products</div>
              <div className="ResellerStorefrontViewControllerProductGrid">
                {vm.isLoading && (vm.products || []).length <= 0
                  ? Array.from({ length: 6 }).map((v) => (
                      <div
                        className="ShimmerAnimation"
                        style={{
                          height: (this.state.width - 60) / 2,
                          width: (this.state.width - 60) / 2,
                        }}
                      />
                    ))
                  : vm.products?.map((product) => {
                      return (
                        <ProductSummaryItemCellWidget
                          model={product}
                          sellerId={this.props.id}
                          cellWidth={(this.state.width - 60) / 2}
                        />
                      );
                    })}
              </div>
              {shouldLoadMore && (
                <DivWithIntersectionObserver onVisibilityChanged={this.onEndReached}>
                  <LoadingWidget />
                </DivWithIntersectionObserver>
              )}
            </div>
            <div id="SpaceNavigationBackground" style={{ opacity: "0" }} />
            <div id="SpaceHomeNavContainer">
              {!isResellerDomain() && (
                <button
                  id="SpaceHomeButton"
                  onClick={() => (window.location.href = isResellerDomain() ? "/" + this.props.id : "/")}
                >
                  <img src={ic_back} alt="" />
                </button>
              )}
              <div id="ProductNameNavbar" style={{ opacity: "0", paddingTop: "1px", marginLeft: isResellerDomain() ? "16px" : undefined }}>
                <span>
                  {this.props.id &&
                    (this.props.id.length > 19 ? this.props.id.substring(0, 19) + "..." : this.props.id)}
                </span>
              </div>
              <button
                onClick={()=>window.open(
                  `https://wa.me/${getResellerPhoneNumber(this.props.id)}?text=Halo ${vm.detail?.sellerProfile.name}`, "_blank"
                )}
                id="ChatButton"
                style={{
                  opacity: "1",
                  // top: "1px",
                  position: "absolute",
                  right: "103px",
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  borderRadius: "19px",
                  width: "36px",
                  height: "36px",
                }}
              >
                <img alt="chat" src={ic_chat} />
              </button>
              <button
                onClick={() => this.viewModel().onShareLink()}
                id="ProductShareButton"
                style={{
                  opacity: "1",
                  // top: "1px",
                  position: "absolute",
                  right: "59px",
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  borderRadius: "19px",
                  width: "36px",
                  height: "36px",
                }}
              >
                <img src={ic_share} alt="share" style={{ marginTop: "-1px" }}></img>
              </button>
              <button
                id="SpaceCartButton"
                style={{ top: 0 }}
                onClick={() => {
                  window.location.href = isResellerDomain() ? `/shop/${this.props.id}/Cart` : "/Cart";
                }}
              >
                {isResellerDomain() ? (
                  <img src={ic_cart_black} alt="" style={{ height: "24px" }} />
                ) : (
                  <img src={ic_cart} alt="" />
                )}
                {(this.viewModel().numberInCart ?? 0) > 0 && <div>{this.viewModel().numberInCart}</div>}
              </button>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              flexDirection: "column",
              justifyContent: "center",
              minHeight: "inherit",
            }}
          >
            <img src={ic_shop_not_found} alt=":("></img>
            <SizedDiv height={12} />
            <p>Toko yang dicari tidak ditemukan</p>
          </div>
        )}
      </Scaffold>
    );
  }

  private handleScrolling() {
    const containerElement = document.querySelector("#STOREFRONT_VIEW");
    const containerRect = containerElement?.getBoundingClientRect();
    let transparencyValue = (containerRect?.y ?? 0) / -((this.state.width * 3) / 2 + 16);
    transparencyValue = transparencyValue > 1 ? 1 : transparencyValue;

    // const backButton = document.querySelector("#SpaceHomeButton");
    const navBar = document.querySelector("#SpaceNavigationBackground");
    const titleInNavBar = document.querySelector("#ProductNameNavbar");
    if (navBar && titleInNavBar) {
      // (backButton as any).style.opacity = transparencyValue;
      (navBar as any).style.opacity = transparencyValue;
      (titleInNavBar as any).style.opacity = transparencyValue;
    }
  }
}

const WrappedResellerStorefrontViewController = () => {
  const { id } = useParams();

  return <ResellerStorefrontViewController id={id}></ResellerStorefrontViewController>;
};

export default WrappedResellerStorefrontViewController;
