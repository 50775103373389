import React, { useEffect, useState } from "react";
import Scaffold, { ShowSnackbarModel } from "../../CommonComponents/UI/Scaffold/Scaffold";
import InputFieldWidget from "../../CommonComponents/UI/InputFieldWidget/InputFieldWidget";
import SizedDiv from "../../CommonComponents/UI/SizedDiv";
import TextAreaWidget from "../../CommonComponents/UI/TextAreaWidget/TextAreaWidget";

import "./ResellerRegister.css";
import ResellerRegisterViewModel, { ResellerRegisterFormData } from "./ResellerRegisterViewModel";
import ButtonWidget from "../../CommonComponents/UI/ButtonWidget/ButtonWidget";
import $, { event } from "jquery";
import { LoadingWidget } from "../../CommonComponents/UI/LoadingWidget/LoadingWidget";
import ic_information from "../../Assets/Images/ic_information.svg";
import ic_pinpoint_black from "../../Assets/Images/ic_pinpoint_black.svg";
import ic_pinpoint_grey from "../../Assets/Images/ic_pinpoint_grey.svg";
import ResellerRegisterPinpointPage from "./ResellerRegisterPinpointPage";
import { Link } from "react-router-dom";
import { mixpanelManager } from "../../Analytics/Mixpanel/Common";
import mixpanel from "mixpanel-browser";

const ResellerRegisterPage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const plan = parseInt(urlParams.get("plan") || "-1");

  const viewModel = new ResellerRegisterViewModel();
  const [formData, setFormData] = useState<ResellerRegisterFormData>(
    plan >= 0 ? { subscription: viewModel.subscriptionPlans[plan].value } : {}
  );
  const [isSubmittable, setIsSubmittable] = useState(false);
  const [errorMessages, setErrorMessages] = useState<any>({});
  const [isLoading, setisLoading] = useState(false);
  const [error, setError] = useState<{
    message: string;
    onRetryButtonTapped: () => void;
  } | null>(null);
  const [location, setLocation] = useState({});
  const [isPinpointSet, setIsPinpointSet] = useState(Boolean(formData.pinpoint));

  useEffect(() => {
    if (plan >= 0) {
      $("#subscription_" + plan).trigger("click");
    }
    onLocationChange();
  }, []);

  useEffect(() => {
    // console.log("formdata", formData);
    setErrorMessages(viewModel.validateFormData(formData));
    setIsSubmittable(viewModel.checkIfSubmittable(formData));
  }, [formData]);

  const onSetAddressPinpoint = () => {
    localStorage.setItem("resellerRegisterFormData", JSON.stringify(formData));
  };

  const onLocationChange = () => {
    const parsedData: ResellerRegisterFormData = {
      ...JSON.parse(localStorage.getItem("resellerRegisterFormData") || "{}"),
      ...formData,
    };
    console.log("localstorage", localStorage.getItem("resellerRegisterFormData"));
    console.log("parsed", parsedData);
    console.log("retrieving pinpoint");
    var newFormData: ResellerRegisterFormData = {
      ...formData,
      ...parsedData,
    };
    if (localStorage.getItem("pinpointTitle")) {
      const pinpoint = {
        title: localStorage.getItem("pinpointTitle") || "",
        addressLabel: localStorage.getItem("pinpointAddressLabel") || "",
        latitude: parseFloat(localStorage.getItem("pinpointLatitude") || ""),
        longitude: parseFloat(localStorage.getItem("pinpointLongitude") || ""),
      };
      console.log("pinpoint", pinpoint);
      setFormData({
        pinpoint: pinpoint,
        ...formData,
      });
      localStorage.removeItem("pinpointTitle");
      localStorage.removeItem("pinpointAddressLabel");
      localStorage.removeItem("pinpointLatitude");
      localStorage.removeItem("pinpointLongitude");
      setIsPinpointSet(true);
      newFormData = {
        ...newFormData,
        pinpoint: pinpoint,
      };
    } else {
      setIsPinpointSet(false);
    }

    console.log("newformdata", newFormData);
    setFormData({...newFormData, ...formData});
    $("#addressInput").text(parsedData.address);

    if (parsedData.subscription) {
      // setTimeout(() => {
      $("#subscription_" + viewModel.getSubscriptionIndex(parsedData.subscription || -1)).trigger("click");
      // }, 100);
    }

    if (parsedData.gender?.toString()) {
      // setTimeout(() => {
      $("#gender_" + parsedData.gender).trigger("click");
      console.log("clicking #gender_" + parsedData.gender);
      // }, 100);
    }

    console.log("formdata after that", formData);
    localStorage.removeItem("resellerRegisterFormData");
  };

  const _retrievePinpoint = () => {};

  const handleSubmitForm = () => {
    setIsSubmittable(false);
    setisLoading(true);
    viewModel.submitFormData(
      formData,
      function onSuccess(res) {
        // MIXPANEL TRACKING: semo_register
        var eventProperties = {
          email: formData.email,
          name: formData.name,
          phone_wa: formData.phoneNumber,
          address_detail: formData.address,
          province: formData.province,
          city: formData.city,
          postal_code: formData.postalCode,
          subscription_month: formData.subscription,
          age: formData.age,
          gender: formData.gender === 1 ? "Male" : "Female",
          occupation: formData.occupation
        }
        mixpanel.track("semo_register", eventProperties, undefined, (lmao: any)=>{
          console.log("callback called", lmao)
          window.location.href = `/payment/${res.data.paymentCode}?plan=${viewModel.getSubscriptionIndex(
          formData.subscription || 0
        )}`;
        })
      },
      function onFailure(error) {
        setIsSubmittable(true);
        setisLoading(false);
        setError({ message: error, onRetryButtonTapped: () => handleSubmitForm() });
      }
    );
  };

  return (
    <>
      <Scaffold showErrorModel={error || null}>
        <div className="container px-4">
          <SizedDiv height={24} />
          <form autoComplete="on">
            <div className="row">
              <div className="col">
                <InputFieldWidget
                  title="Email"
                  inputType="email"
                  isEnabled={true}
                  placeholder={"Ketik di sini"}
                  errorMessage={errorMessages.email || ""}
                  action={(e) => setFormData({ ...formData, email: e.target.value })}
                  variant="outlined"
                  isRequired={true}
                  name="email"
                  autoComplete="on"
                  value={formData.email}
                />
              </div>
            </div>
            <div className="row">
              <div className="col mtop22">
                <InputFieldWidget
                  title="Nama"
                  inputType="text"
                  isEnabled={true}
                  placeholder={"Ketik di sini"}
                  errorMessage={""}
                  action={(e) => setFormData({ ...formData, name: e.target.value })}
                  variant="outlined"
                  isRequired={true}
                  name="name"
                  autoComplete="on"
                  value={formData.name}
                />
              </div>
            </div>
            <div className="row">
              <div className="col mtop22">
                <InputFieldWidget
                  title="No Handphone (WA aktif)"
                  inputType="tel"
                  isEnabled={true}
                  placeholder={"Ketik di sini"}
                  errorMessage={errorMessages.phoneNumber || ""}
                  action={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
                  variant="outlined"
                  isRequired={true}
                  name="phone"
                  autoComplete="on"
                  value={formData.phoneNumber}
                />
              </div>
            </div>
            <div className="row">
              <div className="col mtop22">
                <label className="form-label" style={{ color: "black", fontWeight: "500" }}>
                  Alamat Gudang<span style={{ color: "red" }}>*</span>
                </label>
                <div className="MessageBoxWarning">
                  <img alt="info" src={ic_information} style={{ height: "19px" }} />
                  <p>Alamat gudang utama sebagai titik pengiriman wholesale order tidak bisa diubah setelah terdaftar</p>
                </div>
                <SizedDiv height={8} />
                <TextAreaWidget
                  // title="Alamat Gudang"
                  placeholder={"Alamat gudang"}
                  errorMessage={""}
                  action={(e) => setFormData({ ...formData, address: e.target.value })}
                  variant="outlined"
                  isRequired={true}
                  name="address"
                  autoComplete="on"
                  lines={2}
                  value={formData.address}
                  id="addressInput"
                ></TextAreaWidget>
                <SizedDiv height={8} />
                <InputFieldWidget
                  inputType="text"
                  isEnabled={true}
                  placeholder={"Provinsi"}
                  errorMessage={""}
                  action={(e) => setFormData({ ...formData, province: e.target.value })}
                  variant="outlined"
                  isRequired={true}
                  name="province"
                  autoComplete="on"
                  value={formData.province}
                />
                <SizedDiv height={8} />
                <InputFieldWidget
                  inputType="text"
                  isEnabled={true}
                  placeholder={"Kota"}
                  errorMessage={""}
                  action={(e) => setFormData({ ...formData, city: e.target.value })}
                  variant="outlined"
                  isRequired={true}
                  name="city"
                  autoComplete="on"
                  value={formData.city}
                />
                <SizedDiv height={8} />
                <InputFieldWidget
                  inputType="text"
                  isEnabled={true}
                  placeholder={"Kode pos"}
                  errorMessage={""}
                  action={(e) => setFormData({ ...formData, postalCode: e.target.value })}
                  variant="outlined"
                  isRequired={true}
                  name="zip"
                  autoComplete="on"
                  value={formData.postalCode}
                />
                <SizedDiv height={8} />
                {formData.pinpoint && (
                  <>
                    <div className="PinpointCard">
                      <img alt="location" src={ic_pinpoint_grey} />
                      <div>
                        <b>{formData.pinpoint.title}</b>
                        <p>{formData.pinpoint.addressLabel}</p>
                      </div>
                    </div>
                    <SizedDiv height={8} />
                  </>
                )}
                <Link
                  onClick={() => onSetAddressPinpoint()}
                  to={"/register/pinpoint"}
                  className="ResellerPinpointButton"
                >
                  <img alt="pinpoint" src={ic_pinpoint_black} />
                  {isPinpointSet ? "Change address pinpoint" : "Set address pinpoint"}
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col mtop22">
                <label className="form-label" style={{ color: "black", fontWeight: "500" }}>
                  Subscription (Langganan)<span style={{ color: "red" }}>*</span>
                </label>
                <fieldset id="group_subscription" className="GridContainerGap8">
                  {viewModel.subscriptionPlans.map((c, i) => (
                    <div
                      className="BoxOptionItem"
                      onClick={() => {
                        if (c.value !== formData.subscription) {
                          $("#subscription_" + i).trigger("click");
                          setFormData({ ...formData, subscription: c.value });
                        } else {
                          $("#subscription_" + i).prop("checked", false);
                          setFormData({ ...formData, subscription: -1 });
                        }
                      }}
                    >
                      <input id={"subscription_" + i} type="radio" value={c.value} name="group_subscription"></input>
                      <div>
                        <p>{c.name}</p>
                        <p>
                          <s>{c.price.original}</s> <b>{c.price.netPrice}</b>
                        </p>
                      </div>
                    </div>
                  ))}
                </fieldset>
              </div>
            </div>
            <div className="row">
              <div className="col mtop22">
                <InputFieldWidget
                  title="Umur"
                  inputType="number"
                  isEnabled={true}
                  placeholder={"Ketik di sini"}
                  errorMessage={errorMessages.age || ""}
                  action={(e) => setFormData({ ...formData, age: parseInt(e.target.value) })}
                  variant="outlined"
                  isRequired={true}
                  name="age"
                  autoComplete="on"
                  value={formData.age?.toString()}
                />
              </div>
            </div>
            <div className="row">
              <div className="col mtop22">
                <label className="form-label" style={{ color: "black", fontWeight: "500" }}>
                  Gender<span style={{ color: "red" }}>*</span>
                </label>
                <fieldset id="group_gender" className="GridContainerGap8">
                  <div
                    className="BoxOptionItem"
                    style={{ padding: "14.5px 16px" }}
                    onClick={() => {
                      if (formData.gender !== 0) {
                        $("#gender_0").trigger("click");
                        setFormData({ ...formData, gender: 0 });
                      } else {
                        $("#gender_0").prop("checked", false);
                        setFormData({ ...formData, gender: -1 });
                      }
                    }}
                  >
                    <input type="radio" value={0} name="group_gender" id="gender_0"></input>
                    <p>Perempuan</p>
                  </div>
                  <div
                    className="BoxOptionItem"
                    style={{ padding: "14.5px 16px" }}
                    onClick={() => {
                      if (formData.gender !== 1) {
                        $("#gender_1").trigger("click");
                        setFormData({ ...formData, gender: 1 });
                      } else {
                        $("#gender_1").prop("checked", false);
                        setFormData({ ...formData, gender: -1 });
                      }
                    }}
                  >
                    <input type="radio" value={1} name="group_gender" id="gender_1"></input>
                    <p>Laki-laki</p>
                  </div>
                </fieldset>
              </div>
            </div>
            <div className="row">
              <div className="col mtop22">
                <InputFieldWidget
                  title="Pekerjaan"
                  inputType="text"
                  isEnabled={true}
                  placeholder={"Ketik di sini"}
                  errorMessage={""}
                  action={(e) => setFormData({ ...formData, occupation: e.target.value })}
                  variant="outlined"
                  isRequired={true}
                  name="occupation"
                  autoComplete="on"
                  value={formData.occupation}
                />
              </div>
            </div>
          </form>
        </div>
        <SizedDiv height={124} />
        <div className="FixedBottomButtonContainer">
          <ButtonWidget
            backgroundColor={isSubmittable ? "#4A00E8" : ""}
            action={() => handleSubmitForm()}
            isCurvy={true}
            isEnabled={isSubmittable}
          >
            {isLoading ? <LoadingWidget /> : "Kirim"}
          </ButtonWidget>
        </div>
      </Scaffold>
    </>
  );
};

export default ResellerRegisterPage;
