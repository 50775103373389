import React, { ReactNode, useEffect, useState } from "react";
import BottomSheet from "./DraggableBottomSheetLibrary";
import "./DraggableBottomSheet.css";

type DraggableBottomSheetProps = {
    isShown?: boolean;
    onClosed?: () => void;
    children?: ReactNode;
    animateOut?: boolean;
};

const DraggableBottomSheet = (props: DraggableBottomSheetProps) => {
    const [isOpen, setIsOpen] = useState(props.isShown || false);

    const close = () => setIsOpen(false);

    useEffect(() => {
        if (!isOpen) {
            props.onClosed?.();
        }
        // console.log("isOpen",isOpen)
    }, [isOpen, props.animateOut]);

    return (
        <>
            {/* <div className={"DraggableBottomSheetBackground"}></div> */}
            <BottomSheet
                styles={{
                    bottomSheet: {
                        width: "inherit",
                        zIndex: "20",
                        animation: props.animateOut
                            ? "animateDraggableBottomSheetOut 0.5s"
                            : "animateDraggableBottomSheetIn 0.5s",
                    },
                    // backdrop: { opacity: "0" },
                    dragIndicator: {
                        wrap: { paddingTop: "10px", paddingBottom: "12px" },
                        indicator: { height: "4px", width: "60px", borderRadius: "2px", backgroundColor: "#E4E4E4" },
                    },
                    window: {
                        wrap: { borderRadius: "24px 24px 0 0", maxWidth: "600px" },
                        content: { paddingBottom: "6px" },
                    },
                }}
                isOpen={isOpen}
                close={close}
            >
                {props.children}
            </BottomSheet>
        </>
    );
};

export default DraggableBottomSheet;
