import React from "react";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { ImageWidget } from "../../../CommonComponents/UI/ImageWidget/ImageWidget";
import { IndexIndicator } from "../../../CommonComponents/UI/IndexIndicator/IndexIndicatorWidget";
import { standarizeThefepiLink } from "../../../CommonComponents/Utils";
import StoreSectionFeedModel from "../Models/StoreSectionFeedModel";

import "./StoreSectionFeed.css";
import { IndexIndicatorBullet } from "../../../CommonComponents/UI/IndexIndicator/IndexIndicatorBullet";

export class StoreBannerSectionWidget extends React.Component<{
    screenWidth: number,
    model: StoreSectionFeedModel,
    onBannerIndexChanged: (index: number) => void,
    showingBannerIndex: number
}> {
    render() {
        const banners = this.props.model.contents.banners;
        
        return <div id="StoreBannerSectionContainer" style={{height:`${this.props.screenWidth * 2 / 3}px`}}>
            <Swiper 
                id="StoreBannerSectionSwiper"
                slidesPerView={1}
                onSlideChangeTransitionStart={(swiper) => this.props.onBannerIndexChanged(swiper.activeIndex)}
                autoplay={{
                    delay: 3500,
                    disableOnInteraction: false
                }}
                modules={[Autoplay]}
            >
                {
                    banners.map((model, index) =>
                        <SwiperSlide key={index} style={{height:`${this.props.screenWidth * 2 / 3}px`}}>
                            <button 
                                style={{height:"inherit", width:"inherit"}} 
                                onClick={() => {
                                    if (model.deeplink) window.location.href = standarizeThefepiLink(model.deeplink);
                                }}
                            >
                                <ImageWidget imageUrl={model.imageUrl} />
                            </button>
                        </SwiperSlide>
                    )
                }
            </Swiper>
            <div id="StoreBannerSectionIndexIndicatorContainer">
                <IndexIndicatorBullet 
                    showingIndex={this.props.showingBannerIndex + 1} 
                    totalOfIndices={banners.length ?? 0}
                />
            </div>
        </div>;
    }
}