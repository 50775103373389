import React from "react";
import { SwiperSlide, Swiper } from "swiper/react";
import ProductSummaryItemModel from "../../../../CommonComponents/Models/ProductSummaryItemModel";
import ProductSummaryItemCellWidget from "../../../../CommonComponents/UI/Cells/ProductSummaryItemCellWidget";
import { standarizeThefepiLink } from "../../../../CommonComponents/Utils";
import ProgressBar from "../StoreBannersProductList/ProgressBar";
import "./StoreNewArrivalSectionWidget.css";

export type StoreNewArrivalSectionWidgetProps = {
  products?: ProductSummaryItemModel[];
  title?: string;
  subtitle?: string;
  deeplink?: string;
  screenWidth?: number;
};

export default class StoreNewArrivalSectionWidget extends React.Component<
  StoreNewArrivalSectionWidgetProps,
  { activeIndex: number }
> {
  constructor(props: StoreNewArrivalSectionWidgetProps) {
    super(props);
    this.state = {
      activeIndex: 1,
    };
  }

  render(): React.ReactNode {
    const { products = [], screenWidth = 0 } = this.props;

    const formattedProducts = [];

    for (let i = 0; i < products.length / 6; i++) {
      formattedProducts.push(products.slice(i * 6, i * 6 + 6));
    }

    return (
      <div id="StoreNewArrivalSectionWidgetContainer">
        <div id="StoreNewArrivalSectionWidgetHeader">
          <div>
            <div id="StoreNewArrivalSectionWidgetTitle">{this.props.title}</div>
            <div id="StoreNewArrivalSectionWidgetSubtitle">
              {this.props.subtitle}
            </div>
          </div>
          {this.props.deeplink && (
            <a href={standarizeThefepiLink(this.props.deeplink)} id="StoreNewArrivalSectionWidgetLink">
              See all
            </a>
          )}
        </div>

        <Swiper
          style={{
            padding: "0px 16px",
          }}
          onSlideChange={(x) => {
            this.setState({
              activeIndex: x.activeIndex + 1,
            });
          }}
          spaceBetween={16}
          slidesPerView={1}
          // centeredSlides={true}
          // centeredSlidesBounds={true}
        >
          {formattedProducts.map((products) => (
            <SwiperSlide>
              <div id="StoreNewArrivalSectionWidgetProductsGrid">
                {products.map((p) => (
                  <ProductSummaryItemCellWidget
                    cellWidth={screenWidth / 3 - 24}
                    badgeUrls={p.badgeUrls}
                    model={{
                      id: p.id,
                      imageUrl: p.imageUrl,
                      price: p.price,
                      title: p.title || "",
                      // description: p.description
                      brand: p.brand,
                    }}
                  />
                ))}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div style={{ marginTop: 12, marginLeft: 16, marginRight: 16 }}>
          <ProgressBar
            progress={
              this.state.activeIndex / (formattedProducts.length || 1)
            }
          />
        </div>
        <div id="StoreBannersProductListWidgetPageIndicator">
          {`${this.state.activeIndex}/${formattedProducts.length}`}
        </div>
      </div>
    );
  }
}
