import Scaffold, {
  ShowSnackbarModel,
} from "../../CommonComponents/UI/Scaffold/Scaffold";
import ChannelChatPopup from "../../CommonComponents/UI/ChannelChatPopup/ChannelChatPopup";
import ErrorPopupMessageWidget from "../../CommonComponents/UI/PopupWidget/ErrorPopupMessage/ErrorPopupMessageWidget";
import { useParams } from "react-router-dom";
import ProductDetailViewModel from "./ProductDetailViewModel";
import ProductDetailImageWidget from "./Widgets/ProductDetailImageWidget";
import ProductDetailMainInformationWidget from "./Widgets/ProductDetailMainInformationWidget";
import ProductDetailCreatorInformationWidget from "./Widgets/ProductDetailCreatorInformationWidget";
import ProductDetailOptionWidget from "./Widgets/ProductDetailOptionWidget";
import ProductDetailFaqWidget from "./Widgets/ProductDetailFaqWidget";
import ProductDetailInformationWidget from "./Widgets/ProductDetailInformationWidget";
import ProductDetailDescriptionWidget from "./Widgets/ProductDetailDescriptionWidget";
import ButtonWidget, {
  ButtonWidgetType,
} from "../../CommonComponents/UI/ButtonWidget/ButtonWidget";

import "./ProductDetail.css";
import ic_thefepi_f from "../../Assets/Images/Logo/ic_fepi_f.svg";
import ic_back from "../../Assets/Images/ic_chevron_left.svg";
import ic_cart from "../../Assets/Images/ic_cart_dark.svg";
import ic_cart_black from "../../Assets/Images/ic_cart_black.svg";
import ic_share from "../../Assets/Images/ic_share_box.svg";
import ic_chat from "../../Assets/Images/ic_chat.svg"

import {
  WithCreatorLinkProps,
  convertToRupiah,
  getLocationQueries,
  getResellerPhoneNumber,
  getScreenSizeWithMaxWidth,
  isResellerDomain,
  withSellerId,
} from "../../CommonComponents/Utils";
import ViewController from "../../CommonComponents/Base/ViewController";
import PopUpWidgetModel from "../../CommonComponents/UI/PopUp/Models/PopUpWidgetModel";
import ProductDetailVariantPicker from "./Widgets/ProductDetailVariantPicker/ProductDetailVariantPicker";
import ProductDetailVariantPickerSheet from "./Widgets/ProductDetailVariantPicker/ProductDetailVariantPickerSheet";
import HypeButton from "./Widgets/HypeButton";
import ProductSizeTableWidget from "../../CommonComponents/UI/ProductSizeTable/ProductSizeTableWidget";
import { ImageWidget } from "../../CommonComponents/UI/ImageWidget/ImageWidget";
import BackButton from "../../CommonComponents/UI/BackButton/BackButton";
import ProductDetailEligibleCouponsWidget from "./Widgets/ProductDetailEligibleCouponsWidget";
import CouponSheet from "../Coupon/Widgets/CouponSheet";
import mixpanel from "mixpanel-browser";

function ProductDetailScreen(props: any) {
  let { productId } = useParams();
  const queries = getLocationQueries();
  const sellerId = queries["creator"];
  localStorage.removeItem("seller_id")
  if (sellerId) {
    localStorage.seller_id = sellerId;
  }
  return <ProductDetailPage  {...props} productId={productId ?? ""} />;
}


class ProductDetailPage extends ViewController<
  { productId: string } & WithCreatorLinkProps,
  ProductDetailViewModel
> {
  constructor(props: { productId: string } & WithCreatorLinkProps) {
    super(props, new ProductDetailViewModel(props.productId, props.creatorLink));
    this.viewModel.loadPreloadingProps();
    window.addEventListener("scroll", () => this.handleScrolling());

    //MIXPANEL TRACKING: semo_page_view
    if (isResellerDomain()) {
      mixpanel.track("semo_page_view", {
        page_name: "ProductDetail",
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", () => this.handleScrolling());
  }

  handleSubmit = (event: any) => {
    this.viewModel.onAddToCart();
  };

  closeModal = () => {
    document.body.classList.remove("modal-open");
    this.viewModel.onCloseModal();
  };

  handleCouponClick = (id: string) => {
    console.log("load coupon",id)
    this.viewModel.handleCouponClick(id)
    // this.viewModel.toggleSheet()

  }

  render() {
    const productDetail = this.viewModel.productDetail;
    const attributes = this.viewModel.attributes;
    const DeeplinkHeight = 46;
    const fixedHeight = 72;
    return (
      <Scaffold
        isShowLoading={this.viewModel.isLoading}
        showSnackbar={this.viewModel.snackbarMessage}
        showErrorModel={this.viewModel.errorModel}
        shareUrlModel={this.viewModel.shareUrlModel}
      >
        <div id="PRODUCT_DETAIL" onScroll={() => this.handleScrolling()}>
          <div
            id="ProductDetailImageCoverContainer"
            style={{
              height: `${(getScreenSizeWithMaxWidth().width * 4) / 3}px`,
            }}
          >
            <ProductDetailImageWidget
              screenWidth={this.state.width}
              productDetail={productDetail}
            />
          </div>
          {!isResellerDomain() &&  productDetail && (
            <div id="ProductDetailCreatorSection">
              <ProductDetailCreatorInformationWidget
                productDetail={productDetail}
              />
            </div>
          )}
          <div id="ProductDetailTopInfoContainer">
            <ProductDetailMainInformationWidget
              mainInformation={productDetail}
              onShareLink={()=>this.viewModel.onShareLink?.()}
            />
            {!isResellerDomain() && <ProductDetailEligibleCouponsWidget 
              eligibleCoupons={productDetail?.eligibleCoupons} 
              onEligibleCouponClick={this.handleCouponClick} 
            />}
            <CouponSheet 
              isVisible = {this.viewModel.shouldShowCouponSheet}
              title= {this.viewModel.couponData?.title}
              description= {this.viewModel.couponData?.description}
              tnc= {this.viewModel.couponData?.tnc}
              hideSelectButton = {true}
              onBackClick={()=>this.viewModel.closeCouponSheet()}
              onUnderlayClick={()=>this.viewModel.closeCouponSheet()}
              onCloseClick={()=>this.viewModel.closeCouponSheet()}
            />
            <ProductDetailVariantPicker
              oneLiner
              onAttributeItemClick={(x, stock, isPreOrder) => {
                if (this.viewModel.primaryAttribute?.type && x) {
                  this.viewModel.toggleSheet();
                  this.viewModel.selectAttributeItem(
                    this.viewModel.primaryAttribute?.type,
                    x,
                    stock || 0,
                    isPreOrder || false
                  );
                  this.viewModel.loadAttributes(
                    this.viewModel.getWhatKeyShouldWeLoadAfter(
                      this.viewModel.primaryAttribute.type
                    )
                  );
                }
              }}
              onSeeAllClick={() => {
                // console.log('xxxxxxxxxx')
                const a = this.viewModel.getFirstSelectableAttributeItem();
                // console.log({a})
                if (!this.viewModel.primaryAttribute || !a) return;
                this.viewModel.selectAttributeItem(
                  this.viewModel.primaryAttribute?.type,
                  a?.attribute,
                  a?.stock || 0,
                  a.isPreOrder || false
                );
                this.viewModel.loadAttributes(
                  this.viewModel.getWhatKeyShouldWeLoadAfter(
                    this.viewModel.primaryAttribute.type
                  )
                );
                this.viewModel.toggleSheet();
              }}
              type={this.viewModel.primaryAttribute?.type}
              selectedMapAttributes={this.viewModel.selectedMapAttributes}
              title={this.viewModel.attributeOptions.join(", ")}
              attribute={this.viewModel.primaryAttribute}
              shouldShowSeeAll
            />
            <ProductDetailVariantPickerSheet
              attributeOptions={this.viewModel.attributeOptions}
              onCloseClick={() => this.viewModel.toggleSheet()}
              onUnderlayClick={() => {
                this.viewModel.toggleSheet();
              }}
              attributes={this.viewModel.attributes}
              description={this.viewModel.productName}
              imageUrl={this.viewModel.productDetail?.productImageUrls?.[0]}
              selectedMapAttributes={this.viewModel.selectedMapAttributes}
              title={this.viewModel.productDetail?.brand.name}
              isVisible={this.viewModel.shouldShowVariantPickerSheet}
              qty={this.viewModel.desiredQty}
              shouldDisableQtyButton={
                !(
                  this.viewModel.attributeOptions.length ===
                  Object.keys(this.viewModel.selectedMapAttributes).length
                )
              }
              onAdd={() => {
                const attr =
                  this.viewModel.selectedMapAttributes[
                    this.viewModel.getLastKey()
                  ];
                // console.log({attr})
                console.log({ attr });
                if (this.viewModel.desiredQty < attr.stock || attr.isPreOrder) {
                  this.viewModel.addDesiredQty();
                }
              }}
              onSub={() => {
                if (this.viewModel.desiredQty > 1) {
                  this.viewModel.subDesiredQty();
                }
              }}
              onAttributeItemClick={(type, attr, stock, isPreOrder) => {
                // console.log({type, attr, stock, isPreOrder})
                this.viewModel.emit(() => {
                  this.viewModel.desiredQty = 1;
                });

                const prevKey = this.viewModel.getPrevKey(type);

                if (prevKey) {
                  if (!this.viewModel.selectedMapAttributes[prevKey]) {
                    this.viewModel.emit(() => {
                      this.viewModel.snackbarMessage = new ShowSnackbarModel({
                        message: `Select ${prevKey.toLowerCase()} first.`,
                        inset: {
                          bottom: 64,
                        },
                        type: "normal",
                      });
                    });
                    return;
                  }
                }

                if (attr) {
                  this.viewModel.selectAttributeItem(
                    type,
                    attr,
                    stock || 0,
                    isPreOrder || false
                  );
                  this.viewModel.loadAttributes(
                    this.viewModel.getWhatKeyShouldWeLoadAfter(type)
                  );
                }
              }}
              onAddToBagClick={() => {
                this.viewModel.onAddToCart();
              }}
              shouldDisableAddToBagButton={
                Object.keys(this.viewModel.selectedMapAttributes).length <
                  this.viewModel.attributeOptions.length ||
                this.viewModel.desiredQty <= 0
              }
            />
          </div>
          {isResellerDomain() && this.viewModel.productDetail?.sellerProfile &&
          <div style={{marginLeft:24, marginTop: 16, marginRight: 24, display: 'flex', alignItems: 'center'}}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.35407 2C4.53626 2 3.80084 2.4979 3.49711 3.25722L2.14305 6.64238C2.04855 6.87862 2 7.13073 2 7.38516V8C2 9.10457 2.89543 10 4 10C5.10457 10 6 9.10457 6 8C6 9.10457 6.89543 10 8 10C9.10457 10 10 9.10457 10 8C10 9.10457 10.8954 10 12 10C13.1046 10 14 9.10457 14 8C14 9.10457 14.8954 10 16 10C17.1046 10 18 9.10457 18 8V7.38516C18 7.13073 17.9514 6.87862 17.857 6.64238L16.5029 3.25722C16.1992 2.4979 15.4637 2 14.6459 2H5.35407Z" fill="#626262"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M16 8C16.3701 8 16.6933 8.2011 16.8662 8.5C16.6933 8.7989 16.3701 9 16 9C15.4477 9 15 8.55228 15 8H16ZM12 9C11.4477 9 11 8.55228 11 8H13C13 8.55228 12.5523 9 12 9ZM8 9C7.44772 9 7 8.55228 7 8H9C9 8.55228 8.55228 9 8 9ZM4 8H5C5 8.55228 4.55228 9 4 9C3.62986 9 3.30669 8.7989 3.13378 8.5C3.30669 8.2011 3.62986 8 4 8ZM16 11C16.3506 11 16.6872 10.9398 17 10.8293V17C17 17.5523 16.5523 18 16 18H12.5V16C12.5 14.6193 11.3807 13.5 10 13.5C8.61929 13.5 7.5 14.6193 7.5 16V18H4C3.44772 18 3 17.5523 3 17V10.8293C3.31278 10.9398 3.64936 11 4 11C4.76836 11 5.46925 10.7111 6 10.2361C6.53076 10.7111 7.23164 11 8 11C8.76836 11 9.46924 10.7111 10 10.2361C10.5308 10.7111 11.2316 11 12 11C12.7684 11 13.4692 10.7111 14 10.2361C14.5308 10.7111 15.2316 11 16 11ZM11.5 18H8.5V16C8.5 15.1716 9.17157 14.5 10 14.5C10.8284 14.5 11.5 15.1716 11.5 16V18Z" fill="#626262"/>
            </svg>
            <div style={{width: 24,marginLeft: 12, height: 24, borderRadius: 999, overflow: 'hidden'}}>
              <img style={{width: '100%', height: '100%', objectFit: 'cover'}} src={this.viewModel.productDetail?.sellerProfile?.imageUrl}/>
            </div>
            <div style={{marginLeft: 4, fontWeight: 500, fontSize: 14}}>
              {this.viewModel.productDetail?.sellerProfile?.name}
            </div>

          </div>}

          <ProductDetailDescriptionWidget
            reviewCount={productDetail?.review.reviewSummary.totalReviews}
            productId={this.props.productId}
            productDetail={productDetail}
            tabId={this.viewModel.tabId}
            onTabClicked={(tabId) => this.viewModel.onchangeTab(tabId)}
          />
          <div className="accordion" id="size-chart-section">
            <div className="accordion-item" style={{borderColor: 'transparent'}}  >
              <div className="accordion-header" style={{height: 36, alignItems: 'center', display: 'flex', paddingLeft: 24, paddingRight: 24}}>
                <button
                className="size-chart-button accordion-button collapsed"
                style={{
                  fontWeight: '600',
                  fontSize: '12px',
                  boxShadow: 'none',
                  lineHeight: '18px',
                  display: 'flex',
                  alignItems: 'center',

                  color: '#000000'
                  ,
                  borderColor: 'transparent'

                }}
                  data-bs-toggle="collapse"
                  data-bs-target={`#size-chart-content`}
                >Size Details</button>
              </div>
              <div
                id={`size-chart-content`}
                className={`accordion-collapse collapse`}
                style={{ padding: 24 }}
                data-bs-parent="#size-chart-section"
              >
                <ProductSizeTableWidget
                  headers={this.viewModel.sizes.map((s) => s.label)}
                  rows={this.viewModel.sizes.reduce((acc, { items }) => {
                    const columnCount = this.viewModel.sizes.length;
                    acc.push(
                      Array.from({ length: columnCount }).map(
                        (_, index) => items[index]
                      )
                    );
                    return acc;
                  }, [] as Array<string[]>)}
                />
              </div>
            </div>
          </div>
          <div style={{ margin: "24px 16px 0px 16px" }}>
            <ProductDetailInformationWidget />
            <ProductDetailFaqWidget />
          </div>

          <div
            style={{
              height: "4px",
              width: "inherit",
              backgroundColor: "var(--whiteGrey)",
            }}
          />
          <button
            onClick={() =>
              (window.location.href =
                `${isResellerDomain() ?  `/shop/${this.props.creatorLink}` : ''}/SizeChart/` + productDetail?.brand.id + "/2")
            }
            style={{
              margin: "0px 16px",
              display: "flex",
              width: "calc(100% - 32px)",
              height: "42px",
              alignItems: "center",
            }}
          >
            <p className="font14" style={{ flexGrow: "1", textAlign: "left" }}>
              <b>Size Chart</b>
            </p>
            <p className="font16" style={{ flexShrink: "0" }}>
              {">"}
            </p>
          </button>

          {/* <div
            style={{
              height: "4px",
              width: "inherit",
              backgroundColor: "var(--whiteGrey)",
            }}
          /> */}
          {/* <div
            style={{
              margin: "0px 16px",
              // display:"flex",
              width: "calc(100% - 32px)",
              // alignItems:"center"
              paddingTop: 12,
              paddingBottom: 12,
            }}
          >
            <p className="font14" style={{ flexGrow: "1", textAlign: "left", marginBottom: 12 }}>
              <b>Recommendations</b>
            </p>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
                gap: 3
              }}
            >
              {this.viewModel.recommendations.map((r) => {
                return (
                  <div style={{ width: "100%", position: 'relative' }}>
                    {r.isSoldOut && <div style={{padding: '4px 8px 4px 8px', background: '#F77462', position: 'absolute', left:0, top: 0, fontWeight: 700, fontSize: 10, color: '#fff'}}>SOLD OUT</div>}
                   
                    <img
                      style={{
                        width: "100%",
                        height: (this.state.width / 3) * 1.32,
                      }}
                      src={r.imageUrl}
                    />
                    <p id="RecTitle">{r.title}</p>
                    <p id="RecPrice">{convertToRupiah(r.price.netPrice)}</p>
                    <p>
                      <span id="RecRealPrice">{convertToRupiah(r.price.realPrice)}</span>
                      <span id="RecDiscountPercentage">{r.price.discount}%</span>
                    </p>
                  </div>
                );
              })}
            </div>
          </div> */}
          <div
            style={{
              height: "4px",
              width: "inherit",
              backgroundColor: "var(--whiteGrey)",
            }}
          />
          <div
            id="ProductTopGradient"
            className="row"
            style={{
              bottom: `${DeeplinkHeight + fixedHeight - 75}px`,
              width: "inherit",
            }}
          />
          <div id="ProductDetailBottomContainer">
            {!isResellerDomain() ?
            <div className="col-2 text-center ptop15">
               <img
                data-bs-toggle="modal"
                data-bs-target="#ChatModal"
                width="20"
                className="img-fluid"
                src={global.ImgUrl + "shopable/ic-detail-chat@2x.png"}
                alt="gambar1"
              ></img>
            </div>
            : <img alt="chat" src={ic_chat} style={{height: "36px", marginLeft: "8px", marginTop: "8px", marginRight: "14px"}}
                onClick={()=>window.open(
                  `https://wa.me/${getResellerPhoneNumber(this.props.creatorLink)}?text=Halo ${localStorage.getItem("resellerName") || this.props.creatorLink}, ` 
                  + `mau bertanya tentang produk berikut ini:%0A%0A`
                  + `${this.viewModel.productDetail?.brand.name}%0A`
                  + `${this.viewModel.productDetail?.productName}%0A`
                  + `${window.location.href}`
                  , "_blank"
                )}
              />
              }
            <div style={{ flexGrow: 1 }}>
              <HypeButton
                isBlack={isResellerDomain()}
                isDisabled={this.viewModel.productDetail?.isSoldOut}
                label={isResellerDomain() ? "Add to Cart" : "Add to Bag"}
                renderLeftIcon={() => {
                  return (
                    <div style={{ marginRight: 8 }}>
                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <line
                          x1="13"
                          y1="4.5"
                          x2="13"
                          y2="19.5"
                          stroke="white"
                          stroke-linecap="round"
                        />
                        <line
                          x1="5"
                          y1="11.5"
                          x2="20"
                          y2="11.5"
                          stroke="white"
                          stroke-linecap="round"
                        />
                      </svg>
                    </div>
                  );
                }}
                onClick={() => {
                  const a = this.viewModel.getFirstSelectableAttributeItem();
                  if (!this.viewModel.primaryAttribute || !a) return;
                  this.viewModel.selectAttributeItem(
                    this.viewModel.primaryAttribute?.type,
                    a?.attribute,
                    a?.stock || 0,
                    a.isPreOrder || false
                  );
                  this.viewModel.loadAttributes(
                    this.viewModel.getWhatKeyShouldWeLoadAfter(
                      this.viewModel.primaryAttribute.type
                    )
                  );

                  this.viewModel.toggleSheet();
                }}
              />
            </div>
          </div>
          <div id="SpaceNavigationBackground" style={{ opacity: "0" }} />
          <div id="SpaceHomeNavContainer">
          {isResellerDomain() ? (
              <BackButton id="SpaceHomeButton">
                <img src={isResellerDomain() ? ic_back : ic_thefepi_f} alt="" />
              </BackButton>
            ) : (
              <button
                id="SpaceHomeButton"
                onClick={() =>
                  (window.location.href = isResellerDomain()
                    ? "/" + this.props.creatorLink
                    : "/")
                }
              >
                <img src={isResellerDomain() ? ic_back : ic_thefepi_f} alt="" />
              </button>
            )}
            <div id="ProductNameNavbar" style={{ opacity: "0" }}>
              <span>
                {this.viewModel.productName.length > 19
                  ? productDetail?.productName.substring(0, 19) + "..."
                  : productDetail?.productName}
              </span>
            </div>
            <button onClick={()=>this.viewModel.onShareLink?.()} id="ProductShareButton" 
              style={{
                opacity: "0",
                top: "4px",
                position: "absolute",
                right: "62px",
              }}
            >
                <img src={ic_share} alt="share"></img>
            </button>
            <button
              id="SpaceCartButton"
              style={{ top: 0 }}
              onClick={() => {
                window.location.href = isResellerDomain() ? `/shop/${this.props.creatorLink}/Cart` : "/Cart";
              }}
            >
              <img src={isResellerDomain() ? ic_cart_black : ic_cart} alt="" />
              {(this.viewModel.numberInCart ?? 0) > 0 && (
                <div>{this.viewModel.numberInCart}</div>
              )}
            </button>
          </div>
        </div>

        <ErrorPopupMessageWidget
          showModal={this.viewModel.showModal}
          Message={this.viewModel.Message}
          closeModal={() => {
            this.closeModal();
          }}
        />

        {isResellerDomain() ?
          <ChannelChatPopup 
            resellerNumber={getResellerPhoneNumber(this.props.creatorLink)}
            message={`Halo, ${this.props.creatorLink}`}
          /> : <ChannelChatPopup />
        }
      </Scaffold>
    );
  }

  private handleScrolling() {
    const containerElement = document.querySelector("#PRODUCT_DETAIL");
    const containerRect = containerElement?.getBoundingClientRect();
    let transparencyValue =
      (containerRect?.y ?? 0) / -((this.state.width * 3) / 2 + 16);
    transparencyValue = transparencyValue > 1 ? 1 : transparencyValue;

    const navBar = document.querySelector("#SpaceNavigationBackground");
    const titleInNavBar = document.querySelector("#ProductNameNavbar");
    const shareButton = document.querySelector("#ProductShareButton");
    if (navBar && titleInNavBar) {
      
    (navBar as any).style.opacity = transparencyValue;
    (titleInNavBar as any).style.opacity = transparencyValue;
    (shareButton as any).style.opacity = transparencyValue;
    }
  }
}


export default withSellerId(ProductDetailScreen);