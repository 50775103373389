import React from "react";
import "./TrackingDetails.css";
import SizedDiv from "../../../CommonComponents/UI/SizedDiv";
import { ImageWidget } from "../../../CommonComponents/UI/ImageWidget/ImageWidget";

export type TrackingDetailsProps = {
  orderId?: string;
  orderDate?: string;
  trackingCount?: number;
  trackings?: {
    courierDetail?: {
      imageUrl?: string;
      label?: string;
      receipt?: string;
    };
    products?: {
      imageUrl?: string;
      brand?: string;
      name?: string;
      attributes?: string;
    }[];
    trackingDetailList?: {
      timeLabel?: string;
      description?: string;
    }[];
  }[];
};

function TrackingDetails(props: TrackingDetailsProps) {
  const { orderDate, orderId, trackingCount, trackings } = props;

  return (
    <div>
      <p className="TrackingDetailsOrderTitle">Order #{orderId}</p>
      <div className="TrackingDetailsOrderDateContainer">
        <p className="TrackingDetailsOrderDateKey">Order Date: </p>
        <p className="TrackingDetailsOrderDateLabel">{orderDate}</p>
      </div>
      <div className="TrackingDetailsShipmentCounter">
        {trackingCount} shipment(s)
      </div>
      <SizedDiv height={32} />
      {trackings?.map((tracking) => (
        <React.Fragment key={tracking.courierDetail?.receipt}>
          <div className="TrackingDetailsChunkContainer">
            <div className="TrackingDetailsChunkHeader">
              <div className="TrackingDetailsChunkHeaderCourierContainer">
                <div className="TrackingDetailsChunkHeaderCourierImage">
                  <ImageWidget
                    imageUrl={tracking.courierDetail?.imageUrl}
                    objectFit="cover"
                  />
                </div>
                <div className="TrackingDetailsChunkHeaderCourierLabel">
                  {tracking.courierDetail?.label}
                </div>
              </div>
              <div className="TrackingDetailsChunkHeaderCourierReceipt">
                {tracking.courierDetail?.receipt}
              </div>
            </div>
            <div className="TrackingDetailsChunkProductsContainer">
              {tracking.products?.map((product) => (
                <React.Fragment key={product.name}>
                  <div className="TrackingDetailsChunkProduct">
                    <div className="TrackingDetailsChunkProductImage">
                      <ImageWidget
                        imageUrl={product.imageUrl}
                        objectFit="cover"
                      />
                    </div>
                    <div className="TrackingDetailsChunkProductContainer">
                      <div className="TrackingDetailsProductNameContainer">
                        <p className="TrackingDetailsChunkProductBrand">
                          {product.brand}
                        </p>
                        <p
                          className="TrackingDetailsChunkProductBrand"
                          style={{ marginLeft: 4, marginRight: 4 }}
                        >
                          -
                        </p>
                        <p className="TrackingDetailsChunkProductName">
                          {product.name}
                        </p>
                      </div>
                      <p className="TrackingDetailsChunkProductVariant">
                        {product.attributes}
                      </p>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>

          <div className="TrackingDetailsChunkTimelineContainer">
            {tracking.trackingDetailList?.map((detail, index) => (
              <div
                key={detail.description}
                className="TrackingDetailsChunkTimeline"
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className="TrackingDetailsChunkTimelineIndicator">
                    {index === 0 ? (
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ marginTop: 4, marginBottom: 4 }}
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10ZM14.0303 8.53033C14.3232 8.23744 14.3232 7.76256 14.0303 7.46967C13.7374 7.17678 13.2626 7.17678 12.9697 7.46967L9 11.4393L7.03033 9.46967C6.73744 9.17678 6.26256 9.17678 5.96967 9.46967C5.67678 9.76256 5.67678 10.2374 5.96967 10.5303L8.46967 13.0303C8.61032 13.171 8.80109 13.25 9 13.25C9.19891 13.25 9.38968 13.171 9.53033 13.0303L14.0303 8.53033Z"
                          fill="#894CF5"
                        />
                      </svg>
                    ) : (
                      <svg
                        width="5"
                        height="5"
                        viewBox="0 0 5 5"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ marginTop: 8, marginBottom: 8 }}
                      >
                        <circle cx="2.5" cy="2.5" r="2.5" fill="#999999" />
                      </svg>
                    )}
                  </div>
                  {index !== (tracking.trackingDetailList?.length || 0) - 1 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      <div
                        style={{
                          width: 1,
                          marginTop: 2,
                          backgroundColor: "#999999",
                          height: "100%",
                        }}
                      ></div>
                    </div>
                  )}
                </div>
                <div>
                  <p className="TrackingDetailsChunkTimelineDate">
                    {detail.timeLabel}
                  </p>
                  <p className="TrackingDetailsChunkTimelineDescription">
                    {detail.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <SizedDiv height={36} />
        </React.Fragment>
      ))}
    </div>
  );
}

export default TrackingDetails;
