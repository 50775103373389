import React, { useEffect, useState } from "react";
import Scaffold, { ShowErrorModel } from "../../CommonComponents/UI/Scaffold/Scaffold";
import SizedDiv from "../../CommonComponents/UI/SizedDiv";
import $ from "jquery";
import ic_duplicate from "../../Assets/Images/ic_duplicate.svg";
import ic_plus_black from "../../Assets/Images/ic_plus_black.svg";
import ic_check from "../../Assets/Images/ic_check.svg";
import ic_camera from "../../Assets/Images/ic_camera.svg";
import ic_picture from "../../Assets/Images/ic_picture.svg";
import ic_x from "../../Assets/Images/ic_x.svg";
import ic_file from "../../Assets/Images/ic_file.svg";
import medium_celebration from "../../Reseller/RegisterPayment/Assets/medium-celebration.png";

import "./ResellerRegisterPayment.css";
import ButtonWidget from "../../CommonComponents/UI/ButtonWidget/ButtonWidget";
import { LoadingWidget } from "../../CommonComponents/UI/LoadingWidget/LoadingWidget";
import ResellerRegisterPaymentViewModel from "./ResellerRegisterPaymentViewModel";
import DraggableBottomSheet from "../../CommonComponents/UI/DraggableBottomSheet/DraggableBottomSheet";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import { useParams } from "react-router-dom";
import { convertToRupiah } from "../../CommonComponents/Utils";
import axios from "axios";

const ResellerRegisterPaymentPage = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const plan = parseInt(urlParams.get("plan") || "0");
    const { paymentCode } = useParams();
    const viewModel = new ResellerRegisterPaymentViewModel();
    const [isSubmittable, setIsSubmittable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showSheet, setShowSheet] = useState(false);
    const [animateOutSheet, setAnimateOutSheet] = useState(false);
    const [file, setFile] = useState<{ exists?: boolean; name?: string; file?: File }>();
    const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(
        localStorage.getItem("resellerPaid") === paymentCode
    );
    const [error, setError] = useState<{
        message: string;
        onRetryButtonTapped: () => void;
    } | null>(null);

    useEffect(() => {
        setIsSubmittable(file?.exists || false);
    }, [file]);

    const handleCloseBottomSheet = () => {
        setAnimateOutSheet(true);
        setTimeout(() => {
            setShowSheet(false);
            setAnimateOutSheet(false);
        }, 500);
    };

    const handleFileChange = (event: any) => {
        const selectedFile = event.target.files[0];
        console.log(selectedFile);
        setFile({ exists: true, name: selectedFile.name, file: selectedFile });
        setShowSheet(false);
    };

    const handleUnselectFile = () => {
        setFile({ exists: false, name: "" });
        $("#fileInput").val("");
        $("#captureInput").val("");
    };

    // async function handleSubmitForm() {
    //     setIsLoading(true);
    //     let formData = new FormData();
    //     formData.append("paymentCode", paymentCode || "");
    //     if (file?.file) formData.append("image", file?.file, file?.name);
    //     const endpointFileUpload = "https://manage.file.thefepi.com/appdata/Reseller/Upload/SubscriptionPaymentProof";
    //     return fetch(`${endpointFileUpload}`, {
    //         method: "POST",
    //         body: formData,
    //         headers: {
    //             // "X-AppKey":
    //             //     "eyJpdiI6Im9ySEUvR0owSjdVL2M1V3Y5emZzOUE9PSIsInZhbHVlIjoibGJ3Rk1qNWhTbGRtOFJqSmpVZU9xZz09IiwibWFjIjoiYTc1NjcwNjgyMDkzODNlOTg3NzY1NzI3MGVjMzlkNzk4M2FiMzU4NmFiMWI0Y2Q3NTEwNDRkZWEzNDFmYzMyMSJ9",
    //             // Authorization: "Basic dGhlZmVwaTohQCNhcHBzMjAyMVRI",
    //             Authorization:
    //                 "Bearer dWlMT3pIL3FOWHhHeTJjK0RpbEdaUStGeWt0VDNNUHVzQmlIcDdHWm5lNlViNEp3WmZYdlRlT1Y3cFY2cnVFclR4UGtGb3RhMXloMEVMUXFLSlczRFE9PQ==",
    //         },
    //         // redirect: "follow",
    //     })
    //         .then((response) => response.json())
    //         .then((data) => {
    //             data.data.imageUrl as string;
    //             setIsLoading(false);
    //             console.log("upload OK");
    //             localStorage.setItem("resellerPaid", paymentCode || "");
    //             localStorage.removeItem("resellerPackage");
    //             localStorage.removeItem("resellerPackagePrice");
    //             setIsPaymentSuccessful(true);
    //         })
    //         .catch((reason) => {
    //             // normal code
    //             setIsLoading(false);
    //             console.log("error", reason);
    //             setError({ message: "Please try again", onRetryButtonTapped: () => handleSubmitForm2() });

    //             // bypass
    //             // setIsLoading(false);
    //             // console.log("upload OK");
    //             // localStorage.setItem("resellerPaid", paymentCode || "");
    //             // localStorage.removeItem("resellerPackage");
    //             // localStorage.removeItem("resellerPackagePrice");
    //             // setIsPaymentSuccessful(true);
    //         });
    // }

    async function handleSubmitForm2() {
        setIsLoading(true);
        const authorizationHeader =
            "dWlMT3pIL3FOWHhHeTJjK0RpbEdaUStGeWt0VDNNUHVzQmlIcDdHWm5lNlViNEp3WmZYdlRlT1Y3cFY2cnVFclR4UGtGb3RhMXloMEVMUXFLSlczRFE9PQ==";
        let formData = new FormData();
        formData.append("paymentCode", paymentCode || "");
        if (file?.file) formData.append("image", file?.file, file?.name);
        const endpointFileUpload = "https://manage.file.thefepi.com/appdata/Reseller/Upload/SubscriptionPaymentProof";

        // Set up Axios request
        axios
            .post(endpointFileUpload, formData, {
                headers: {
                    Authorization: `Bearer ${authorizationHeader}`,
                    "Content-Type": "multipart/form-data", // Important when sending FormData
                },
            })
            .then((response) => {
                console.log(response.data); // Process the response data here
                setIsLoading(false);
                console.log("upload OK");
                localStorage.setItem("resellerPaid", paymentCode || "");
                localStorage.removeItem("resellerPackage");
                localStorage.removeItem("resellerPackagePrice");
                setIsPaymentSuccessful(true);
                setError(null);
            })
            .catch((error) => {
                console.error("Error:", error);
                setIsLoading(false);
                setError({ message: error, onRetryButtonTapped: () => handleSubmitForm2() });
            });
    }

    // async function handleSubmitForm3() {
    //     var myHeaders = new Headers();
    //     myHeaders.append(
    //         "Authorization",
    //         "dWlMT3pIL3FOWHhHeTJjK0RpbEdaUStGeWt0VDNNUHVzQmlIcDdHWm5lNlViNEp3WmZYdlRlT1Y3cFY2cnVFclR4UGtGb3RhMXloMEVMUXFLSlczRFE9PQ=="
    //     );

    //     var formdata = new FormData();
    //     formdata.append("paymentCode", paymentCode || "");
    //     if (file?.file) formdata.append("image", file?.file, file?.name);

    //     var requestOptions = {
    //         method: "POST",
    //         headers: myHeaders,
    //         body: formdata,
    //         // redirect: 'follow'
    //         // accept: "*/*",
    //         // credentials: 'include'
    //     };

    //     fetch("https://manage.file.thefepi.com/appdata/Reseller/Upload/SubscriptionPaymentProof", requestOptions)
    //         .then((response) => response.text())
    //         .then((result) => console.log(result))
    //         .catch((error) => console.log("error", error));
    // }

    return (
        <Scaffold showErrorModel={error || null}>
            {!isPaymentSuccessful ? (
                <>
                    <div className="container p-4" id="scaffoldContainer">
                        <div className="ResellerRegisterPaymentHeader">
                            <b>Selangkah lagi!</b>
                        </div>
                        <SizedDiv height={12} />
                        <div className="ResellerRegisterPaymentProse">
                            Lanjutkan pembayaran berlangganan dan upload bukti bayar dalam 24 jam
                        </div>
                        <SizedDiv height={24} />

                        <div className="ResellerRegisterPaymentDetails">
                            <div className="ResellerRegisterPaymentDetail">
                                <p>Pilihan Langganan:</p>
                                <div className="ResellerRegisterPaymentDetailContent">
                                    <b>{viewModel.subscriptionPlans[plan].name || "error"}</b>
                                </div>
                            </div>
                            <div className="ResellerRegisterPaymentDetail">
                                <p>Jumlah pembayaran:</p>
                                <div className="ResellerRegisterPaymentDetailContent">
                                    <b>
                                        {convertToRupiah(
                                            parseInt(
                                                viewModel.subscriptionPlans[plan].price.netPrice.replace("K", "000")
                                            )
                                        )}
                                    </b>
                                    <div style={{ display: "none" }} id="price_to_copy">
                                        {viewModel.subscriptionPlans[plan].price.netPrice.replace("K", "000")}
                                    </div>
                                    <button
                                        style={{ height: "24px" }}
                                        onClick={() =>
                                            viewModel.handleCopyToClipboard("price_to_copy", "copy_price_icon")
                                        }
                                    >
                                        <img id="copy_price_icon" src={ic_duplicate} alt="copy to clipboard" />
                                        <img
                                            style={{ display: "none", height: "15px" }}
                                            id="copy_price_icon_check"
                                            src={ic_check}
                                            alt="copy to clipboard"
                                        />
                                    </button>
                                </div>
                            </div>
                            <div className="ResellerRegisterPaymentDetail">
                                <p>Metode pembayaran:</p>
                                <b>BCA - Transfer Bank ke PT. IDE PICUP GROUP</b>
                            </div>
                            <div className="ResellerRegisterPaymentDetail">
                                <p>Nomor rekening:</p>
                                <div className="ResellerRegisterPaymentDetailContent">
                                    <b>7140-788-788</b>
                                    <div style={{ display: "none" }} id="rekening_to_copy">
                                        7140788788
                                    </div>
                                    <button
                                        style={{ height: "24px" }}
                                        onClick={() =>
                                            viewModel.handleCopyToClipboard("rekening_to_copy", "copy_rekening_icon")
                                        }
                                    >
                                        <img id="copy_rekening_icon" src={ic_duplicate} alt="copy to clipboard" />
                                        <img
                                            style={{ display: "none", height: "15px" }}
                                            id="copy_rekening_icon_check"
                                            src={ic_check}
                                            alt="copy to clipboard"
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <SizedDiv height={24} />

                        <div className="ResellerRegisterPaymentUploadLabel">
                            Bukti Bayar<span style={{ color: "red" }}>*</span>
                        </div>
                        {file?.exists ? (
                            <div className="ResellerRegisterPaymentUploadFileSelected">
                                <img src={ic_file} alt="file" />
                                <b>{file.name}</b>
                                <button onClick={() => handleUnselectFile()}>
                                    <img src={ic_x} alt="close" />
                                </button>
                            </div>
                        ) : (
                            <button className="ResellerRegisterPaymentUploadButton" onClick={() => setShowSheet(true)}>
                                <img src={ic_plus_black} alt="upload" />
                                Upload
                            </button>
                        )}
                    </div>
                    <div className="FixedBottomButtonContainer">
                        <ButtonWidget
                            backgroundColor={isSubmittable ? "#4A00E8" : ""}
                            isCurvy={true}
                            isEnabled={isSubmittable}
                            action={() => handleSubmitForm2()}
                        >
                            {isLoading ? <LoadingWidget /> : "Kirim"}
                        </ButtonWidget>
                    </div>
                    <input
                        style={{ display: "none" }}
                        accept="image/*"
                        id="fileInput"
                        type="file"
                        onChange={handleFileChange}
                    />
                    <input
                        style={{ display: "none" }}
                        type="file"
                        accept="image/*"
                        capture="environment"
                        id="captureInput"
                        onChange={handleFileChange}
                    ></input>

                    {showSheet && (
                        <DraggableBottomSheet
                            isShown={showSheet}
                            onClosed={() => handleCloseBottomSheet()}
                            animateOut={animateOutSheet}
                        >
                            <div className="DraggableBottomSheetContents">
                                <button
                                    className="DraggableBottomSheetItem"
                                    onClick={() => $("#captureInput").trigger("click")}
                                >
                                    <img src={ic_camera} alt="camera" />
                                    <SizedDiv width={8} />
                                    <p>Take photo</p>
                                </button>
                                <button
                                    className="DraggableBottomSheetItem"
                                    onClick={() => $("#fileInput").trigger("click")}
                                >
                                    <img src={ic_picture} alt="camera" />
                                    <SizedDiv width={8} />
                                    <p>Library</p>
                                </button>
                                <button className="DraggableBottomSheetItem" onClick={() => setShowSheet(false)}>
                                    <div style={{ width: "24px" }}>
                                        <img style={{ height: "13.5px" }} src={ic_x} alt="camera" />
                                    </div>
                                    <SizedDiv width={8} />
                                    <p>Cancel</p>
                                </button>
                                <SizedDiv height={8} />
                            </div>
                        </DraggableBottomSheet>
                    )}
                </>
            ) : (
                <>
                    <SizedDiv height={40} />
                    <div className="ResellerRegisterPaymentSuccessful">
                        <img src={medium_celebration} alt="hurray!" />
                        <SizedDiv height={24} />
                        <b>Pendaftaran diproses!</b>
                        <SizedDiv height={24} />
                        <p>
                            Terima kasih telah melakukan pembayaran. Data Anda sedang dalam proses pengecekan dan
                            aktivasi akun
                        </p>
                        <SizedDiv height={24} />
                        <p>
                            Mohon menunggu maks. 2x24 jam, customer service Semo akan segera menghubungi whatsapp Anda
                        </p>
                    </div>
                </>
            )}
        </Scaffold>
    );
};

export default ResellerRegisterPaymentPage;
