import React from 'react';

class CustomerLevelScreen extends React.Component {
    render() {
        return (
            <div className="container" id="MyLevel">
                {/*
                <div className="row">
                    <div className="col text-center">
                        <br></br>
                        <br></br>
                        <h4 style={{fontSize:'24px'}}>Comming Soon</h4>
                        <br></br>
                    </div>
                </div>
                */}
                <div className="row">
                    <div className="col text-left">
                        <br></br>
                        <h4 style={{ fontSize: '20px' }}>Customer Level System</h4>
                        <br></br>
                    </div>
                </div>
                <div className="row">
                    <div className="col text-center">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Level</th>
                                    <th scope="col">Purchase Count</th>
                                    <th scope="col">Purchase Amount</th>
                                    <th scope="col">Points Benefits</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>White</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td>Bronze</td>
                                    <td>1</td>
                                    <td>500,000</td>
                                    <td>1%</td>
                                </tr>
                                <tr>
                                    <td>Silver</td>
                                    <td>3</td>
                                    <td>1,500,000</td>
                                    <td>1%</td>
                                </tr>
                                <tr>
                                    <td>Gold</td>
                                    <td>5</td>
                                    <td>2,500,000</td>
                                    <td>1.5%</td>
                                </tr>
                                <tr>
                                    <td>Platinum</td>
                                    <td>7</td>
                                    <td>4,000,000</td>
                                    <td>1.5%</td>
                                </tr>
                                <tr>
                                    <td>Diamond</td>
                                    <td>9</td>
                                    <td>5,500,000</td>
                                    <td>2%</td>
                                </tr>
                                <tr>
                                    <td>VIP</td>
                                    <td>12</td>
                                    <td>10,000,000</td>
                                    <td>2%</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default CustomerLevelScreen;