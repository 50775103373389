import BaseUIComponent from "../../CommonComponents/Base/BaseUIComponent";
import NavigationButton from "../../CommonComponents/UI/ButtonWidget/NavigationButton";
import Scaffold from "../../CommonComponents/UI/Scaffold/Scaffold";
import CuratedProductsViewModel from "./CuratedProductsViewModel";
import StoreTopTabbarWidget from "../CommonComponents/Widgets/StoreTopTabbarWidget";
import ProductSummaryItemCellWidget from "../../CommonComponents/UI/Cells/ProductSummaryItemCellWidget";
import { LoadingWidget } from "../../CommonComponents/UI/LoadingWidget/LoadingWidget";
import CuratedProductsSortViewWidget from "./Widgets/CuratedProductsSortViewWidget";
import CuratedProductsTimerViewWidget from "./Widgets/CuratedProductsTimerViewWidget";
import {createSearchParams, NavigateFunction, useLocation, useNavigate, useParams} from 'react-router-dom'

import ic_cart from "../../Assets/Images/ic_cart_dark.svg";
import ic_sort from "../../Assets/Images/ic_arrow_sort.svg";
import ic_search from "../../Assets/Images/ic_search_dark.svg";

import "./CuratedProductsScreen.css";
import { getLocationQueries, getScreenSizeWithMaxWidth } from "../../CommonComponents/Utils"

export type CuratedProductsScreenProps = {
  navigate: NavigateFunction
}

class CuratedProductsScreen extends BaseUIComponent<CuratedProductsScreenProps, CuratedProductsViewModel> {
    listOfProductTopMargin = 0;

    constructor(props: CuratedProductsScreenProps) {
        const queries = getLocationQueries();


        super(
            props,
            new CuratedProductsViewModel({
                groupId: queries["groupId"],
                categoryId: queries["categoryId"],
                subCategoryId: queries["subCategoryId"] ?? null,
                detailId: queries["detailId"] ?? null,
            })
        );
        if (this.viewModel().fresh) {          
          this.viewModel().loadPreloadingProps();
        }

        window.addEventListener("scroll", () => this.handleScrolling());
    }

    componentWillUnmount() {
        this.viewModel().persist();
        window.removeEventListener("scroll", () => this.handleScrolling());
    }

    override render() {
        const selectedSubCategoryDetail = this.viewModel().selectedSubCategoryDetail ?? this.viewModel().subCategoryDetails[0]?.id;
        const topTabbarLabelModel = this.viewModel().subCategoryDetails.map(model => {
            return {
                ...model,
                onTap: () => {
                   this.viewModel().changeSelectedSubCategoryDetail(model.id)
                   
            const x = new URLSearchParams(window.location.search);
            const newParams = {
                groupId: x.get("groupId") || "",
                categoryId: x.get("categoryId") || "",
                subCategoryId: x.get("subCategoryId") || "",
                detailId: model.id || "",
              };
              // console.log(createSearchParams(newParams).toString())
            this.props.navigate(
              `?${createSearchParams(newParams).toString()}`,
              {
                replace: true,
              }
            );
                },
            };
        });
        const cellWidth = (this.state.width - 44.0) / 2;
        const sortModels = this.viewModel().sortModel;
        const nonMainContentTotalHeight =
            0 + (topTabbarLabelModel.length > 0 ? 32 : 0) + (this.viewModel().sortModel?.length ?? 0 ? 26 : 0) + (this.viewModel().timerModel ? 68 : 0);
        const timerModel = this.viewModel().timerModel;
        this.listOfProductTopMargin = 
            (topTabbarLabelModel.length > 0 ? 32 : 0) + 
            (timerModel ? 58 : 0) + 
            ((this.viewModel().sortModel?.length ?? 0) > 0  && this.viewModel().isFlashSaleEnded !== true ? 26 : 0);
        
        return (
            <Scaffold
                topBar={
                    <>
                        <p style={{ fontSize: "24px", fontWeight: "700" , flexGrow: 1, overflow: 'hidden',
textOverflow: 'ellipsis',
whiteSpace: 'nowrap'}}>{this.viewModel().pageTitle}</p>
                        <div style={{flexShrink: 0}}>
                        <NavigationButton 
                            src={ic_search} 
                            onClick={() => {
                                window.location.href = "/Store/Search";
                            }}
                        />
                        <NavigationButton
                            src={ic_cart}
                            numberOnRightTop={this.viewModel().numberInCart}
                            onClick={() => {
                                window.location.href="/Cart";
                            }}
                        /></div>
                    </>
                }
                isShowLoading={this.viewModel().isLoading && this.viewModel().subCategoryDetails.length === 0}
                selectedBottomBarItem="store"
                showErrorModel={this.viewModel().errorMessage}
                showSnackbar={this.viewModel().errorSnackbar}
                bottomModalChild={
                    sortModels ? (
                        <CuratedProductsSortViewWidget
                            models={sortModels}
                            selectedId={this.viewModel().selectedSortIdInSortWidget ?? ""}
                            onSelectSortId={value => this.viewModel().changeSelectedSortInSortWidget(value)}
                            onApplySortTapped={() => this.viewModel().applySort()}
                        />
                    ) : undefined
                }
                isShowingBottomModal={this.viewModel().isShowingSort}
                onBottomModalBackgroundTapped={() => this.viewModel().hideSort()}
            >
                <div id="CuratedProductsMainContentContainer">
                    {this.viewModel().isLoading && this.viewModel().subCategoryDetails.length !== 0 ? (
                        <div id="CuratedProducstMainContentLoadingContainer">
                            <LoadingWidget />
                        </div>
                    ) : (
                        <>
                            <div id="CuratedProductFloatingContainer">
                                {topTabbarLabelModel.length > 0 && <StoreTopTabbarWidget labels={topTabbarLabelModel} selectedId={selectedSubCategoryDetail} />}
                                {timerModel && <CuratedProductsTimerViewWidget timer={timerModel} screenWidth={this.state.width} />}
                                {(this.viewModel().sortModel?.length ?? 0) > 0 && this.viewModel().isFlashSaleEnded !== true && (
                                    <div id="CuratedProductsSelectedSortDisplayed">
                                        <button onClick={() => this.viewModel().showSort()}>
                                            <img src={ic_sort} alt="" />
                                            <div style={{ width: "4px" }} />
                                            {this.viewModel().sortModel?.find(value => value.id === this.viewModel().selectedSortId)?.label ?? ""}
                                        </button>
                                    </div>
                                )}
                            </div>
                            <div style={{marginTop:`${this.listOfProductTopMargin}px`}}>
                                {
                                    this.viewModel().isFlashSaleEnded === true ?
                                    <div id="CuratedProductsMainFlashSaleEndedContainer">
                                        <p>Flash Sale has ended, look forward to our next flash sale</p>
                                    </div> :
                                    <div id="CuratedProductsMainProductsContainer" style={{ height: `calc(100% - ${nonMainContentTotalHeight}px)` }}>
                                        {this.viewModel().products.map(model => {
                                          return   <ProductSummaryItemCellWidget 
                                            key={`CuratedProductListItem_${model.id}`} 
                                            model={model} 
                                            badgeUrls={model.badgeUrls}
                                            cellWidth={cellWidth} />
                                        })}
                                    {this.viewModel().totalOfProducts > this.viewModel().products.length && (
                                        <div id="CuratedProductsProductsLoadingContainer">
                                            <LoadingWidget />
                                        </div>
                                    )}
                                    </div>
                                }
                            </div>
                        </>
                    )}
                </div>
            </Scaffold>
        );
    }

    private handleScrolling() {
        const loadingContainerElement = document.querySelector("#CuratedProductsProductsLoadingContainer");
        const loadingContainerRect = loadingContainerElement?.getBoundingClientRect();

        if (loadingContainerRect) {
            if (!this.viewModel().loadingProducts && this.viewModel().products.length < this.viewModel().totalOfProducts && loadingContainerRect.y - (getScreenSizeWithMaxWidth().height - this.listOfProductTopMargin) < 200) {
                this.viewModel().loadProducts();
            }
        }
    }
}

const WrappedComponent = (props: any) => {
  const navigate = useNavigate()
  return <CuratedProductsScreen navigate={navigate} {...props} />
}

export default WrappedComponent