import BaseUIComponent from "../../CommonComponents/Base/BaseUIComponent"

import Scaffold, { ShowSnackbarModel } from "../../CommonComponents/UI/Scaffold/Scaffold";

import RegisterPasswordViewModel from "./RegisterPasswordViewModel";

import { Navigate  } from "react-router-dom";
import "./Register.css";


import ButtonWidget, { ButtonWidgetType } from "../../CommonComponents/UI/ButtonWidget/ButtonWidget";

import InputFieldWidget from "../../CommonComponents/UI/InputFieldWidget/InputFieldWidget";

export default class RegisterPasswordScreen extends BaseUIComponent<{}, RegisterPasswordViewModel> {
    constructor(props: {}) {
        super(props, new RegisterPasswordViewModel());
    }

    handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) =>{
        this.viewModel().onPasswordChanged(event.target.value);
    }

    handleChangeConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) =>{
        this.viewModel().onConfirmPasswordChanged(event.target.value);
    }

    handleSubmit = (event: any) => {
        this.viewModel().onSubmitPassword();
    }

    render() {
        if (this.viewModel().isSuccess) {
            return <Navigate  to='/RegisterProfile'/>;
        }

        return(
            <Scaffold 
                showSnackbar={
                    this.viewModel().errorMessage ? 
                    new ShowSnackbarModel({
                        message: this.viewModel().errorMessage ?? "",
                    }) : undefined
                }>
                
                <div id="RegisterContainer" className="container">
                    <div className="row">
                        <div className="col">
                            <InputFieldWidget
                                title="Password"
                                inputType="password"
                                isEnabled={true}
                                placeholder={"At least 8 characters"}
                                errorMessage={this.viewModel().errorMessagePassword}
                                action={(event) => {
                                    this.handleChangePassword(event);
                                }} 
                            ></InputFieldWidget>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col mtop22">
                            <InputFieldWidget
                                title="Confirm Password"
                                inputType="password"
                                isEnabled={true}
                                placeholder={"Confirm Password"}
                                errorMessage={this.viewModel().errorMessageConfirmPassword}
                                action={(event) => {
                                    this.handleChangeConfirmPassword(event);
                                }} 
                            ></InputFieldWidget>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col mtop22 text-center">
                            <ButtonWidget 
                                buttonType={ButtonWidgetType.primary} 
                                isLoading={this.viewModel().isLoadingButtonSubmit ? true : false}
                                isEnabled={this.viewModel().password !== "" && this.viewModel().confirm_password !== "" && this.viewModel().errorMessagePassword === "" && this.viewModel().errorMessageConfirmPassword === "" ? true : false}
                                width="calc(100% - 6px)"
                                action={() => {
                                    this.handleSubmit('');
                                }} 
                            >
                                Next
                            </ButtonWidget>
                        </div>
                    </div>
                </div>
            </Scaffold>
        );
    }
} 