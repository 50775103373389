import "./LocationSearch.css";

import ic_search from "../../Assets/Images/ic_search_dark.svg";
import ic_current_location from "../../Assets/Images/ic_current_location.svg";

import ViewController from "../../CommonComponents/Base/ViewController";
import Scaffold from "../../CommonComponents/UI/Scaffold/Scaffold";
import LocationSearchViewModel from "./LocationSearchViewModel";
import LocationSearchResultCellWidget from "./Widgets/LocationSearchResultCellWidget";
import { LoadingWidget } from "../../CommonComponents/UI/LoadingWidget/LoadingWidget";
import { WithCreatorLinkProps, withSellerId } from "../../CommonComponents/Utils";

 class LocationSearchViewController extends ViewController<WithCreatorLinkProps, LocationSearchViewModel> {
    constructor(props: WithCreatorLinkProps) {
        super(props, new LocationSearchViewModel({
            creatorLink: props.creatorLink
        }));

        this.viewModel.loadLiveLocation();
    }

    render() {
        return <div id="LocationSearchVC">
            <Scaffold
                backgroundColor="var(--washoutGrey)"
                topBar={
                    <>
                        <div className="FlexFillSpacing" style={{
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "16px",
                            zIndex: "20"
                        }}>
                            Address Pinpoint
                        </div>
                    </>
                }
            >
                <div style={{
                    display: "flex", 
                    flexDirection: "column", 
                    position: "absolute",
                    top: 0, left: 0, bottom: 0, right: 0,
                }}>
                    <div id="LocationSearchSearchContainer">
                        <img src={ic_search} alt="" style={{flexShrink: 0}} />
                        <input 
                            type="text"
                            value={this.viewModel.query}
                            placeholder="Enter Address"
                            autoComplete="off"
                            style={{
                                flexGrow: 1
                            }}
                            onChange={
                                (event) => {
                                    this.viewModel.onQueryChanged(event.target.value);
                                }
                            }
                        />
                    </div>
                    <div id="LocationSearchSearchResultsContainer">
                        {
                            this.viewModel.isLoading ? 
                            <>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    height: "100%"
                                }}>
                                    <LoadingWidget />
                                </div>
                            </> : 
                            <>
                                {
                                    this.viewModel.query.length > 0 ? 
                                    <>
                                        {
                                            this.viewModel.results.map((model, index) => {
                                                return <LocationSearchResultCellWidget
                                                    key={`${index}`}
                                                    title={model.title}
                                                    subtitle={model.addressLabel}
                                                    coordinate={{
                                                        latitude: model.latitude,
                                                        longitude: model.longitude
                                                    }}
                                                    isCurrentLocation={false}
                                                    onSelected={(location) => {
                                                        this.viewModel.onLocationSelected(location);
                                                    }}
                                                />
                                            })
                                        }
                                    </> : 
                                    <>
                                        {
                                            this.viewModel.isCurrentLocationLoading ?
                                            <>
                                                <div id="LocationSearchResultLoadingCell">
                                                    <img src={ic_current_location} alt="" />
                                                    <div>
                                                        <div className="ShimmerAnimation"/>
                                                        <div className="ShimmerAnimation"/>
                                                    </div>
                                                </div>
                                                <div id="LocationSearchResultSeparator"/>
                                            </> :
                                            <>
                                                {
                                                    this.viewModel.currentLocation &&
                                                    <LocationSearchResultCellWidget
                                                        title="My Current Location"
                                                        subtitle={this.viewModel.currentLocation.addressLabel}
                                                        coordinate={{
                                                            latitude: this.viewModel.currentLocation.latitude,
                                                            longitude: this.viewModel.currentLocation.longitude
                                                        }}
                                                        isCurrentLocation={true}
                                                        onSelected={(location) => {
                                                            this.viewModel.onLocationSelected(location);
                                                        }}
                                                    />
                                                }
                                            </>
                                        }
                                    </>
                                }
                            </>
                        }
                    </div>
                </div>
                {
                    this.viewModel.isSubmittingPinPoint &&
                    <div style={{
                        position: "fixed",
                        top: 0,
                        bottom: 0,
                        width: "100%",
                        maxWidth: 600,
                        backgroundColor: "#00000055",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 100
                    }}>
                        <LoadingWidget theme="dark" />
                    </div>
                }
            </Scaffold>
        </div>
    }
}

export default withSellerId(LocationSearchViewController)