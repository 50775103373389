import React from "react";
import ButtonWidget, { ButtonWidgetType } from "../../../CommonComponents/UI/ButtonWidget/ButtonWidget";
import { ImageWidget } from "../../../CommonComponents/UI/ImageWidget/ImageWidget";
import { ShowErrorModel } from "../../../CommonComponents/UI/Scaffold/Scaffold";
import { standarizeThefepiLink } from "../../../CommonComponents/Utils";
import StoreSectionFeedModel from "../Models/StoreSectionFeedModel";
import ic_error from "../../../Assets/Images/ic_error_pink.svg";
import ScrollContainer from "react-indiana-drag-scroll";
import timerCalculation from "../Utils/StoreDurationRemainingCalculation";
import ProductSummaryWithVideoItemCellWidget from "../../../CommonComponents/UI/Cells/ProductSummaryWithVideoItemCellWidget";
import { Swiper, SwiperSlide } from "swiper/react";

import "./StoreSectionFeed.css";
import "swiper/css";

interface StoreProductWithVideoThumbSectionFeedWidgetProps {
    id: string;
    screenWidth: number;
    model: StoreSectionFeedModel;
    timeRemaining?: number;
    isLoadingProducts: boolean;
    loadProductsError?: ShowErrorModel;
    selectedCategoryId?: string;
    onSelectCategory: (id: string) => void;
    isVisible: boolean;
}

interface StoreProductWithVideoThumbSectionFeedWidgetState {
    showingIndex: number;
}

export default class StoreProductWithVideoThumbSectionFeedWidget extends React.Component<StoreProductWithVideoThumbSectionFeedWidgetProps, StoreProductWithVideoThumbSectionFeedWidgetState> {

    constructor(props: StoreProductWithVideoThumbSectionFeedWidgetProps) {
        super(props);

        this.state = {
            showingIndex: 0
        };

        
    }

    render() {
        const model = this.props.model.contents;
        const timerModel = timerCalculation({
            screenWidth: this.props.screenWidth,
            timeRemaining: this.props.timeRemaining,
            totalDurationInSeconds: model.timer?.totalDurationInSeconds
        });
        const categoryCellWidth = 
            (this.props.screenWidth - 32 - 4 * 8) / 4.5 < 72 
            ? (this.props.screenWidth - 32 - 3 * 8) / 3.3 
            : (this.props.screenWidth - 32 - 4 * 8) / 4.5;
        const topBorderRadius = model.topCornerRadius ? `${model.topCornerRadius}px` : undefined;
        const bottomBorderRadius = model.bottomCornerRadius ? `${model.bottomCornerRadius}px` : undefined;
        const cellWidth = (this.props.screenWidth - 16) / 1.4;

        return <div id="StoreProductListSectionFeedSectionContainer" 
            className={this.props.id}
            style={{
              position: 'relative',
              backgroundColor:model.theme === "DARK" ? "black" : undefined,
              borderTopLeftRadius:topBorderRadius,
              borderTopRightRadius:topBorderRadius,
              borderBottomLeftRadius:bottomBorderRadius,
              borderBottomRightRadius:bottomBorderRadius
            }}
        >
         {model.backgroundImageUrl &&   <img src={model.backgroundImageUrl} alt="" style={{ objectFit: 'fill', position: 'absolute', inset: 0, maxWidth: '600px', height: '100%', width: '100%'}}/>}
            <div style={{zIndex: 1}}>
              {
                model.sectionTitle &&
                <div id="StoreProductListSectionFeedSectionTitleContainer">
                    {
                        model.topLeadingIconUrl && <div>
                            <ImageWidget imageUrl={model.topLeadingIconUrl} />
                        </div>
                    }
                    <p style={{
                        textAlign:(model.isSectionTitleCentered === true) ? "center" : "left",
                        color:model.theme === "DARK" ? "white" : undefined
                    }}> { model.sectionTitle } </p>
                    {
                        (model.seeAllDeeplink && model.seeAllPosition !== "BOTTOM") &&
                        <button 
                            onClick={() => window.location.href = standarizeThefepiLink(model.seeAllDeeplink ?? "")}
                        >
                            see all
                        </button>
                    }
                </div>
            }
            {
                timerModel && <div id="StoreProductListSectionFeedSectionTimerContainer">
                    <div>
                        <div style={{ width: `${timerModel.remainingTimeDivWidth}px` }} />
                    </div>
                    <p>
                        Ends in{" "}
                        <strong>
                            {timerModel.days} day(s) / {timerModel.hours} : {timerModel.minutes} : {timerModel.seconds}
                        </strong>
                    </p>
                </div>
            }
            {
                model.banners.length > 0 &&
                <button
                    style={{
                        width:`${this.props.screenWidth}px`, 
                        height:`${this.props.screenWidth * 5 / 9}px`,
                        marginBottom:"12px"
                    }} 
                    onClick={() => window.location.href = standarizeThefepiLink(model.banners[0].deeplink ?? "")}
                >
                    <ImageWidget imageUrl={model.banners[0].imageUrl} />
                </button>
            }
            {
                model.categoryItems.length > 0 &&
                    <div id="StoreProductListSectionFeedSectionCategoriesContainer">
                        {
                            model.categoryItems.map((model, index) => {
                                const pId = model.id === this.props.selectedCategoryId ? "StoreProductListSectionFeedSectionSelectedCategory" : "StoreProductListSectionFeedSectionUnselectedCategory";
                                return (
                                    <button 
                                        key={`HomeOnHypeCategory_${model.id}_${index}`} 
                                        onClick={() => this.props.onSelectCategory(model.id)}
                                        style={{width:`${categoryCellWidth}px`}}
                                    >
                                        <div style={{height:`${categoryCellWidth}px`, width:`${categoryCellWidth}px`}}>
                                            <ImageWidget imageUrl={model.imageUrl}/>
                                        </div>
                                        <div>
                                            <p id={pId}>{model.label}</p>
                                        </div>
                                    </button>
                                );
                            })
                        }
                    </div>
            }
            <div id={`StoreProductsWithVideo_${this.props.id}`}>
                {
                    this.props.isLoadingProducts && <ScrollContainer>
                        <div id="StoreProductListSectionFeedSectionProductsContainer">
                            {
                                Array.from({length: 4}).map(() => {
                                    return <div>
                                        <div className="ShimmerAnimation" style={{width:`${cellWidth}px`, height:`${cellWidth}px`, borderRadius:`8px`}} />
                                        <div style={{height:"2px"}}/>
                                        <div className="ShimmerAnimation" style={{width:`${cellWidth / 2}px`, height:`16px`, borderRadius:`8px`}} />
                                        <div style={{height:"2px"}}/>
                                        <div className="ShimmerAnimation" style={{width:`${cellWidth}px`, height:`16px`, borderRadius:`8px`}} />
                                        <div style={{height:"2px"}}/>
                                        <div className="ShimmerAnimation" style={{width:`${cellWidth * 3 / 4}px`, height:`14px`, borderRadius:`7px`}} />
                                    </div>;
                                })
                            }
                        </div>
                    </ScrollContainer>
                }
                {
                    this.props.loadProductsError && 
                    <div id="StoreProductListSectionFeedSectionLoadProductErrorContainer" style={{height:`${cellWidth + 52}px`}}>
                        <img src={ic_error} alt=""/>
                        <p>We found a problem, please try to reload.</p>
                        <ButtonWidget 
                            buttonType={ButtonWidgetType.primary} 
                            action={() => this.props.loadProductsError?.onRetryButtonTapped()} 
                            buttonHeight={32}
                        >
                            Retry
                        </ButtonWidget>
                    </div>
                }
                {
                    model.products.length > 0 &&
                    <div>
                        <Swiper
                            style={{
                                padding:"0px 16px"
                            }}
                            spaceBetween={12}
                            slidesPerView={1.4}
                            centeredSlides={true}
                            centeredSlidesBounds={true}
                            onSlideChangeTransitionEnd={(swiper) => {
                                if (this.state.showingIndex !== swiper.activeIndex) {
                                    this.setState({
                                        showingIndex: swiper.activeIndex
                                    });
                                }
                            }}
                        >
                            {
                                model.products.map((product, index) => {
                                    return (
                                        <SwiperSlide key={`HomeOnHypeProductsSlider_${product.id}_${index}_${this.state.showingIndex === index}`}>
                                            <ProductSummaryWithVideoItemCellWidget 
                                                key={`HomeOnHypeProducts_${product.id}_${index}_${this.state.showingIndex === index}`}
                                                model={product} 
                                                cellWidth={cellWidth - 12} 
                                                isPlayingVideo={this.props.isVisible && this.state.showingIndex === index}
                                                theme={model.theme}
                                            />
                                        </SwiperSlide>
                                    );
                                })
                            }
                        </Swiper>
                    </div>
                }
                {
                    (model.seeAllDeeplink && model.seeAllPosition === "BOTTOM") &&
                    <div style={{padding:"32px 16px 0px 16px"}}>
                        <ButtonWidget 
                            action={() => window.location.href = standarizeThefepiLink(model.seeAllDeeplink ?? "")}
                            buttonType={ButtonWidgetType.secondary}
                            
                        >
                            See all
                        </ButtonWidget>
                    </div>
                }
                {
                    (model.cta &&
                    <div style={{padding:"32px 16px 0px 16px"}}>
                        <ButtonWidget 
                            action={() => window.location.href = standarizeThefepiLink(model.cta?.deeplink ?? "")}
                            buttonType={ButtonWidgetType.secondary}
                            backgroundColor={model.cta.backgroundColorHex}
                            titleColor={model.cta.textColorHex}
                        >
                            {model.cta.title}
                        </ButtonWidget>
                    </div>)
                }
            </div>
            </div>
        </div>;
    }
}