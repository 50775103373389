import React from "react";

export type TrackIdFieldProps = {
  value?: string | number;
  onChange?: (value?: string | number) => void;
  placeholder?: string;
};

function TrackIdField(props: TrackIdFieldProps) {
  const {} = props;
  return (
    <div
      style={{
        padding: 16,
        fontSize: 12,
        border: "1px solid #E4E4E4",
        borderRadius: 18,
      }}
    >
      <input
        value={props.value}
        style={{ outline: "none", border: "none", width: '100%' }}
        placeholder={props.placeholder}
        onChange={(e) => props.onChange?.(e.target.value)}
      />
    </div>
  );
}

export default TrackIdField;
