import React from "react";
import BaseUIComponent from "../../CommonComponents/Base/BaseUIComponent"
import Scaffold, { ShowSnackbarModel } from "../../CommonComponents/UI/Scaffold/Scaffold";
import LoginViewModel from "./LoginSsoViewModel";
import { Navigate  } from "react-router-dom";
import "./Login.css";

import ButtonWidget, { ButtonWidgetType } from "../../CommonComponents/UI/ButtonWidget/ButtonWidget";
import InputFieldWidget from "../../CommonComponents/UI/InputFieldWidget/InputFieldWidget";

export default class LoginSsoScreen extends BaseUIComponent<{}, LoginViewModel> {
    constructor(props: {}) {
        super(props, new LoginViewModel());
    }

    handleSubmit = (event: any) => {
        this.viewModel().onSubmit();
    }

    handleChangeEmailTelp = (event: React.ChangeEvent<HTMLInputElement>) =>{
        this.viewModel().onEmailOrPhoneNumberChanged(event.target.value);
    }

    handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) =>{
        this.viewModel().onPwdChanged(event.target.value);
    }

    render() {
        if (this.viewModel().isSuccess) {
            return <Navigate  to='/'/>;
        }

        return(
            <Scaffold 
                showSnackbar={
                    this.viewModel().errorMessage ? 
                    new ShowSnackbarModel({
                        message: this.viewModel().errorMessage ?? "",
                    }) : undefined
                }>
                
                <div id="LoginNormalContainer" className="container">
                    <div className="row">
                        <div className="col">
                            <div>
                                <InputFieldWidget
                                    title="Email / Phone Number"
                                    inputType="text"
                                    isEnabled={true}
                                    placeholder={"Enter Email or Phone number"}
                                    errorMessage={this.viewModel().errorMessageEmailTelp}
                                    action={(event) => {
                                        this.handleChangeEmailTelp(event);
                                    }} 
                                ></InputFieldWidget>
                            </div>
                            <div className="mtop19">
                                <InputFieldWidget
                                    title="Password"
                                    inputType="password"
                                    isEnabled={true}
                                    placeholder={"Enter your Password"}
                                    errorMessage=""
                                    action={(event) => {
                                        this.handleChangePassword(event);
                                    }} 
                                ></InputFieldWidget>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col mtop22 text-center">
                            <ButtonWidget 
                                buttonType={ButtonWidgetType.primary} 
                                isLoading={this.viewModel().isLoadingButtonSubmit ? true : false}
                                isEnabled={this.viewModel().input_email_telp === "" || this.viewModel().pwd === "" ? false : true}
                                width="calc(100% - 6px)"
                                action={() => {
                                    this.handleSubmit('');
                                }} 
                            >
                                Sign In
                            </ButtonWidget>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col mtop35 text-center">
                            <p><a href="/ForgotPassword/">Forgot password ?</a></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col mtop35 text-center">
                            <p>New Here? <a href="/Register/">Create an account</a></p>
                        </div>
                    </div>
                </div>
            </Scaffold>
        );
    }
} 