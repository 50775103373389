import React from 'react';

class PhotoGuideScreen extends React.Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col text-center no-padding">
                        <img className="img-fluid" src={global.ImgUrl + 'shopable/Photo-Guide-App-3.webp'} alt="guide"></img>
                    </div>
                </div>

            </div>
        );
    }
}

export default PhotoGuideScreen;