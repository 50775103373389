import ic_thefepi_f from "../../Assets/Images/Logo/ic_fepi_f.svg";
import ic_photo_profile_placeholder from "../../Assets/Images/ic_photo_profile_placeholder.svg";

import "./EditProfileViewController.css";
import ViewController from "../../CommonComponents/Base/ViewController"
import NavigationButton from "../../CommonComponents/UI/ButtonWidget/NavigationButton"
import Scaffold from "../../CommonComponents/UI/Scaffold/Scaffold"
import EditProfileViewModel from "./EditProfileViewModel"
import { ImageWidget } from "../../CommonComponents/UI/ImageWidget/ImageWidget";
import InputFieldWidget from "../../CommonComponents/UI/InputFieldWidget/InputFieldWidget";
import ButtonWidget from "../../CommonComponents/UI/ButtonWidget/ButtonWidget";

export default class EditProfileViewController extends ViewController<{}, EditProfileViewModel> {
    constructor(props: {}) {
        super(props, new EditProfileViewModel());
        this.viewModel.loadDetail();
    }

    render() {
        return <Scaffold
            topBar={
                <>
                    <NavigationButton
                        src={ic_thefepi_f} 
                        onClick={() => window.location.href = "/"} 
                    />
                    <p style={{fontSize:"16px", fontWeight:"bold", marginLeft:"8px"}}>Edit Profile</p>
                    <div style={{flexGrow:1}}/>
                </>
            }
            isShowLoading={this.viewModel.isLoading}
        >
            <div id="EditProfileMainContentsContainer">
                <div>
                    <p>My Profile Picture</p>
                    <div>
                        <ImageWidget imageUrl={this.viewModel.model?.customer.profileImageUrl} placeholder={ic_photo_profile_placeholder} />
                    </div>
                    <div className="EditProfileFormContainer">
                        <InputFieldWidget 
                            title="Username"
                            placeholder="Enter your username"
                            errorMessage=""
                            inputType="text"
                            action={(event) => {
                                this.viewModel.changeUsername(event.target.value);
                            }}
                            value={this.viewModel.username}
                        />
                    </div>
                    <div className="EditProfileFormContainer">
                        <InputFieldWidget 
                            title="Phone Number"
                            placeholder="Enter your phone number"
                            errorMessage=""
                            inputType="number"
                            action={(event) => {
                                this.viewModel.changePhoneNumber(event.target.value);
                            }}
                            value={this.viewModel.phoneNumber}
                        />
                    </div>
                    <div className="EditProfileFormContainer">
                        <InputFieldWidget 
                            title="Email Account"
                            placeholder="Enter your email"
                            errorMessage={this.viewModel.isEmailValid ? "" : "Please check your email format"}
                            inputType="email"
                            action={(event) => {
                                this.viewModel.changeEmail(event.target.value);
                            }}
                            value={this.viewModel.emailAccount}
                        />
                    </div>
                    <div className="EditProfileFormContainer">
                        <InputFieldWidget 
                            title="DOB"
                            errorMessage=""
                            inputType="date"
                            action={(event) => {
                                this.viewModel.changeDob(event.target.value);
                            }}
                            value={this.viewModel.dob}
                        />
                    </div>
                </div>
                <div>
                    <ButtonWidget 
                        isLoading={this.viewModel.isUploadingProfile}
                        isEnabled={this.viewModel.isSaveButtonEnable}
                        action={() => this.viewModel.uploadEditProfile()}
                    >Save</ButtonWidget>
                </div>
            </div>
        </Scaffold>
    }
}