import React from "react";
import "./StoreSectionFeed.css";
import StoreSectionFeedModel from "../Models/StoreSectionFeedModel";
import { ImageWidget } from "../../../CommonComponents/UI/ImageWidget/ImageWidget";
import { standarizeThefepiLink } from "../../../CommonComponents/Utils";

export type StoreGridIconListWidgetProps = {
  model?: StoreSectionFeedModel;
  onGridItemClick?: (url?: string) => void;
};

export class StoreGridIconListWidget extends React.Component<StoreGridIconListWidgetProps> {
  render() {
    if (!this.props.model) return null;

    return (
      <div id="StoreGridIconListWidget" className="GridContainer">
        {this.props.model.contents.icons.map((icon, index) => {
          return (
            <button
              onClick={() => this.props.onGridItemClick?.(standarizeThefepiLink(icon.deeplink))}
              key={`button_${index}`}
              className="GridItemContainer"
            >
              <div className="GridItemImage">
                <ImageWidget imageUrl={icon.imageUrl} />
              </div>

              <div className="GridItemLabel">{icon.label}</div>
            </button>
          );
        })}
      </div>
    );
  }
}
