import React from "react";
import { CuratedProductsTimerModel } from "../Models/CureatedProductsResponse";

import "./CuratedProductsWidgets.css";

import ic_flash_sale from "../../../Assets/Images/ic_flash_sale.svg";

type CuratedProductsTimerViewWidgetProps = {
    timer: CuratedProductsTimerModel;
    screenWidth: number;
};

export default class CuratedProductsTimerViewWidget extends React.Component<CuratedProductsTimerViewWidgetProps> {
    render() {
        const timeNumberFormat = Intl.NumberFormat("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
        });
console.log({timeNumberFormat})
        const timeReamining = this.props.timer.durationRemainingInSeconds;
        const seconds = timeNumberFormat.format(timeReamining % 60);
        const minutes = timeNumberFormat.format(Math.floor((timeReamining / 60) % 60));
        const hours = timeNumberFormat.format(Math.floor((timeReamining / 3600) % 24));
        const days = timeNumberFormat.format(Math.floor(timeReamining / 86400));

        const flashSaleTimeRemainingPercentage = timeReamining / this.props.timer.totalDurationInSeconds;
        const remainingTimeDivWidth = flashSaleTimeRemainingPercentage * (this.props.screenWidth - 32);

        return (
            <div id="CuratedProductsTimerContainer">
                <div>
                    <div style={{ width: `${remainingTimeDivWidth}px` }} />
                </div>
                <div />
                <div>
                    <img src={ic_flash_sale} alt="" />
                    <p>
                        {
                            timeReamining <= 0 ? 
                            "Ended" : 
                            <>
                                Ends in{" "}
                                <strong>
                                    {days} day(s) / {hours} : {minutes} : {seconds}
                                </strong>
                            </>
                        }
                    </p>
                </div>
            </div>
        );
    }
}
