import Scaffold, {
  ShowSnackbarModel,
} from "../../CommonComponents/UI/Scaffold/Scaffold";
import ChannelChatPopup from "../../CommonComponents/UI/ChannelChatPopup/ChannelChatPopup";
import CheckoutShippingAddressWidget from "./Widgets/CheckoutShippingAddressWidget/CheckoutShippingAddressWidget";
import CheckoutNoteWidget from "./Widgets/CheckoutNoteWidget/CheckoutNoteWidget";
import CheckoutBillingWidget from "./Widgets/CheckoutBillingWidget/CheckoutBillingWidget";
import CheckoutViewModel from "./CheckoutViewModel";
import ic_info from "../../Assets/Images/ic_info_grey.svg";
import { Navigate } from "react-router-dom";

import "./Checkout.css";
import CheckoutItemSection from "./Widgets/CheckoutItemSection/CheckoutItemSection";
import SizedDiv from "../../CommonComponents/UI/SizedDiv";
import CheckoutPointWidget from "./Widgets/CheckoutPointWidget/CheckoutPointWidget";
import CheckoutCouponWidget from "./Widgets/CheckoutCouponWidget/CheckoutCouponWidget";
import Typography from "./Widgets/Typography/Typography";
import CheckoutButtonWidget from "./Widgets/CheckoutButtonWidget/CheckoutButtonWidget";
import React from "react";
import ViewController from "../../CommonComponents/Base/ViewController";
import LoginPopupMessageWidget from "../../CommonComponents/UI/PopupWidget/LoginPopupMessage/LoginPopupMessageWidget";
import { WithCreatorLinkProps, getResellerPhoneNumber, isResellerDomain, withSellerId } from "../../CommonComponents/Utils";
import mixpanel from "mixpanel-browser";

export class CheckoutScreen extends ViewController<
WithCreatorLinkProps,
  CheckoutViewModel
> {
  constructor(props: WithCreatorLinkProps) {
    super(props, new CheckoutViewModel({
      creatorLink: props.creatorLink
    }));
    this.viewModel.prepareCheckout();
    
      window.localStorage.removeItem('isLoaded');

    //MIXPANEL TRACKING: semo_page_view
    if (isResellerDomain()) {
      mixpanel.track("semo_page_view", {
        page_name: "Checkout",
      });
    }
  }

  handleSubmit = () => {
    window.location.href = "/Payment";
  };

  closeModal = () => {
    document.body.classList.remove("modal-open");
    this.viewModel.onCloseModal();
  };

  render() {
    const checkoutData = this.viewModel.checkoutData;
    const billingData = this.viewModel.billingData;

    const isAllowedToCheckout =
      Boolean(checkoutData?.lastShippingAddress) &&
      checkoutData?.sectionItems.filter(x => !Boolean(x.selectedCourier)).length === 0

    const isMixedPurchase = (checkoutData?.sectionItems.length || 0) > 1;

    if (localStorage.getItem("selectedBagItem") === null) {
      return <Navigate to="/" />;
    }

    return (
      <div id="CheckoutVC">
        <Scaffold
          backgroundColor="var(--washoutGrey)"
          isShowLoading={this.viewModel.isLoading}
          topBar={
            <>
              <div
                className="FlexFillSpacing"
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "8px",
                  zIndex: "20",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Checkout
              </div>
            </>
          }
          showSnackbar={
            this.viewModel.errorMessage
              ? new ShowSnackbarModel({
                  message: this.viewModel.errorMessage ?? "",
                  inset: {
                    bottom: 52
                  }
                })
              : undefined
          }
        >
          <div
            // id="CheckoutContainer"
            className="container"
            style={{
              padding: 0,
              backgroundColor: "var(--washoutGrey)",
              overflowY: "auto",
              paddingBottom: "88px",
            }}
          >
            <div>
              <div style={{ padding: "24px" }}>
                {this.viewModel.systemInfo?.map(info => <div id="CourierSelectorImportantMessage">
                                    <img src={ic_info} alt="" />
                                    <p style={{color: "var(--mediumGrey)", fontSize: "12px"}}>
                                        { info }
                                    </p>
                                </div>)}
                                <SizedDiv height={20}/>
                <Typography.Title mb={12}>Shipping Address</Typography.Title>
                <CheckoutShippingAddressWidget
                  addressId={
                    checkoutData?.lastShippingAddress?.shippingAddressId
                  }
                  addressDetails={checkoutData?.lastShippingAddress?.address}
                  addressName={checkoutData?.lastShippingAddress?.name}
                  orderId={this.viewModel.orderId ?? ""}
                  addressEmail={checkoutData?.lastShippingAddress?.email}
                />
                <SizedDiv height={16} />
                <CheckoutNoteWidget
                  note={this.viewModel.note}
                  onNoteChange={(event: any) => {
                    this.viewModel.onNoteChange(event.target.value);
                  }}
                />
              </div>
              {!isMixedPurchase ? (
                <div style={{ paddingBottom: 16, paddingTop: 0 }}>
                  <CheckoutItemSection
                    shippingAddressId={
                      checkoutData?.lastShippingAddress?.shippingAddressId
                    }
                    isMixedPurchase={isMixedPurchase}
                    items={checkoutData?.sectionItems[0].items}
                    billingItems={checkoutData?.billing.billingItems}
                    billingTotal={checkoutData?.billing.total}
                    courierName={
                      checkoutData?.sectionItems[0].selectedCourier?.title
                    }
                    courierPrice={
                      checkoutData?.sectionItems[0].selectedCourier?.price
                        .netPrice
                    }
                    courierImageUrl={
                      checkoutData?.sectionItems[0].selectedCourier?.imageUrl
                    }
                    onIsInsuranceCheckedChange={this.viewModel.createInsuranceCheckHandler(
                      checkoutData?.sectionItems[0].typeSection
                    )}
                    onCourierClick={
                      checkoutData?.lastShippingAddress?.shippingAddressId
                        ? this.viewModel.createCourierClickHandler({
                            index: 0,
                            orderId: this.viewModel.orderId,
                            shippingAddressId:
                              checkoutData?.lastShippingAddress
                                .shippingAddressId,
                            bagItemIds: checkoutData?.sectionItems[0].items.map(i => i.bagItemId),
                          })
                        : () => {}
                    }
                    isInsuranceChecked={this.viewModel.includeInsurances.has(
                      checkoutData?.sectionItems[0].typeSection || ""
                    )}
                    insuranceLabel={
                      checkoutData?.sectionItems[0].shippingInsuranceLabel
                    }
                  />
                </div>
              ) : (
                checkoutData?.sectionItems.map((section, index) => (
                  <div
                    style={{
                      paddingBottom: 16,
                      paddingTop: index === 0 ? 0 : 16,
                    }}
                  >
                    <CheckoutItemSection
                      shippingAddressId={
                        checkoutData.lastShippingAddress?.shippingAddressId
                      }
                      items={section.items}
                      isMixedPurchase={isMixedPurchase}
                      type={section.typeSection}
                      billingItems={section.subtotal.billingItems}
                      billingTotal={section.subtotal.total}
                      courierName={section.selectedCourier?.title}
                      courierImageUrl={section.selectedCourier?.imageUrl}
                      courierPrice={
                        section.selectedCourier?.price.netPrice || 0
                      }
                      onIsInsuranceCheckedChange={this.viewModel.createInsuranceCheckHandler(
                        section.typeSection
                      )}
                      onCourierClick={
                        checkoutData?.lastShippingAddress?.shippingAddressId
                          ? this.viewModel.createCourierClickHandler({
                              index,
                              orderId: this.viewModel.orderId,
                              shippingAddressId:
                                checkoutData.lastShippingAddress
                                  ?.shippingAddressId,
                              bagItemIds: section.items.map(i => i.bagItemId),
                            })
                          : () => {}
                      }
                      isInsuranceChecked={this.viewModel.includeInsurances.has(
                        section.typeSection
                      )}
                      insuranceLabel={section.shippingInsuranceLabel}
                    />
                  </div>
                ))
              )}

             {isResellerDomain() ? <></> : <>
             <div style={{ padding: "0 24px 0 24px" }}>
                <Typography.Title mb={12}>Point & Coupon</Typography.Title>
                <CheckoutPointWidget
                availablePoints={this.viewModel.availablePoints}
                  point={this.viewModel.point < 0 ? 0 : this.viewModel.point}
                  onClick={() => {
                    if (this.viewModel.isLoggedIn) {
                      window.location.href = this.viewModel.createPointPageRedirectUrl();
                    } else {
                      this.viewModel.openLoginPopup();
                    }
                  }}
                />
              </div>
              <SizedDiv height={16} />
              <div style={{ padding: "0 24px 0 24px" }}>
                <CheckoutCouponWidget
                  availableCoupons={checkoutData?.numberOfCoupons}
                  couponName={this.viewModel.couponName || this.viewModel.couponCode}
                  onUnsetCoupon={() => this.viewModel.handleUnsetCoupon()}
                  onClick={() => {
                    if (this.viewModel.isLoggedIn) {
                      window.location.href = "/Coupon";
                    } else {
                      this.viewModel.openLoginPopup();
                    }
                  }}
                />
              </div></>}
              <SizedDiv height={16} />
              <div style={{ padding: "0 24px 0 24px" }}>
              {this.viewModel.promoInfo?.map(info =>  <div id="CourierSelectorInfoContainer">
                                        <p style={{color: "var(--blueGrey)", fontSize: "12px", fontWeight: "bold"}}>{info.title}</p>
                                        <p style={{color: "var(--blueGrey)", fontSize: "12px"}}>{info.description}</p>
                                    </div>)}
              </div>
              
              <SizedDiv height={16} />
              <div style={{ padding: "0 24px 0 24px" }}>
                <Typography.Title mb={12}>Total Price</Typography.Title>
                <CheckoutBillingWidget
                  total={billingData?.total}
                  billing={billingData?.billingItems}
                />
              </div>
              <SizedDiv height={16} />
              <CheckoutButtonWidget
                resellerLink={!isResellerDomain() ? undefined : this.props.creatorLink}
                isDisabled={!isAllowedToCheckout}
                onClick={this.viewModel.createPayButtonClickHandler({
                  couponId: this.viewModel.couponId,
                  courierIds:
                    checkoutData?.sectionItems
                      .filter((i) => i.selectedCourier)
                      .map((i) => i.selectedCourier!!.courierId) || [],
                  deliveryNotes: this.viewModel.note || "",
                  includeInsurances: Array.from(
                    this.viewModel.includeInsurances
                  ),
                  orderId: this.viewModel.orderId,
                  point: this.viewModel.point || 0,
                  shippingAddressId:
                    checkoutData?.lastShippingAddress?.shippingAddressId,
                  couponCode: undefined,
                })}
              />
            </div>
            {isResellerDomain() ?
              <ChannelChatPopup 
                resellerNumber={getResellerPhoneNumber(this.props.creatorLink)}
                message={`Halo, ${this.props.creatorLink}`}
              /> : <ChannelChatPopup />
            }
            <LoginPopupMessageWidget showModal={this.viewModel.shouldShowLoginPopup} closeModal={() => {
              this.viewModel.closeLoginPopup();
            }}/>
          </div>
        </Scaffold>
      </div>
    );
  }
}

export default withSellerId(CheckoutScreen)