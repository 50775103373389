import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import ProductDetailResponse from "./Models/ProductDetailResponse";
import AttributeResponse from "./Models/AttributeResponse";
import TotalItemResponse from "./Models/TotalItemResponse";
import { ShowErrorModel, ShowSnackbarModel } from "../../CommonComponents/UI/Scaffold/Scaffold";
import { getDeviceModel, getPreloadProps, isResellerDomain } from "../../CommonComponents/Utils";
import ReactPixel from "react-facebook-pixel";
import { v4 as uuidv4 } from "uuid";
import ViewModel from "../../CommonComponents/Base/ViewModel";
import ProductAttributesResponseV2 from "./Models/ProductAttributesResponseV2";
import ProductSummaryItemModel from "../../CommonComponents/Models/ProductSummaryItemModel";
import { eventManager } from "../../Analytics/Events/Common";
import { ViewItemEvent } from "../../Analytics/Events/GA4/ViewItemEvent";
import { ShareUrlModel } from "../../CommonComponents/UI/ShareUrlPopUp/Models/ShareUrlModel";

export enum AttributeType {
    COLOR = "COLOR",
    TYPE = "TYPE",
    SIZE = "SIZE",
}

export type AttributeItemModel = {
    id: string;
    label: string;
};

export type AttributeModel = {
    title: string;
    type: AttributeType;
    items: {
        attribute: AttributeItemModel;
        stock?: number;
        isReadyStock?: boolean;
        isPreOrder?: boolean;
    }[];
};

export default class ProductDetailViewModel extends ViewModel {
  productId: string;
  productName: string = "1";
  tabId: string = "1";
  option1: string = "";
  option2: string = "";
  length: number = 0;
  numberInCart: number = 0;
  isEnableBtn: boolean = false;
  productDetail: ProductDetailResponse | null = null;
  // attributes: AttributeResponse | null = null;
  isLoading: boolean = true;
  isLoadingButtonSubmit: boolean = false;
  errorModel: ShowErrorModel | null = null;
  errorSnackBarModel: ShowSnackbarModel | null = null;
  Message: string | null = "";
  isSuccess: boolean = false;
  showModal: boolean = false;
  snackbarMessage: ShowSnackbarModel | null = null;
  count: number = 0;
  recommendations: ProductSummaryItemModel[] = [];
  sizes: {
    label: string;
    items: string[];
  }[] = [];
  shareUrlModel: ShareUrlModel | null = null;

  desiredQty = 1;
  shouldShowVariantPickerSheet?: boolean;
  attributeOptions: AttributeType[] = [];
  attributes: AttributeModel[] = [];
  primaryAttribute?: AttributeModel;
  _primaryAttribute?: AttributeModel;
  selectedMapAttributes: Record<
    AttributeType,
    AttributeItemModel & { stock: number; isPreOrder: boolean }
  > = {} as Record<
    AttributeType,
    AttributeItemModel & { stock: number; isPreOrder: boolean }
  >;

  shouldShowCouponSheet: boolean = false;
  couponData?: { title: string; description: string; tnc: string[] } = undefined;

  handleCouponClick(id: string) {
        console.log("konsol", id);
        this.emit(() => {
            this.shouldShowCouponSheet = true;
        });
        requestAPI(Endpoints.couponDetail, {
            parameters: {
                couponId: id,
            },
            onSuccess: (response: BaseResponse<{ title: string; description: string; tnc: string[] }>) => {
              this.emit(()=>{
                this.couponData = response.data;
              })
            },
            onFailure: (error) => {
                this.emit(() => {
                    this.isLoading = false;
                    this.errorModel = {
                        message: error,
                        onRetryButtonTapped: () => this.loadPreloadingProps(),
                    };
                });
            },
        });
    }

  closeCouponSheet() {
      this.emit(()=>{
        this.shouldShowCouponSheet = false;
        this.couponData = undefined;
      })
    }

  onShareLink() {
        let splitted = window.location.href.split("/") || []
        eventManager.flush();
        eventManager.add({
          content_type: "product",
          item_id: "product_" + parseInt(splitted[splitted.length-1])
        })
        this.emit(()=> this.shareUrlModel = new ShareUrlModel({urlString: window.location.href}));
    }

  toggleSheet() {
    this.emit(() => {
      if (this.shouldShowVariantPickerSheet) {
        // @ts-ignore
        this.selectedMapAttributes = {};
      }

      this.shouldShowVariantPickerSheet = !this.shouldShowVariantPickerSheet;
    });
  }

  addDesiredQty() {
    this.emit(() => {
      this.desiredQty = this.desiredQty + 1;
    });
  }

  subDesiredQty() {
    this.emit(() => {
      this.desiredQty = this.desiredQty - 1;
    });
  }

  creatorLink?: string;

  constructor(productId: string, creatorLink?: string) {
    super();

    this.creatorLink = creatorLink;

    this.productId = productId;
  }

    loadPreloadingProps() {
        this.emit(() => {
            this.isLoading = true;
            this.errorModel = null;
        });

        getPreloadProps({
            onSuccess: (_, __, numberInCart) => {
                this.emit(() => (this.numberInCart = numberInCart));

                if (localStorage.uuid === "" || localStorage.uuid === undefined) {
                    localStorage.setItem("uuid", uuidv4());
                }
                //TRACK GOOGLE
                window.gtag("event", "select_content", { content_type: "product" });

                this.loadProducts();
            },
            onFailure: (error) => {
                this.emit(() => {
                    this.isLoading = false;
                    this.errorModel = {
                        message: error,
                        onRetryButtonTapped: () => this.loadPreloadingProps(),
                    };
                });
            },
        });
    }

    getFirstSelectableAttributeItem():
        | {
              attribute: AttributeItemModel;
              stock?: number;
              isReadyStock?: boolean;
              isPreOrder?: boolean;
          }
        | undefined {
        return this.primaryAttribute?.items.find((i) => (i.stock || 0) > 0 || i.isPreOrder);
    }

    selectAttributeItem(k: AttributeType, item: AttributeItemModel, stock: number, isPreOrder: boolean) {
        this.emit(() => {
            this.selectedMapAttributes[k] = { ...item, stock, isPreOrder };
            const keysThatShouldBeDeleted = [...this.attributeOptions].slice(
                // @ts-ignore
                this.attributeOptions.findIndex((x) => x === k) + 1,
                this.attributeOptions.length
            );
            // console.log(this.attributeOptions)
            keysThatShouldBeDeleted.forEach((k) => {
                // @ts-ignore
                delete this.selectedMapAttributes[k];
            });
        });
    }

    getPrevKey(k: AttributeType): AttributeType | undefined {
        return this.attributeOptions[this.attributeOptions.findIndex((x) => x === k) - 1] as AttributeType;
    }

    getWhatKeyShouldWeLoadAfter(k: AttributeType): AttributeType | undefined {
        return this.attributeOptions[this.attributeOptions.findIndex((x) => x === k) + 1] as AttributeType;
    }

    loadAttributes(k?: AttributeType) {
        if (!k) return;
        requestAPI(Endpoints.productAttributesV2, {
            parameters: {
                selectedAttributeIds: Object.values(this.selectedMapAttributes).map((x) => x.id),
                attributeType: k,
                productId: this.productId,
            },
            onSuccess: (response: BaseResponse<ProductAttributesResponseV2>) => {
                this.emit(() => {
                    const currentAttributes = this.attributes;
                    const newAttributes = response.data.attributes;
                    // @ts-ignore
                    this.attributes = this.attributeOptions
                        .map((key) => {
                            const currentAttribute = currentAttributes.find((x) => x.type === key);
                            const newAttribute = newAttributes.find((x) => x.type === key);
                            if (!newAttribute && !currentAttribute) {
                                return this.primaryAttribute;
                            }
                            if (newAttribute) {
                                return newAttribute;
                            } else {
                                return currentAttribute;
                            }
                        })
                        .filter((x) => x);
                });
            },
            onFailure: () => {},
        });
    }

    getLastKey(): AttributeType {
        return this.attributeOptions[this.attributeOptions.length - 1] as AttributeType;
    }

    loadProducts() {
        this.emit(() => {
            this.isLoading = true;
            this.errorModel = null;
        });

        requestAPI(Endpoints.productDetail, {
            parameters: Object.assign(
                {},
                {
                    fpAuth: localStorage.fpAuth,
                    deviceId: localStorage.uuid,
                    productId: this.productId,
                },
                Boolean(localStorage.seller_id) && !isResellerDomain() && { sellerId: localStorage.seller_id },
                Boolean(this.creatorLink) && { creatorLink: this.creatorLink }
            ),
            onSuccess: (response: BaseResponse<ProductDetailResponse>) => {
                // this.primaryAttribute = response.data.primaryAttribute;
                if (window.location.host === "m.thefepi.com") {
                    //TRACK FB
                    var data = {
                        content_name: response.data.productName,
                        content_ids: this.productId,
                        content_type: "product",
                        value: response.data.price.netPrice,
                        currency: "IDR",
                    };
                    ReactPixel.track("ViewContent", data);
                }
                eventManager.flush();
                eventManager.set({
                    currency: "IDR",
                    value: response.data.price.netPrice,
                    items: [
                        {
                            brand: response.data.brand.name,
                            id: this.productId,
                            name: response.data.productName,
                            price: {
                                discount: response.data.price.discount,
                                net: response.data.price.netPrice,
                            },
                        },
                    ],
                });
                eventManager.fire(ViewItemEvent);
                eventManager.flush();
                this.emit(() => {
                    this.productDetail = response.data;
                    this.recommendations = response.data.recommendations;
                    this.isLoading = false;
                    this.sizes = response.data.sizeChart;
                    this.length = response.data.attributeOptions.length;
                    this.productName = response.data.productName;
                    this.isLoading = false;
                    this.primaryAttribute = response.data.primaryAttribute;
                    this._primaryAttribute = {
                        ...response.data.primaryAttribute,
                        items: response.data.primaryAttributes,
                    };
                    this.attributeOptions = response.data.attributeOptions;
                    this.attributes = [this.primaryAttribute];
                });
            },
            onFailure: (error: string) => {
                if(error.includes("Product Not found") && !isResellerDomain()) {
                  window.location.href = "/Brand/" + parseInt(error.substring(28));
                } else {
                  this.emit(() => {
                      this.isLoading = false;
                      this.errorModel = {
                          message: error,
                          onRetryButtonTapped: () => this.loadPreloadingProps(),
                      };
                  });
                }
            },
        });
    }

    onchangeTab(val: string) {
        this.emit(() => {
            this.tabId = val;
        });
    }

    onAddToCart() {
        //TRACK GOOGLE
        window.gtag("event", "add_to_cart", {
            items: [
                {
                    id: this.productId,
                    name: this.productDetail?.productName,
                    brand: this.productDetail?.brand.name,
                    price: this.productDetail?.price.netPrice,
                    currency: "IDR",
                },
            ],
        });

        if (window.location.host === "m.thefepi.com") {
            //TRACK FB
            var data = {
                content_name: this.productDetail?.brand.name,
                content_ids: this.productId,
                content_type: "product",
                quantity: 1,
                value: this.productDetail?.price.netPrice,
                currency: "IDR",
            };
            ReactPixel.track("AddToCart", data);
        }

        requestAPI(Endpoints.bagAdd, {
            parameters: Object.assign(
                {},
                {
                    deviceId: localStorage.uuid,
                    productId: this.productId,
                    attributes: Object.values(this.selectedMapAttributes).map((x) => x.id),
                    quantity: this.desiredQty,
                    flag: this.isResellerDomain ? "RESELLER" : "WEB",
                    // flag: getDeviceModel(2),
                },
                Boolean(localStorage.seller_id) && { sellerId: localStorage.seller_id },
                Boolean(this.creatorLink) && { creatorLink: this.creatorLink }
            ),
            onSuccess: (response: BaseResponse<ProductDetailResponse>) => {
                // this.emit(() => {
                //   this.showModal = true;
                //   this.Message = "Berhasil ditambah ke keranjang";
                // });

                this.bagTotalItem();
                this.toggleSheet();

                this.emit(() => {
                    this.snackbarMessage = new ShowSnackbarModel({
                        message: "Successfully added to bag",
                        inset: {
                            bottom: 58,
                        },
                        type: "info",
                    });
                });

                // this.emit(() => {
                //   this.showModal = true;
                //   this.Message = "Berhasil ditambah ke keranjang";
                // });
            },
            onFailure: (error: string) => {
                // console.log(error)
                this.emit(() => {
                    this.snackbarMessage = new ShowSnackbarModel({
                        message: error,
                        inset: {
                            bottom: 64,
                        },
                        type: "error",
                    });
                });
                // this.emit(() => {
                //   this.errorSnackBarModel = new ShowSnackbarModel({
                //     message: 'xxxxx'
                //   })
                // })
                // this.errorModel = {
                //     message: error,
                //     onRetryButtonTapped: () => void 0,
                //   };
                // this.emit(() => {
                //   this.Message = error;
                //   document.body.classList.add("modal-open");
                //   this.showModal = true;
                // });
            },
        });
    }

    bagTotalItem() {
        requestAPI(Endpoints.bagTotalItem, {
            parameters: {
                deviceId: localStorage.uuid,
                fpAuth: localStorage.fpAuth,
            },
            onSuccess: (response: BaseResponse<TotalItemResponse>) => {
                this.emit(() => (this.numberInCart = response.data.totalItems));
            },
            onFailure: (error: string) => {
                this.Message = error;
            },
        });
    }

    onCloseModal() {
        this.emit(() => (this.showModal = false));
    }
}
