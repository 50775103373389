import $ from "jquery";

export default class ResellerRegisterPaymentViewModel {
    handleCopyToClipboard = (copyableDivId: string, animateDivId: string) => {
        const textToCopy = document.getElementById(copyableDivId)?.innerText;
        const tempInput = document.createElement("input");
        tempInput.value = textToCopy || "";
        document.body.appendChild(tempInput);
        tempInput.select();
        tempInput.setSelectionRange(0, 99999);
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        this.animateDiv(animateDivId);
    };

    animateDiv = (animateDivId: string) => {
        $("#" + animateDivId).hide();
        $("#" + animateDivId + "_check").show();
        setTimeout(() => {
            $("#" + animateDivId + "_check").hide();
            $("#" + animateDivId).show();
        }, 1500);
    };

    fileToBlob(file: File): Promise<Blob> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            // Handle the onload event when the file reading is completed
            reader.onload = function (event) {
                const fileData = event.target?.result as ArrayBuffer; // The file data in array buffer format
                const blob = new Blob([fileData], { type: file.type }); // Create a Blob from the array buffer

                resolve(blob);
            };

            // Handle the onerror event if there is an issue reading the file
            reader.onerror = function (event) {
                reject(event.target?.error);
            };

            // Read the file as an array buffer
            reader.readAsArrayBuffer(file);
        });
    }

    subscriptionPlans = [
        {
            value: 1,
            name: "1 bulan",
            price: {
                netPrice: "99K",
                original: "249K",
            },
        },
        {
            value: 3,
            name: "3 bulan",
            price: {
                netPrice: "199K",
                original: "399K",
            },
        },
        {
            value: 6,
            name: "6 bulan",
            price: {
                netPrice: "699K",
                original: "399K",
            },
        },
        {
            value: 12,
            name: "12 bulan",
            price: {
                netPrice: "499K",
                original: "999K",
            },
        },
    ];
}
