import React from "react"

import { ImageWidget } from "../../../CommonComponents/UI/ImageWidget/ImageWidget";
import { ContentDisplayProductCell } from "./ContentDisplayProductCell";
import { IndexIndicator } from "../../../CommonComponents/UI/IndexIndicator/IndexIndicatorWidget";
import { Swiper, SwiperSlide } from "swiper/react";
import { ContentDisplayModel } from "../Models/ContentDisplayModels";
import ReadMoreWidget from "../../../CommonComponents/UI/ReadMore/ReadMoreWidget";

import "./ContentDisplay.css";
import "swiper/css";

import ic_photo_profile_placeholder from "../../../Assets/Images/ic_photo_profile_placeholder.svg";
import ic_love from "../../../Assets/Images/ic_love.svg";
import ic_love_filled from "../../../Assets/Images/ic_love_filled_red.svg"
import ic_share from "../../../Assets/Images/ic_share.svg";
import { normalizeString } from "../../../CommonComponents/Utils";

type ContentDisplayCellProps = {
    model: ContentDisplayModel,
    onLoveButtonTapped: (id: string, isLiked: boolean) => void,
    onFollowButtonTapped: (id: string) => void,
    onShareButtonTapped: (url: string) => void,
    sellerId?: string,
}

type ContentDisplayCellState = {
    activeImageIndex: number,
    isLiked: boolean,
    numberOfLikes: number,
    isFollowed: boolean,
    sellerId?: string
}

export class ContentDisplayCell extends React.Component<ContentDisplayCellProps, ContentDisplayCellState> {
    constructor(props: ContentDisplayCellProps) {
        super(props);
        this.state = {
            activeImageIndex: 0,
            isLiked: props.model.isLiked,
            numberOfLikes: props.model.totalOfLikes,
            isFollowed: props.model.sellerProfile.isFollowed,
            sellerId: props.model.sellerId
        }
    }

    render() {
        const model = this.props.model;
        const images = model.contentAssets
        const creatorDetail = model.sellerProfile;
        const sellerId = model.sellerId;
        const name = creatorDetail.name;
        const description =  model.contentDetail;
        // const description = "amsdkmaskdml \n aksdasdmaksd \n asdasdasd \n asdasd \n asdasdasd \n amsdkadlasndnasndjasdnkjadn \n asdasdasdas d\n asdadasda sd \n asdadad"
        // const description =  "\n\n\n\n\n\n\nasddsa\n\asdddasdasdasdasdad s a s d a k sm  d lamsldmalkmsdlmalkdmlamdmakmdlkamsdlkmalkmdlkamkldmaldmlakmdlkmald asdddasdasdasdasdadsasdaksmdlamsldmalkmsdlmalkdmlamdmakmdlkamsdlkmalkmdlkamkldmaldmlakmdlkmald asdddasdasdasdasdadsasdaksmdlamsldmalkmsdlmalkdmlamdmakmdlkamsdlkmalkmdlkamkldmaldmlakmdlkmald ";
        const products = model.products;

        return(
            <>
                <Swiper id="ContentDisplayImageSwiper"
                    slidesPerView={1} 
                    onSlideChangeTransitionEnd={(swiper) => this.setState({activeImageIndex: swiper.activeIndex, isLiked: this.state.isLiked})}
                >
                    {images.map((model, index) => 
                        <SwiperSlide key={index} style={{height: "100%"}}>
                            <ImageWidget imageUrl={model.assetUrl} loadingViewTheme="dark" key={index} />
                        </SwiperSlide>
                    )}
                </Swiper>
                
                <div id="ContentDisplayBottomContainer">
                    <div id="ContentDisplayBottomContentDetailRow">
                        <div id="ContentDisplayDescriptionColumn">
                            <div id="ContentDisplayCreatorRow">
                                <button onClick={() => {window.location.href = `/${model.sellerId ?? ""}`}}>
                                    <div id="ContentDisplayCreatorImage">
                                        <ImageWidget imageUrl={creatorDetail.imageUrl} placeholder={ic_photo_profile_placeholder} />
                                    </div>
                                    <p id="ContentDisplayCreatorName">{normalizeString(name)}</p>
                                </button>
                                {
                                    !this.state.isFollowed && 
                                    <button id="ContentDisplayCreatorFollowButton"
                                            onClick={(_) => {
                                                if (localStorage.fpAuth) {
                                                    this.setState({
                                                        activeImageIndex: this.state.activeImageIndex, 
                                                        isLiked: this.state.isLiked,
                                                        numberOfLikes: this.state.numberOfLikes,
                                                        isFollowed: !this.state.isFollowed
                                                    });
                                                }
                                                
                                                this.props.onFollowButtonTapped(model.sellerId);
                                            }} >
                                        Follow
                                    </button>
                                }
                            </div>
                            <ReadMoreWidget id="ContentDisplayDetail" numberOfShrinkedChars={60}>
                                {description}
                            </ReadMoreWidget>
                        </div>
                        <div id="ContentDisplayInteractionCTA">
                            <button className="ContentDisplayAction" 
                                    onClick={(_) => {
                                        if (localStorage.fpAuth) {
                                            this.setState({
                                                activeImageIndex: this.state.activeImageIndex, 
                                                isLiked: !this.state.isLiked,
                                                numberOfLikes: this.state.numberOfLikes + (this.state.isLiked ? -1 : 1),
                                                isFollowed: this.state.isFollowed
                                            })
                                        }
                                        
                                        this.props.onLoveButtonTapped(model.contentId, model.isLiked);
                                    }} >
                                <img src={this.state.isLiked ? ic_love_filled : ic_love} alt="love"></img>
                                <p>{this.state.numberOfLikes}</p>
                            </button>
                            <button 
                                className="ContentDisplayAction" 
                                onClick={() => {
                                    this.props.onShareButtonTapped(model.shareLink);
                                }}
                            >
                                <img src={ic_share} alt="share"></img>
                                <p>Share</p>
                            </button>
                        </div>
                    </div>
                    {
                        images.length > 1 && 
                        <div id="IndexIndicatorContainer">
                            <IndexIndicator totalOfIndices={images.length} showingIndex={this.state.activeImageIndex} />
                        </div>
                    }
                    <div id="ContentDisplayProductSwiperParent">
                        <Swiper 
                            id="ContentDisplayProductSwiper"
                            spaceBetween={18} 
                            slidesPerView={products.length === 1 ? 1 : 1.1}
                            centeredSlides={true}
                            centeredSlidesBounds={true}
                        >
                            {products.map((model, index) => 
                                <SwiperSlide key={index}>
                                    <ContentDisplayProductCell 
                                        sellerId={sellerId} 
                                        productModel={model} 
                                        key={index} 
                                    />
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </div>
                </div>
            </>
        );
    }
}