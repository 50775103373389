import ic_chevron_left from "../../Assets/Images/ic_chevron_left.svg";
import ic_search from "../../Assets/Images/ic_search_black.svg";
import ic_current_location from "../../Assets/Images/ic_current_location.svg";

import React, { useEffect, useState } from "react";
import Scaffold from "../../CommonComponents/UI/Scaffold/Scaffold";
import BackButton from "../../CommonComponents/UI/BackButton/BackButton";
import SizedDiv from "../../CommonComponents/UI/SizedDiv";
import { LoadingWidget } from "../../CommonComponents/UI/LoadingWidget/LoadingWidget";
import LocationSearchResultCellWidget from "../../Store/LocationSearch/Widgets/LocationSearchResultCellWidget";
import LocationSearchResponse, {
  LocationSearchResultModel,
} from "../../Store/LocationSearch/Models/LocationSearchResponse";
import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import ReverseGeoLocationResponse from "../../Store/LocationSearch/Models/ReverseGeoLocationResponse";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import $ from "jquery"
import { isResellerDomain } from "../../CommonComponents/Utils";
import mixpanel from "mixpanel-browser";

type ResellerRegisterPinpointPageProps = {};

function ResellerRegisterPinpointPage(props: ResellerRegisterPinpointPageProps) {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState<LocationSearchResultModel[]>([]);
  const [isCurrentLocationLoading, setIsCurrentLocationLoading] = useState(false);
  const [currentLocation, setCurrentLocation] = useState({
    addressLabel: "",
    latitude: 0,
    longitude: 0,
    title: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [onGoingRequest, setOnGoingRequest] = useState<NodeJS.Timeout | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    loadLiveLocation();

    //MIXPANEL TRACKING: semo_page_view
    if (isResellerDomain()) {
      mixpanel.track("semo_page_view", {
        page_name: "SetPinpoint",
      });
    }
  }, []);

  useEffect(() => {
    console.log(currentLocation);
  }, [currentLocation]);

  function handleQueryChange(value: string) {
    setQuery(value);
    setIsLoading(value.length > 0);
    setResults([]);

    setOnGoingRequest(
      setTimeout(() => {
        const abortController = new AbortController();
        abortController.abort("Modify query");

        if (value.length > 0) {
          _requestLocationSearch();
        }
      }, 1000)
    );
  }

  function _requestLocationSearch() {
    setIsLoading(true);
    setErrorMessage(undefined);

    requestAPI(Endpoints.checkoutLocation, {
      parameters: {
        query: query,
        latitude: currentLocation?.latitude,
        longitude: currentLocation?.longitude,
      },
      onSuccess: (response: BaseResponse<LocationSearchResponse>) => {
        if (response.result) {
          setIsLoading(false);
          setResults(response.data.locations);
          console.log(results);
        } else {
          setIsLoading(false);
          setErrorMessage(response.message);
        }
      },
      onFailure: (errorMessage) => {
        setIsLoading(false);
        setErrorMessage(errorMessage);
      },
    });
  }

  function handleLocationSelect(location: { label: string; latitude: number; longitude: number }) {
    localStorage.setItem("pinpointAddressLabel", location.label);
    localStorage.setItem("pinpointLatitude", location.latitude.toString());
    localStorage.setItem("pinpointLongitude", location.longitude.toString());
    localStorage.setItem(
      "pinpointTitle",
      results.find((result) => result.latitude === location.latitude && result.longitude === location.longitude)
        ?.title || location.label
    );
    $("#backButton").trigger("click");
  }

  function loadLiveLocation() {
    navigator.geolocation.getCurrentPosition((position) => {
      setIsCurrentLocationLoading(true);

      requestAPI(Endpoints.checkoutReverseGeoLocation, {
        parameters: {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        },
        onSuccess: (response: BaseResponse<ReverseGeoLocationResponse>) => {
          setIsCurrentLocationLoading(false);
          setCurrentLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            title: response.data.location.title,
            addressLabel: response.data.location.addressLabel,
          });
        },
        onFailure: (_) => {
          setIsCurrentLocationLoading(false);
        },
      });
    });
  }

  return (
    <Scaffold
      topBar={
        <>
          <SizedDiv width={4} />
          <BackButton id="backButton">
            <img alt="assets" src={ic_chevron_left} style={{ height: "17px", marginBottom: "1px" }}></img>
          </BackButton>
          <SizedDiv width={14} />
          <b style={{ fontSize: "larger" }}>Set address pinpoint</b>
        </>
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        }}
      >
        <div id="LocationSearchSearchContainer">
          <img src={ic_search} alt="" style={{ flexShrink: 0, height: "18px" }} />
          <input
            type="text"
            value={query}
            placeholder="Enter Address"
            autoComplete="off"
            style={{
              flexGrow: 1,
            }}
            onChange={(event) => {
              handleQueryChange(event.target.value);
            }}
          />
        </div>
        <div id="LocationSearchSearchResultsContainer">
          {isLoading ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <LoadingWidget />
              </div>
            </>
          ) : (
            <>
              {query.length > 0 ? (
                <>
                  {results.length > 0 ? (
                    <>
                      {results.map((model, index) => {
                        return (
                          <LocationSearchResultCellWidget
                            key={`${index}`}
                            title={model.title}
                            subtitle={model.addressLabel}
                            coordinate={{
                              latitude: model.latitude,
                              longitude: model.longitude,
                            }}
                            isCurrentLocation={false}
                            onSelected={(location) => {
                              handleLocationSelect(location);
                            }}
                          />
                        );
                      })}
                      {currentLocation && (
                        <LocationSearchResultCellWidget
                          title="My Current Location"
                          subtitle={currentLocation.addressLabel}
                          coordinate={{
                            latitude: currentLocation.latitude,
                            longitude: currentLocation.longitude,
                          }}
                          isCurrentLocation={true}
                          onSelected={(location) => {
                            handleLocationSelect(location);
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <div style={{ paddingLeft: "24px", paddingRight: "24px", fontSize: "small" }}>
                      <b>Location Not Found</b>
                      <SizedDiv height={4} />
                      <p>Check your spelling or select via map to select your location</p>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {isCurrentLocationLoading ? (
                    <>
                      <div id="LocationSearchResultLoadingCell">
                        <img src={ic_current_location} alt="" />
                        <div>
                          <div className="ShimmerAnimation" />
                          <div className="ShimmerAnimation" />
                        </div>
                      </div>
                      <div id="LocationSearchResultSeparator" />
                    </>
                  ) : (
                    <>
                      {currentLocation && (
                        <LocationSearchResultCellWidget
                          title="My Current Location"
                          subtitle={currentLocation.addressLabel}
                          coordinate={{
                            latitude: currentLocation.latitude,
                            longitude: currentLocation.longitude,
                          }}
                          isCurrentLocation={true}
                          onSelected={(location) => {
                            handleLocationSelect(location);
                          }}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </Scaffold>
  );
}

export default ResellerRegisterPinpointPage;
