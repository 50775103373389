import React, { useEffect } from "react";
import "./ResellerLandingPage.css";
import mainBanner from "./Assets/main-banner.png";
import step1 from "./Assets/step-1.png";
import whyus1 from "./Assets/whyus1.svg";
import whyus2 from "./Assets/whyus2.svg";
import whyus3 from "./Assets/whyus3.svg";
import whyus4 from "./Assets/whyus4.svg";
import whyus5 from "./Assets/whyus5.svg";
import whyus6 from "./Assets/whyus6.svg";
import whyus7 from "./Assets/whyus7.svg";
import whyus8 from "./Assets/whyus8.svg";
import whyus9 from "./Assets/whyus9.svg";
import whyus10 from "./Assets/whyus10.svg";
import step2 from "./Assets/step-2.png";
import step3 from "./Assets/step-3.png";
import step4 from "./Assets/step-4.png";
import ic_commission from "./Assets/ic_commission.svg";
import ic_view from "./Assets/ic_view.svg";
import ic_purchase from "./Assets/ic_purchase.svg";
import ic_contact_cs from "./Assets/ic_contact_cs.svg";
import thefepiLogoFull from "./Assets/thefepi-logo-full-50.png";
import semo from "./Assets/semo.png";
import bulk_order from "./Assets/bulk_order.svg";
import ic_star from "./Assets/ic_star.svg";
import wholesaleOrder1 from "./Assets/wholesale_order_1.svg";
import wholesaleOrder2 from "./Assets/wholesale_order_2.svg";
import wholesaleOrder3 from "./Assets/wholesale_order_3.svg";
import wholesaleOrder4 from "./Assets/wholesale_order_4.svg";
import wholesaleOrder5 from "./Assets/wholesale_order_5.svg";
import adlv_shirt from "./Assets/adlv_shirt.png";
import ic_sparkle from "./Assets/ic_sparkle.svg";
import shoes_your_brand from "./Assets/shoes_your_brand.svg";
import dropship2 from "./Assets/dropship_2.svg";
import dropship3 from "./Assets/dropship_3.svg";
import dropship4 from "./Assets/dropship_4.svg";
import referral from "./Assets/whyus10.svg";
import diskonEksklusif from "./Assets/diskon-eksklusif.svg";
import diskonEksklusifPlusPercent from "./Assets/diskon-eksklusif-plus-percentage.svg";
import diskonEksklusifPlusPlus from "./Assets/diskon-eksklusif-plusplus.svg";
import mlbLogo from "./Assets/mlb-logo.svg";
import hapaKristin from "./Assets/hapa-kristin-logo.svg";
import pinkFong from "./Assets/pinkfong-logo.svg";
import pororo from "./Assets/pororo-logo.svg";

import SizedDiv from "../../CommonComponents/UI/SizedDiv";
import ButtonWidget from "../../CommonComponents/UI/ButtonWidget/ButtonWidget";
import CollapsibleViewWidget from "../../CommonComponents/UI/CollapsibleView/CollapsibleViewWidget";
import { convertToRupiah, getAppLink } from "../../CommonComponents/Utils";
import ChannelChatPopup from "../../CommonComponents/UI/ChannelChatPopup/ChannelChatPopup";
import potentialProducts from "./Assets/PotentialProducts";
import mlbHat from "./Assets/mlb-hat.png";
import { Link } from "react-router-dom";
import mixpanel from "mixpanel-browser";

const ResellerLandingPage = () => {
  useEffect(() => {
    //MIXPANEL TRACKING: semo_page_view
    mixpanel.track("semo_page_view", {
      page_name: "LandingPage"
    })
  }, []);

  const whyUs = [
    {
      icon: whyus1,
      title: "Tersedia 36,000++ produk & 300++ brand yang akan terus bertambah",
      content: "-",
    },
    {
      icon: whyus2,
      title: "Koleksi paling hype & up-to-date dari Korea",
      content: "-",
    },
    {
      icon: whyus3,
      title: "Product 100% ORIGINAL",
      content: "-",
    },
    {
      icon: whyus4,
      title: "DISKON up to 1JUTA/PRODUK untuk pembelian wholesale (grosir)",
      content: "-",
    },
    {
      icon: whyus5,
      title: "Menawarkan KOMISI penjualan yang tinggi",
      content: "-",
    },
    {
      icon: whyus6,
      title: "Insight Produk dengan POTENSI jual tinggi",
      content: "-",
    },
    {
      icon: whyus7,
      title: "GRATIS biaya shipping internasional",
      content: "-",
    },
    {
      icon: whyus8,
      title: "Fitur DROPSHIP, tanpa repot stock & packing order",
      content: "-",
    },
    {
      icon: whyus9,
      title: "Berbagai METODE PEMBAYARAN yang aman bagi customer Anda",
      content: "-",
    },
    {
      icon: whyus10,
      title: "BONUS REFERRAL untuk hasilkan passive income",
      content: "-",
    },
  ];

  const howToWholesale = [
    {
      icon: wholesaleOrder1,
      text: "Pilih produk",
    },
    {
      icon: wholesaleOrder2,
      text: "Add to Cart",
    },
    {
      icon: wholesaleOrder3,
      text: "Masukkan min. qty yang sudah ditentukan",
    },
    {
      icon: wholesaleOrder4,
      text: "Checkout & selesaikan pembayaran",
    },
    {
      icon: wholesaleOrder5,
      text: "Nantikan order tiba & miliki stock pribadi yang bisa dijual kembali",
    },
  ];

  const howToDropship = [
    {
      icon: wholesaleOrder1,
      text: "Pilih produk",
    },
    {
      icon: dropship2,
      text: "Add to Shop",
    },
    {
      icon: dropship3,
      text: "Share link toko ke socmed, WA, etc.",
    },
    {
      icon: dropship4,
      text: "Raih komisi berlimpah!",
    },
  ];

  const faqs = [
    {
      section: "Benefit",
      content: [
        {
          q: "Bagaimana sih cara bergabung sebagai reseller SEMO?",
          a: "Hanya dengan menghubungi tim Customer Service kami, dan katakan bahwa kamu ingin mendaftar. Setelah itu kamu akan dipandu secara lengkap dan jelas oleh tim kami.",
        },
        {
          q: "Berapa biaya menjadi reseller di SEMO?",
          a: "Karena ada promo yang sedang berjalan, beginilah simulasi biaya menjadi reseller SEMO <br /> 1 month subscription - <s>249k</s> 99k <br /> 3 month subscription - <s>399k</s> 199k <br /> 6 months subscription - <s>699k</s> 399k <br /> 12 month subscription - <s>999k</s> 499k",
        },
        {
          q: "Bagaimana cara kerja sebagai partner SEMO?",
          a: "Kamu hanya perlu mendaftar, membuat akun, memilih barang, dan mempromosikan toko kamu. Kamu dapat melakukan semua hal itu dengan santai dari rumah. Untuk pengiriman dari Korea sampai ker rumah pelanggan, biar kami yang urus!",
        },
        {
          q: "Berapa komisi yang akan didapat oleh reseller SEMO?",
          a: "Kamu bisa mendapatkan komisi hingga 15% loh! Komisi akan berbeda dari setiap produk yang berada dalam etalase",
        },
        {
          q: "Apakah barang SEMO dijamin original?",
          a: "Semua produk yang ada di SEMO adalah produk ORIGINAL yang akan dikirimkan langsung dari KOREA. Jadi, kamu tidak perlu ragu lagi ya!",
        },
        {
          q: "Bagaimana cara pencairan dana, hasil keuntungan berjualan?",
          a: "Hasil keuntungan yang kamu dapatkan, akan langsung masuk ke dompet digital kamu",
        },
      ],
    },
  ];

  const brands = [
    {
      name: "ADLV",
      id: 178,
      imgUrl: "https://images.thefepi.com/file/brand_all/adlv.png",
    },
    {
      name: "MLB",
      id: 198,
      imgUrl: "https://images.thefepi.com/file/brand_all/MLB.png",
    },
    {
      name: "Mardi Mercredi",
      id: 372,
      imgUrl: "https://images.thefepi.com/file/brand_all/20230411_094213_small.png",
    },
    {
      name: "NIKE",
      id: 158,
      imgUrl: "https://images.thefepi.com/file/brand_all/nike.png",
    },
    {
      name: "Crocs",
      id: 334,
      imgUrl: "https://images.thefepi.com/file/brand_all/20221017_101718_small.png",
    },
    {
      name: "Ambler",
      id: 199,
      imgUrl: "https://images.thefepi.com/file/brand_all/brands_circle_AMBLER-min.png",
    },
    {
      name: "carhartt",
      id: 204,
      imgUrl: "https://images.thefepi.com/file/brand_all/LOGO-CARHARTT2.png",
    },
    {
      name: "LifeWork",
      id: 276,
      imgUrl: "https://images.thefepi.com/file/brand_all/Logo-Lifework-1.png",
    },
    {
      name: "Graver",
      id: 247,
      imgUrl: "https://images.thefepi.com/file/brand_all/Graver_Logo_1008.png",
    },
    {
      name: "Comme des Garcons",
      id: 160,
      imgUrl: "https://images.thefepi.com/file/brand_all/comme.png",
    },
    {
      name: "Adidas",
      id: 184,
      imgUrl: "https://images.thefepi.com/file/brand_all/adidas.png",
    },
    {
      name: "Stand Oil",
      id: 370,
      imgUrl: "https://images.thefepi.com/file/brand_all/20230405_150819_small.png",
    },
    {
      name: "Wooalong",
      id: 369,
      imgUrl: "https://images.thefepi.com/file/brand_all/20230330_093553_small.png",
    },
    {
      name: "roba de dona",
      id: 274,
      imgUrl: "https://images.thefepi.com/file/brand_all/RDD_Logo_0605.png",
    },
    {
      name: "Hapa Kristin",
      id: 356,
      imgUrl: "https://images.thefepi.com/file/brand_all/20230117_140034_small.png",
    },
    {
      name: "KENZO",
      id: 248,
      imgUrl: "https://images.thefepi.com/file/brand_all/kenzo.png",
    },
    {
      name: "Vans",
      id: 169,
      imgUrl: "https://images.thefepi.com/file/brand_all/vans.png",
    },
    {
      name: "Tasche",
      id: 269,
      imgUrl: "https://images.thefepi.com/file/brand_all/Tasche_Logo_1204.png",
    },
  ];

  const subscriptionPlans = [
    {
      name: "1 bulan",
      price: {
        netPrice: "99K",
        originalPrice: "149K",
      },
    },
    {
      name: "3 bulan",
      price: {
        netPrice: "199K",
        originalPrice: "399K",
      },
      badgeText: "populer",
    },
    {
      name: "6 bulan",
      price: {
        netPrice: "399K",
        originalPrice: "699K",
      },
    },
    {
      name: "12 bulan",
      price: {
        netPrice: "499K",
        originalPrice: "999K",
      },
      badgeText: "ultimate saver",
    },
  ];

  return (
    <div className="ResellerLandingPageContainer">
      {/* <ChannelChatPopup /> */}
      <div className="ResellerLandingPageFloatingHeader">
        <img src={semo} alt="semo-logo" />
      </div>
      <div className="ResellerLandingPageFloatingFooter" style={{ visibility: "hidden" }}>
        <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
          <button style={{ flexShrink: 0 }} data-bs-toggle="modal" data-bs-target="#ChatModal">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 13V13C10 9.68629 12.6863 7 16 7V7C19.3137 7 22 9.68629 22 13V13" stroke="black" />
              <rect x="17" y="19" width="3" height="2" rx="1" stroke="black" />
              <path d="M22 18V18C22 19.1046 21.1046 20 20 20V20" stroke="black" />
              <path d="M8 15C8 13.8954 8.89543 13 10 13H11V18H10C8.89543 18 8 17.1046 8 16V15Z" stroke="black" />
              <path
                d="M24 16C24 17.1046 23.1046 18 22 18L21 18L21 13L22 13C23.1046 13 24 13.8954 24 15L24 16Z"
                stroke="black"
              />
              <path
                d="M3 7V21C3 23.2091 4.79086 25 7 25H13.1716C13.702 25 14.2107 25.2107 14.5858 25.5858L16.2929 27.2929C16.6834 27.6834 17.3166 27.6834 17.7071 27.2929L19.4142 25.5858C19.7893 25.2107 20.298 25 20.8284 25H25C27.2091 25 29 23.2091 29 21V7C29 4.79086 27.2091 3 25 3H7C4.79086 3 3 4.79086 3 7Z"
                stroke="black"
              />
            </svg>
          </button>

          <div style={{ flex: 1 }}>
            <ButtonWidget
              action={() => {
                // @ts-ignore
                window.location.href = `https://api.whatsapp.com/send?phone=${window.__config.DEFAULT_CS_PHONE_NUMBER}&text=Halo saya tertarik menjadi reseller SEMO`;
              }}
              backgroundColor="#364DFF"
            >
              Daftar jadi reseller
            </ButtonWidget>
          </div>
        </div>
      </div>
      <SizedDiv height={63} />
      <div className="ResellerLandingPageMainBanner">
        <img className="ResellerLandingPageMainBannerImage" alt="main-reseller-banner" src={mainBanner} />
      </div>
      <div style={{ background: "black" }}>
        <div className="ResellerLandingPageContentHeader ResellerGradient">
          <SizedDiv height={24} />
          <div className="ResellerLandingPageProse">
            <p>
              SEMO - Platform reseller & dropship yang disupport penuh oleh&nbsp;
              <a
                style={{ color: "#6c32ea", textDecoration: "underline", fontWeight: "800" }}
                href={
                  process.env.REACT_APP_ENV === "production" ? "https://m.thefepi.com" : "https://dev.m.thefepi.com"
                }
                target="_blank"
                rel="noreferrer"
              >
                thefepi
              </a>
              .
            </p>
            <br />
            <p>Bangun bisnis retail fashion, beauty, dan lifestyle dengan mudah serta keuntungan yang berlimpah.</p>
            <br />
            <p>
              Menghadirkan fitur space toko online dengan kemudahan mengatur produk yang ingin ditampilkan. Potensi
              komisi tinggi, serta dapatkan harga terbaik untuk pembelian wholesale.
            </p>
          </div>
          <SizedDiv height={24} />
        </div>
      </div>
      <div className="ResellerLandingPageMainContent">
        <SizedDiv height={24} />
        <div className="ResellerLandingPageHeadline">Mengapa resell di SEMO?</div>
        <SizedDiv height={32} />
        <div>
          {whyUs.map((c) => (
            <>
              <div className="ResellerLandingPageWhyUsItem">
                <img src={c.icon} alt={c.icon} />
                <p>{c.title}</p>
              </div>
              <SizedDiv height={24} />
            </>
          ))}
        </div>
      </div>
      {/* <SizedDiv height={24} /> */}
      <div className="ResellerLandingPageHowToSection">
        <SizedDiv height={48} />
        <div className="ResellerLandingPageHeadline">Daftar & Gabung Jadi Member</div>
        <div className="ResellerLandingPageHeadline">Nikmati kemudahan wholesale & dropship!</div>
        <SizedDiv height={24} />
        <div className="ResellerLandingPageHowToSectionStep">
          <div className="ResellerLandingPageHowToSectionStepAsset">
            <div className="ResellerLandingPageHeadline">1</div>
            <SizedDiv width={8} />
            <img style={{ width: "160px" }} src={step1} />
          </div>

          <div className="ResellerLandingPageHowToSectionStepContent">
            <SizedDiv height={8} />
            <div style={{ padding: "0 31.5px" }}>
              <button
                onClick={() => {
                  // @ts-ignore
                  window.open(
                    `https://${process.env.REACT_APP_ENV === "production" ? "" : "staging."}semo.co.id/register`,
                    "_blank"
                  );
                }}
              >
                Isi data diri
              </button>
            </div>
            <div className="ResellerLandingPageProse">
              <SizedDiv height={8} />
              Lengkapi form & pilih durasi berlangganan
            </div>
          </div>
        </div>
        <SizedDiv height={24} />
        <div className="ResellerLandingPageHowToSectionStep">
          <div className="ResellerLandingPageHowToSectionStepAsset">
            <div className="ResellerLandingPageHeadline">2</div>
            <SizedDiv width={8} />
            <img style={{ width: "160px" }} src={step2} />
          </div>

          <div className="ResellerLandingPageHowToSectionStepContent">
            <SizedDiv height={8} />
            <div style={{ padding: "0 31.5px" }}>
              <button
                onClick={() => {
                  window.open(getAppLink(), "_blank");
                }}
              >
                Download thefepi
              </button>
            </div>
            <div className="ResellerLandingPageProse">
              <SizedDiv height={8} />
              Install aplikasi untuk mengaktifkan dan mengatur toko
            </div>
          </div>
        </div>
        <SizedDiv height={24} />
        <div className="ResellerLandingPageHowToSectionStep">
          <div className="ResellerLandingPageHowToSectionStepAsset">
            <div className="ResellerLandingPageHeadline">3</div>
            <SizedDiv width={8} />
            <img style={{ width: "160px" }} src={step3} />
          </div>

          <div className="ResellerLandingPageHowToSectionStepContent">
            <SizedDiv height={8} />
            <div style={{ padding: "0 31.5px" }}>
              <button
                onClick={() => {
                  // window.location.href =
                  window.open(
                    process.env.REACT_APP_ENV === "production"
                      ? "https://m.thefepi.com/Store/Products?groupId=Home&categoryId=WeeklyProducts&detailId=All"
                      : "https://dev.m.thefepi.com/Store/Products?groupId=Home&categoryId=WeeklyProducts&detailId=All",
                    "_blank"
                  );
                }}
              >
                Eksplor produk
              </button>
            </div>
            <div className="ResellerLandingPageProse">
              <SizedDiv height={8} />
              Temukan berbagai produk dengan potensi jual tinggi untuk Anda
              <p>
                <b>
                  <u>Wholesale</u>
                </b>{" "}
                atau{" "}
                <b>
                  <u>Dropship</u>
                </b>
              </p>
            </div>
          </div>
        </div>
        <SizedDiv height={24} />
        <div className="ResellerLandingPageHowToSectionStep">
          <div className="ResellerLandingPageHowToSectionStepAsset">
            <div className="ResellerLandingPageHeadline">4</div>
            <SizedDiv width={8} />
            <img style={{ width: "160px" }} src={step4} />
          </div>

          <div className="ResellerLandingPageHowToSectionStepContent">
            <SizedDiv height={8} />
            <div style={{ padding: "0 31.5px" }}>
              <button
                onClick={() => {
                  window.open(
                    process.env.REACT_APP_ENV === "production"
                      ? `https://m.thefepi.com/MySpace`
                      : `https://dev.m.thefepi.com/MySpace`,
                    "_blank"
                  );
                }}
              >
                Share toko
              </button>
            </div>
            <div className="ResellerLandingPageProse">
              <SizedDiv height={8} />
              Datangkan banyak customer dari link toko yang Anda bagikan ke sosmed atau platform lain.
            </div>
          </div>
        </div>
        <SizedDiv height={48} />
      </div>

      <div className="ResellerLandingPageWholesaleOrderSection">
        <SizedDiv height={48} />
        <div className="ResellerLandingPageHeadline">2 Sistem 1 Tujuan</div>
        <div className="ResellerLandingPageHeadline">Apapun caranya tetap bisa cuan!</div>
        <SizedDiv height={32} />
        <div className="ResellerLandingPageWholeSaleOrderSectionHeadingPicture">
          <img alt="wholesale" src={bulk_order} />
        </div>
        <div className="ResellerLandingPageWholesaleOrderSectionContent">
          <div className="ResellerLandingPageWholesaleOrderSectionHeadingTitleContainer">
            <div className="ResellerLandingPageWholesaleOrderSectionHeadingTitle">#1&nbsp;&nbsp;Wholesale Order</div>
          </div>
          <SizedDiv height={38} />
          <div className="ResellerLandingPageProse">
            Miliki stock pribadi dan dapatkan harga terbaik. Lakukan pembelian min. 6 qty/produk atau total harga min.
            3,000,000/produk dalam sekali order dan dapatkan keuntungan berikut:
          </div>
          <SizedDiv height={16} />
          <div style={{ textAlign: "left", display: "flex", flexDirection: "column", gap: "8px" }}>
            <div className="ResellerLandingPageListWithCustomBullet">
              <div className="ResellerLandingPageListWithCustomBulletImage">
                <img alt="star" src={ic_star}></img>
              </div>
              DISKON spesial wholesale
            </div>
            <div className="ResellerLandingPageListWithCustomBullet">
              <div className="ResellerLandingPageListWithCustomBulletImage">
                <img alt="star" src={ic_star}></img>
              </div>
              Ongkir lebih HEMAT
            </div>
            <div className="ResellerLandingPageListWithCustomBullet">
              <div className="ResellerLandingPageListWithCustomBulletImage">
                <img alt="star" src={ic_star}></img>
              </div>
              Amankan stok produk untuk dijual kembali
            </div>
          </div>
          <SizedDiv height={32} />
          <div style={{ fontSize: "20px", textAlign: "center" }}>
            <b>Cara mudah wholesale order</b>
          </div>
          <SizedDiv height={12} />
          {howToWholesale.map((c, i) => (
            <>
              <div className="ResellerLandingPageHowToSectionStep">
                <div className="ResellerLandingPageHowToSectionStepAsset">
                  <div className="ResellerLandingPageHeadline">{i + 1}</div>
                  <SizedDiv width={8} />
                  <img alt="wholesaleOrder1" style={{ width: "160px" }} src={c.icon} />
                </div>
                <div className="ResellerLandingPageProse">{c.text}</div>
              </div>
              <SizedDiv height={12} />
            </>
          ))}
          <SizedDiv height={32} />
          <div className="ResellerLandingPageHeadline">Simulasi keuntungan dari wholesale order</div>
          <SizedDiv height={32} />
          <div className="ResellerLandingPageProse">
            Mengambil contoh produk ALDV. MLB, dan AMBLER, sebagai salah satu best selling brand dari kami.
          </div>
          <SizedDiv height={24} />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="ResellerLandingPageCommissionSimulationContent">
              {/* ADLV ITEM */}
              <div style={{ display: "flex", justifyContent: "left" }}>
                <div className="ResellerLandingPageCommissionSimulationItem">
                  <img src={adlv_shirt} alt="ADLV BABY FACE SHORT SLEEVE T-SHIRT BLACK BINOCULARS" />
                  <SizedDiv width={4} />
                  <div className="ResellerLandingPageCommissionSimulationDescription">
                    <b>ADLV</b>
                    <p>Rp 799,200</p>
                    <SizedDiv height={8} />
                    <p>wholesale price (-7%)</p>
                    <b>Rp 743,256</b>
                    <SizedDiv height={4} />
                    <p>your own resell price</p>
                    <b>Rp 825,000</b>
                    <SizedDiv height={4} />
                    <div className="ResellerLandingPagePotentialProductCommissionCard">
                      <div className="ResellerLandingPagePotentialProductCommissionRate">
                        <img src={ic_sparkle} alt="commission" style={{ width: "20px", height: "20px" }} />
                        &nbsp;
                        <p style={{ fontSize: "14px" }}>profit</p>
                      </div>
                      <div className="ResellerLandingPagePotentialProductCommissionNominal">Rp 81,744</div>
                    </div>
                  </div>
                </div>
              </div>
              {/* MLB ITEM */}
              <div style={{ display: "flex", justifyContent: "left" }}>
                <div className="ResellerLandingPageCommissionSimulationItem">
                  <img src={mlbHat} alt="mlb_hat" />
                  <SizedDiv width={4} />
                  <div className="ResellerLandingPageCommissionSimulationDescription">
                    <b>MLB</b>
                    <p>Rp 789,210</p>
                    <SizedDiv height={8} />
                    <p>wholesale price (-10%)</p>
                    <b>Rp 710,289</b>
                    <SizedDiv height={4} />
                    <p>your own resell price</p>
                    <b>Rp 800,000</b>
                    <SizedDiv height={4} />
                    <div className="ResellerLandingPagePotentialProductCommissionCard">
                      <div className="ResellerLandingPagePotentialProductCommissionRate">
                        <img src={ic_sparkle} alt="commission" style={{ width: "20px", height: "20px" }} />
                        &nbsp;
                        <p style={{ fontSize: "14px" }}>profit</p>
                      </div>
                      <div className="ResellerLandingPagePotentialProductCommissionNominal">Rp 89,711</div>
                    </div>
                  </div>
                </div>
              </div>
              {/* AMBLER ITEM */}
              <div style={{ display: "flex", justifyContent: "left" }}>
                <div className="ResellerLandingPageCommissionSimulationItem">
                  <img
                    src="https://images.thefepi.com/files/1/products/files/files/134601/amb3.jpg"
                    alt="ambler_shirt"
                  />
                  <SizedDiv width={4} />
                  <div className="ResellerLandingPageCommissionSimulationDescription">
                    <b>Ambler</b>
                    <p>Rp 586,500</p>
                    <SizedDiv height={8} />
                    <p>wholesale price (-10%)</p>
                    <b>Rp 527,850</b>
                    <SizedDiv height={4} />
                    <p>your own resell price</p>
                    <b>Rp 600,000</b>
                    <SizedDiv height={4} />
                    <div className="ResellerLandingPagePotentialProductCommissionCard">
                      <div className="ResellerLandingPagePotentialProductCommissionRate">
                        <img src={ic_sparkle} alt="commission" style={{ width: "20px", height: "20px" }} />
                        &nbsp;
                        <p style={{ fontSize: "14px" }}>profit</p>
                      </div>
                      <div className="ResellerLandingPagePotentialProductCommissionNominal">Rp 72,150</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SizedDiv height={16} />
          <div className="ResellerLandingPageProse">
            Hanya dengan menjual 3 pcs produk di atas dalam sehari, maka keuntungan kamu perbulan adalah
          </div>
          <SizedDiv height={12} />
          <div style={{ justifyContent: "center", display: "flex" }}>
            <div className="ResellerLandingPageCommissionSimulationCalculation">
              <div>Rp 243,605 x 30 =</div>
              <p>Rp 7,308,150</p>
            </div>
          </div>
          <SizedDiv height={12} />
          <div className="ResellerLandingPageProse">
            <p>Kesempatan meraih komisi lebih banyak terbuka lebar!</p>
            <p>Cukup promosikan tokomu dan biarkan kami yang mengurus semua penjualanmu✨</p>
          </div>
          <SizedDiv height={24} />
        </div>
      </div>

      <SizedDiv height={26} />
      <div id="ResellerLandingPageDropshipSection" className="ResellerLandingPageWholesaleOrderSection">
        <div className="ResellerLandingPageWholeSaleOrderSectionHeadingPicture">
          <img alt="wholesale" src={shoes_your_brand} />
        </div>
        <div className="ResellerLandingPageWholesaleOrderSectionContent">
          <div className="ResellerLandingPageWholesaleOrderSectionHeadingTitleContainer">
            <div className="ResellerLandingPageWholesaleOrderSectionHeadingTitle">#2&nbsp;&nbsp;Dropship</div>
          </div>
          <SizedDiv height={38} />
          <div className="ResellerLandingPageProse">
            Reselling produk tanpa ribet! Cukup tambahkan produk ke toko dan pasarkan. Semua order yang masuk akan
            langsung dikirim ke customer Anda.
          </div>
          <SizedDiv height={16} />
          <div style={{ textAlign: "left", display: "flex", flexDirection: "column", gap: "8px" }}>
            <div className="ResellerLandingPageListWithCustomBullet">
              <div className="ResellerLandingPageListWithCustomBulletImage">
                <img alt="star" src={ic_star}></img>
              </div>
              Komisi langsung masuk saat order selesai
            </div>
            <div className="ResellerLandingPageListWithCustomBullet">
              <div className="ResellerLandingPageListWithCustomBulletImage">
                <img alt="star" src={ic_star}></img>
              </div>
              Tanpa stock up produk
            </div>
            <div className="ResellerLandingPageListWithCustomBullet">
              <div className="ResellerLandingPageListWithCustomBulletImage">
                <img alt="star" src={ic_star}></img>
              </div>
              Tanpa ribet packing
            </div>
          </div>
          <SizedDiv height={32} />
          <div style={{ fontSize: "20px", textAlign: "center" }}>
            <b>Cara mudah dropship</b>
          </div>
          <SizedDiv height={12} />
          {howToDropship.map((c, i) => (
            <>
              <div className="ResellerLandingPageHowToSectionStep">
                <div className="ResellerLandingPageHowToSectionStepAsset">
                  <div className="ResellerLandingPageHeadline">{i + 1}</div>
                  <SizedDiv width={8} />
                  <img alt="wholesaleOrder1" style={{ width: "160px" }} src={c.icon} />
                </div>
                <div className="ResellerLandingPageProse">{c.text}</div>
              </div>
              <SizedDiv height={12} />
            </>
          ))}
          <SizedDiv height={32} />
          <div className="ResellerLandingPageHeadline">Simulasi komisi</div>
          <SizedDiv height={32} />
          <div className="ResellerLandingPageProse">
            Mengambil contoh produk ALDV. MLB, dan AMBLER, sebagai salah satu best selling brand dari kami.
          </div>
          <SizedDiv height={24} />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="ResellerLandingPageCommissionSimulationContent">
              {/* commisssion items */}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div className="ResellerLandingPageCommissionSimulationItem">
                  <img src={adlv_shirt} alt="ADLV BABY FACE SHORT SLEEVE T-SHIRT BLACK BINOCULARS" />
                  <SizedDiv width={4} />
                  <div className="ResellerLandingPageCommissionSimulationDescription">
                    <p>ADLV</p>
                    <b>Rp 799,200</b>
                    <SizedDiv height={4} />
                    <div className="ResellerLandingPagePotentialProductCommissionCard">
                      <div className="ResellerLandingPagePotentialProductCommissionRate">
                        <img src={ic_commission} alt="commission" style={{ width: "20px", height: "20px" }} />
                        &nbsp;
                        <p style={{ fontSize: "14px" }}>{`commission (7%)`}</p>
                      </div>
                      <div className="ResellerLandingPagePotentialProductCommissionNominal">Rp 55,944</div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div className="ResellerLandingPageCommissionSimulationItem">
                  <img src={mlbHat} alt="mlb hat" />
                  <SizedDiv width={4} />
                  <div className="ResellerLandingPageCommissionSimulationDescription">
                    <p>MLB</p>
                    <b>Rp 789,210</b>
                    <SizedDiv height={4} />
                    <div className="ResellerLandingPagePotentialProductCommissionCard">
                      <div className="ResellerLandingPagePotentialProductCommissionRate">
                        <img src={ic_commission} alt="commission" style={{ width: "20px", height: "20px" }} />
                        &nbsp;
                        <p style={{ fontSize: "14px" }}>{`commission (10%)`}</p>
                      </div>
                      <div className="ResellerLandingPagePotentialProductCommissionNominal">Rp 78,921</div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div className="ResellerLandingPageCommissionSimulationItem">
                  <img
                    src="https://images.thefepi.com/files/1/products/files/files/134601/amb3.jpg"
                    alt="ambler shirt"
                  />
                  <SizedDiv width={4} />
                  <div className="ResellerLandingPageCommissionSimulationDescription">
                    <p>Ambler</p>
                    <b>Rp 586,500</b>
                    <SizedDiv height={4} />
                    <div className="ResellerLandingPagePotentialProductCommissionCard">
                      <div className="ResellerLandingPagePotentialProductCommissionRate">
                        <img src={ic_commission} alt="commission" style={{ width: "20px", height: "20px" }} />
                        &nbsp;
                        <p style={{ fontSize: "14px" }}>{`commission (10%)`}</p>
                      </div>
                      <div className="ResellerLandingPagePotentialProductCommissionNominal">Rp 58,650</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SizedDiv height={16} />
          <div className="ResellerLandingPageProse">
            Hanya dengan menjual 3 pcs produk di atas dalam sehari, maka keuntungan kamu perbulan adalah
          </div>
          <SizedDiv height={12} />
          <div style={{ justifyContent: "center", display: "flex" }}>
            <div className="ResellerLandingPageCommissionSimulationCalculation">
              <div>Rp 193.515 x 30 =</div>
              <p>Rp 5.805.450</p>
            </div>
          </div>
          <SizedDiv height={12} />
          <div className="ResellerLandingPageProse">
            <p>Kesempatan meraih komisi lebih banyak terbuka lebar!</p>
            <p>Cukup promosikan tokomu dan biarkan kami yang mengurus semua penjualanmu✨</p>
          </div>
          <SizedDiv height={24} />
        </div>
        <SizedDiv height={48} />
      </div>

      <div className="ResellerLandingPageSupportSection">
        <SizedDiv height={48} />
        <div className="ResellerLandingPageSupportSectionHeader">
          <div className="ResellerLandingPageHeadline" style={{ width: "100%" }}>
            Support Penuh oleh
          </div>
          <SizedDiv height={4} />
          <img src={thefepiLogoFull} alt="TheFepi" />
        </div>
        <SizedDiv height={32} />
        <div className="ResellerLandingPageSupportSectionBrandsHeader">
          <div className="ResellerLandingPageNumberHeadline">200+</div>
          <SizedDiv height={8} />
          <div className="ResellerLandingPageHeadline">Premium Brands</div>
        </div>
        <SizedDiv height={32} />
        <div className="ResellerLandingPageSupportSectionBrandsContainer">
          {/* <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates nemo numquam delectus aperiam excepturi possimus ratione ipsa amet quod ut! Enim soluta unde magnam provident non. Nemo sequi quia earum!</div> */}
          <div className="ResellerLandingPageSupportSectionBrandsContents">
            {brands.map((brand) => (
              <>
                <Link
                  className="ResellerLandingPageSupportSectionBrand"
                  to={
                    "https://" +
                    (process.env.REACT_APP_ENV === "production" ? "" : "dev.") +
                    "m.thefepi.com/Brand/" +
                    brand.id
                  }
                  target="_blank"
                >
                  <img src={brand.imgUrl} alt={brand.name} />
                  <p>{brand.name}</p>
                </Link>
              </>
            ))}
            {/* <div style={{flexGrow: "1"}}></div> */}
          </div>
        </div>
        <div className="ResellerLandingPageSupportSectionBrandsOverlay">
          {/* <div className="ResellerLandingPageSupportSectionBrandsShadow" /> */}
          <button
            onClick={() =>
              window.open(
                "https://" + (process.env.REACT_APP_ENV === "production" ? "" : "dev.") + "m.thefepi.com/Brands",
                "_blank"
              )
            }
          >
            Lihat semua brand
          </button>
        </div>
      </div>

      <div className="ResellerLandingPagePotentialProductsSectionContainer">
        <SizedDiv height={32} />
        <div className="ResellerLandingPageNumberHeadline">36,000+</div>
        <SizedDiv height={8} />
        <div className="ResellerLandingPageHeadline">Potential Products</div>
        <SizedDiv height={32} />
        <div className="ResellerLandingPagePotentialProductsContents">
          {potentialProducts.map((pp, i) => (
            <>
              <Link
                className="ResellerLandingPagePotentialProductCard"
                to={
                  "https://" +
                  (process.env.REACT_APP_ENV === "production" ? "" : "dev.") +
                  "m.thefepi.com/Product/" +
                  pp.id
                }
                target="_blank"
              >
                <div className="ResellerLandingPagePotentialProductCommissionBadge">
                  <img src={ic_commission} alt="commission" />
                  <b>{pp.commission.rate}%</b>
                </div>
                <img
                  className="ResellerLandingPagePotentialProductImage"
                  src={pp.productImageUrls[0]}
                  alt={pp.productName}
                />
                <SizedDiv height={4} />
                <div className="ResellerLandingPagePotentialProductCommissionCard">
                  <div className="ResellerLandingPagePotentialProductCommissionRate">
                    <img src={ic_commission} alt="commission" />
                    &nbsp;
                    <p style={{ fontSize: "12px" }}>{`commission (${pp.commission.rate}%)`}</p>
                  </div>
                  <div className="ResellerLandingPagePotentialProductCommissionNominal">
                    {convertToRupiah(pp.commission.nominal)}
                  </div>
                </div>
                <SizedDiv height={4} />
                <div className="ResellerLandingPagePotentialProductStatisticsCard">
                  <div className="ResellerLandingPagePotentialProductStatisticsViews">
                    <div>
                      <img src={ic_view} alt="views" />
                      &nbsp;views
                    </div>
                    <p>{pp.views}</p>
                  </div>
                  <div className="ResellerLandingPagePotentialProductStatisticsDivider" />
                  <div className="ResellerLandingPagePotentialProductStatisticsBuys">
                    <div>
                      <img src={ic_purchase} alt="buys" />
                      &nbsp;buys
                    </div>
                    <p>{pp.buys}</p>
                  </div>
                </div>
                <SizedDiv height={4} />
                <div className="ResellerLandingPagePotentialProductBrandName">{pp.brand.name}</div>
                <div className="ResellerLandingPagePotentialProductName">{pp.productName}</div>
                <div className="ResellerLandingPagePotentialProductPrice">{convertToRupiah(pp.price.netPrice)}</div>
                {pp.price.discount > 0 ? (
                  <div className="ResellerLandingPagePotentialProductOriginalPriceContainer">
                    <span className="ResellerLandingPagePotentialProductOriginalPrice">
                      {convertToRupiah(pp.price.realPrice)}
                    </span>

                    <span className="ResellerLandingPagePotentialProductDiscount">-{pp.price.discount}%</span>
                  </div>
                ) : (
                  ""
                )}
              </Link>
            </>
          ))}
          {/* {potentialProducts.length%2===0 ? "" : 
          <div style={{flexGrow: "1"}}></div>} */}
        </div>
      </div>
      <div className="ResellerLandingPageSupportSectionBrandsOverlay" style={{ paddingTop: "0" }}>
        {/* <div className="ResellerLandingPageSupportSectionBrandsShadow" /> */}
        <button
          onClick={() => {
            window.open(
              process.env.REACT_APP_ENV === "production"
                ? "https://m.thefepi.com/Store/Products?groupId=Home&categoryId=WeeklyProducts&detailId=All"
                : "https://dev.m.thefepi.com/Store/Products?groupId=Home&categoryId=WeeklyProducts&detailId=All",
              "_blank"
            );
          }}
        >
          Lihat semua produk
        </button>
      </div>
      <SizedDiv height={48} />

      {/* <div className="ResellerLandingPageCommissionSimulationSectionContainer">
        <SizedDiv height={48} />
        <div className="ResellerLandingPageHeadline">Simulasi komisi</div>
        <SizedDiv height={24} />
        <div className="ResellerLandingPageProse">
          Mengambil contoh produk ALDV. MLB, dan AMBLER, sebagai salah satu best selling brand dari kami.
        </div>
        <SizedDiv height={24} />
        <div className="ResellerLandingPageCommissionSimulationContent">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="ResellerLandingPageCommissionSimulationItem">
              <img
                src="https://images.thefepi.com/files/1/products/files/files/104080/3.jpg"
                alt="ADLV BABY FACE SHORT SLEEVE T-SHIRT BLACK BINOCULARS"
              />
              <SizedDiv width={4} />
              <div className="ResellerLandingPageCommissionSimulationDescription">
                <p>ADLV</p>
                <b>Rp 799,200</b>
                <SizedDiv height={4} />
                <div className="ResellerLandingPagePotentialProductCommissionCard">
                  <div className="ResellerLandingPagePotentialProductCommissionRate">
                    <img src={ic_commission} alt="commission" style={{ width: "20px", height: "20px" }} />
                    &nbsp;
                    <p>{`commission (5%)`}</p>
                  </div>
                  <div className="ResellerLandingPagePotentialProductCommissionNominal">Rp 39,960</div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="ResellerLandingPageCommissionSimulationItem">
              <img src={mlbHat} alt="mlb hat" />
              <SizedDiv width={4} />
              <div className="ResellerLandingPageCommissionSimulationDescription">
                <p>MLB</p>
                <b>Rp 789,210</b>
                <SizedDiv height={4} />
                <div className="ResellerLandingPagePotentialProductCommissionCard">
                  <div className="ResellerLandingPagePotentialProductCommissionRate">
                    <img src={ic_commission} alt="commission" style={{ width: "20px", height: "20px" }} />
                    &nbsp;
                    <p>{`commission (7%)`}</p>
                  </div>
                  <div className="ResellerLandingPagePotentialProductCommissionNominal">Rp 55,245</div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="ResellerLandingPageCommissionSimulationItem">
              <img src="https://images.thefepi.com/files/1/products/files/files/134601/amb3.jpg" alt="ambler shirt" />
              <SizedDiv width={4} />
              <div className="ResellerLandingPageCommissionSimulationDescription">
                <p>Ambler</p>
                <b>Rp 586,500</b>
                <SizedDiv height={4} />
                <div className="ResellerLandingPagePotentialProductCommissionCard">
                  <div className="ResellerLandingPagePotentialProductCommissionRate">
                    <img src={ic_commission} alt="commission" style={{ width: "20px", height: "20px" }} />
                    &nbsp;
                    <p>{`commission (10%)`}</p>
                  </div>
                  <div className="ResellerLandingPagePotentialProductCommissionNominal">Rp 58,650</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SizedDiv height={16} />
        <div className="ResellerLandingPageProse">
          Hanya dengan menjual 3 pcs produk di atas dalam sehari, maka keuntungan kamu perbulan adalah
        </div>
        <SizedDiv height={24} />
        <div style={{ justifyContent: "center", display: "flex" }}>
          <div className="ResellerLandingPageCommissionSimulationCalculation">
            <div>Rp 153.855 x 30 =</div>
            <p>Rp 4.615.650</p>
          </div>
        </div>
        <SizedDiv height={24} />
        <div className="ResellerLandingPageProse">
          <p>Kesempatan meraih komisi lebih banyak terbuka lebar!</p>
          <p>Cukup promosikan tokomu dan biarkan kami yang mengurus semua penjualanmu✨</p>
        </div>
        <SizedDiv height={48} />
      </div> */}

      <div className="ResellerLandingPageDiskonEksklusifSectionContainer">
        <div className="ResellerLandingPageHeadline" style={{ color: "white", fontWeight: "600" }}>
          DISKON EKSKLUSIF!
          <br />
          Special Reseller SEMO
        </div>
        <SizedDiv height={24} />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img alt="eksklusif" src={diskonEksklusif} />
        </div>
        <SizedDiv height={24} />
        <div className="ResellerLandingPageDiskonEksklusifBrands" style={{ position: "relative" }}>
          <img
            style={{ position: "absolute", left: "9px", top: "62px" }}
            alt="plus-percent"
            src={diskonEksklusifPlusPercent}
          />
          <img
            style={{ position: "absolute", right: "0", bottom: "75px" }}
            alt="plus-percent"
            src={diskonEksklusifPlusPlus}
          />
          <div className="ResellerLandingPageDiskonEksklusifBrand">
            <img style={{ zIndex: "1" }} alt="mlb" src={mlbLogo} />
            MLB
          </div>
          <div className="ResellerLandingPageDiskonEksklusifBrand">
            <img alt="hapa-kristin" src={hapaKristin} />
            Hapa Kristin
          </div>
          <div className="ResellerLandingPageDiskonEksklusifBrand">
            <img alt="Pinkfong" src={pinkFong} />
            Pinkfong
          </div>
          <div className="ResellerLandingPageDiskonEksklusifBrand">
            <img style={{ zIndex: "1" }} alt="Pororo" src={pororo} />
            Pororo
          </div>
        </div>
        <SizedDiv height={24} />
        <div className="ResellerLandingPageProse" style={{ color: "white", fontWeight: "400", padding: "0 6px" }}>
          Temukan HARGA TERBAIK dengan DISKON yang lebih besar untuk berbagai produk dari brand official partner yang
          hadir di thefepi
        </div>
        <SizedDiv height={24} />
        <div className="ResellerLandingPageSebarKodeReferralSectionFooter">
          <button
            onClick={() => {
              window.open(
                // @ts-ignore
                `https://api.whatsapp.com/send?phone=${window.__config.DEFAULT_CS_PHONE_NUMBER}&text=Halo saya ingin bertanya terkait SPECIAL DISKON LEBIH BESAR di SEMO`,
                "_blank"
              );
            }}
          >
            Tanya lebih detail
          </button>
        </div>
      </div>

      <div className="ResellerLandingPageSubscriptionSectionContainer">
        <SizedDiv height={48} />
        <div className="ResellerLandingPageHeadline" style={{ color: "white", fontWeight: "600" }}>
          Biaya Berlangganan
        </div>
        <SizedDiv height={24} />
        <div className="ResellerLandingPageProse" style={{ color: "white", fontWeight: "400" }}>
          <p>Tertarik bergabung sebagai Reseller SEMO?</p>
          <p>Dapatkan HARGA PROMOSI yang super menarik!</p>
        </div>
        <SizedDiv height={24} />
        <div className="ResellerLandingPageSubscriptionPlans">
          {subscriptionPlans.map((sp, i) => (
            <>
              <div className="ResellerLandingPageSubscriptionCard">
                {sp.badgeText && (
                  <div className="ResellerLandingPageSubscriptionBadgeContainer">
                    <div className="ResellerLandingPageSubscriptionBadge">
                      <p>{sp.badgeText}</p>
                    </div>
                  </div>
                )}
                <div className="ResellerLandingPageSubscriptionCardHeader">
                  <p>{sp.name}</p>
                </div>
                <div className="ResellerLandingPageSubscriptionCardBody">
                  <p className="ResellerLandingPageTextCenter">IDR</p>
                  <b className="ResellerLandingPageTextCenter">{sp.price.netPrice}</b>
                  <p className="ResellerLandingPageLineThrough ResellerLandingPageTextCenter">
                    {sp.price.originalPrice}
                  </p>
                </div>
                <div className="ResellerLandingPageSubscriptionCardFooter">
                  <button
                    onClick={() => {
                      // @ts-ignore
                      // window.location.href = `https://${process.env.REACT_APP_ENV === "production" ? '' : 'staging.'}semo.co.id/register?plan=${i}`;
                      window.location.href = "/register?plan=" + i;
                    }}
                  >
                    Langganan
                  </button>
                </div>
              </div>
            </>
          ))}
        </div>
        <SizedDiv height={48} />
      </div>

      <div className="ResellerLandingPageSebarKodeReferralSection">
        <div className="ResellerLandingPageHeadline">Sebar Kode Referral</div>
        <SizedDiv height={24} />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img alt="referral" src={referral} style={{ height: "120px", width: "120px" }} />
        </div>
        <SizedDiv height={8} />
        <div className="ResellerLandingPageProse">Ajak kenalan jadi reseller, raih untung hingga jadi miliarder!</div>
        <SizedDiv height={24} />
        <div className="ResellerLandingPageSebarKodeReferralSectionFooter">
          <button
            onClick={() => {
              window.open(
                "https://drive.google.com/file/d/1nCYYyKAfxlhj5hYBGKzOFY1EDxArMawt/view?usp=sharing",
                "_blank"
              );
            }}
          >
            Baca lebih detail
          </button>
        </div>
      </div>

      <div className="ResellerLandingPageFAQSection">
        <SizedDiv height={32} />
        <div className="ResellerLandingPageHeadlineBig">FAQ</div>
        <SizedDiv height={16} />
        <div className="ResellerLandingPageDivider" />
        <SizedDiv height={16} />
        {faqs.map((f) => (
          <>
            {f.content.map((c, i) => (
              <React.Fragment>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      paddingLeft: 16,
                      paddingRight: 16,
                      fontWeight: 600,
                      fontSize: "14px",
                    }}
                  >
                    Q
                  </div>
                  <div style={{ fontSize: "14px" }}>
                    <div
                      style={{
                        fontWeight: 600,
                        marginBottom: 8,
                      }}
                    >
                      {c.q}
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: c.a }} />
                  </div>
                </div>

                <SizedDiv height={24} />
              </React.Fragment>
            ))}
          </>
        ))}
        <div className="ResellerLandingPageDivider" />
        <SizedDiv height={24} />
        <div
          className="ResellerLandingPageProseNotCentered"
          // style={{ paddingLeft: 24, paddingRight: 24 }}
        >
          Apabila kamu memiliki pertanyaan lebih lanjut dan belum tercantum di sini, silakan kontak Customer Service
          kami, ya. Kami akan standby 24 jam untuk kamu. :)
        </div>
      </div>
      <SizedDiv height={124} />
      <div className="ResellerLandingPageFooter" style={{ zIndex: "2" }}>
        <div
          className="ResellerLandingPageFooterChatIcon"
          onClick={() => {
            window.open(
              // @ts-ignore
              `https://api.whatsapp.com/send?phone=${window.__config.DEFAULT_CS_PHONE_NUMBER}&text=Halo saya ingin bertanya terkait SEMO`,
              "_blank"
            );
          }}
        >
          <img src={ic_contact_cs} alt="Contact CS" />
        </div>
        <button
          onClick={() => {
            // @ts-ignore
            window.location.href = `https://${
              process.env.REACT_APP_ENV === "production" ? "" : "staging."
            }semo.co.id/register`;
          }}
        >
          Gabung Sekarang!
        </button>
      </div>
    </div>
  );
};

export default ResellerLandingPage;
