import React from "react";
import "./StoreBannersProductListWidget.css";
import ProductSummaryItemModel from "../../../../CommonComponents/Models/ProductSummaryItemModel";
import { Swiper, SwiperSlide } from "swiper/react";
import HorizontalProductListItem from "./HorizontalProductListItem";
import {
  convertToRupiah,
  standarizeThefepiLink,
} from "../../../../CommonComponents/Utils";
import ProgressBar from "./ProgressBar";

export type StoreBannersProductListWidgetProps = {
  title?: string;
  subtitle?: string;
  bannerWidth?: number;
  deeplink?: string;
  caption?: string;
  screenWidth: number;
  slideProps?: {
    pairedProducts?: ProductSummaryItemModel[];
    products?: ProductSummaryItemModel[];
    bannerImageUrl?: string;
    caption?: string;
    deeplink?: string;
  }[];
};

export default class StoreBannersProductListWidget extends React.Component<
  StoreBannersProductListWidgetProps,
  { activeIndex: number }
> {
  constructor(props: StoreBannersProductListWidgetProps) {
    super(props);
    this.state = {
      activeIndex: 1,
    };
  }

  render(): React.ReactNode {
    return (
      <div style={{ overflow: "hidden" }} id="StoreBannersProductListWidget">
        <div id="StoreBannersProductListWidgetHeader">
          <div>
            <div id="StoreBannersProductListWidgetTitle">
              {this.props.title}
            </div>
            <div id="StoreBannersProductListWidgetSubtitle">
              {this.props.subtitle}
            </div>
          </div>
          {this.props.deeplink && (
            <a
              href={standarizeThefepiLink(this.props.deeplink)}
              id="StoreBannersProductListWidgetLink"
            >
              See all
            </a>
          )}
        </div>
        <div
          id="StoreBannersProductListWidgetPagerView"
          style={{ display: "flex" }}
        >
          {/* 393 - 1.045 */}
          {/* 488 - 1.045 */}
          <Swiper
            style={{
              padding: "0px 16px",
            }}
            onSlideChange={(x) => {
              this.setState({
                activeIndex: x.activeIndex + 1,
              });
            }}
            spaceBetween={16}
            slidesPerView={1.044}
            centeredSlides={true}
            centeredSlidesBounds={true}
          >
            {this.props.slideProps?.map((p) => (
              <SwiperSlide>
                <div>
                  <a href={standarizeThefepiLink(p.deeplink || "")}>
                    <div
                      style={{
                        width: this.props.bannerWidth,
                        height: (this.props.bannerWidth || 1) * (274 / 343),
                        borderRadius: 12,
                        overflow: "hidden",
                        marginBottom: 16,
                        position: "relative",
                      }}
                    >
                      {p.caption && (
                        <div id="StoreBannersProductListWidgetOverlay">
                          {p.caption}
                        </div>
                      )}
                      <div
                        style={{
                          backgroundImage: `url(${p.bannerImageUrl})`,
                          height: "100%",
                          width: "100%",
                          backgroundSize: "cover",
                        }}
                      />
                    </div>
                  </a>

                  {[...(p.products || [])].slice(0, 3).map((x) => (
                    <HorizontalProductListItem
                      badgeUrls={x.badgeUrls}
                      id={x.id}
                      title={x.brand}
                      productImageUrl={x.imageUrl}
                      productImageWidth={(this.props.screenWidth / 375) * 80}
                      productImageHeight={
                        (this.props.screenWidth / 375) * 106.67
                      }
                      discountedPrice={convertToRupiah(x.price.netPrice)}
                      subtitle={x.title}
                      realPrice={
                        x.price.discount > 0
                          ? convertToRupiah(x.price.realPrice)
                          : ""
                      }
                      discount={
                        x.price.discount > 0 ? `${x.price.discount}%` : ""
                      }
                    />
                  ))}
                  {(p.pairedProducts?.length || 0) > 0 && (
                    <p id="StoreBannersProductListWidgetPairTitle">
                      You can pair with
                    </p>
                  )}
                  {[...(p.pairedProducts || [])].map((x) => (
                    <HorizontalProductListItem
                      badgeUrls={x.badgeUrls}
                      id={x.id}
                      title={x.brand}
                      productImageUrl={x.imageUrl}
                      productImageWidth={(this.props.screenWidth / 375) * 80}
                      productImageHeight={
                        (this.props.screenWidth / 375) * 106.67
                      }
                      discountedPrice={convertToRupiah(x.price.netPrice)}
                      subtitle={x.title}
                      realPrice={convertToRupiah(x.price.realPrice)}
                      discount={`${x.price.discount}%`}
                    />
                  ))}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div style={{ marginTop: 16, marginLeft: 16, marginRight: 16 }}>
          <ProgressBar
            progress={
              this.state.activeIndex / (this.props.slideProps?.length || 1)
            }
          />
        </div>
        <div id="StoreBannersProductListWidgetPageIndicator">
          {`${this.state.activeIndex}/${this.props.slideProps?.length || 1}`}
        </div>
      </div>
    );
  }
}
