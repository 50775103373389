import React from "react";
import BaseUIComponent from "../../CommonComponents/Base/BaseUIComponent"

import Scaffold, { ShowSnackbarModel } from "../../CommonComponents/UI/Scaffold/Scaffold";

import CreatorRegistrationViewModel from "./CreatorRegistrationViewModel";

import { Navigate  } from "react-router-dom";
import "./CreatorRegistration.css";

export default class CreatorRegistrationSuccessScreen extends BaseUIComponent<{}, CreatorRegistrationViewModel> {
    constructor(props: {}) {
        super(props, new CreatorRegistrationViewModel());
    }

    handleSubmit = (event: any) => {
        this.viewModel().onSubmit();
    }

    render() {
        if (this.viewModel().isSuccess) {
            return <Navigate  to='/CreatorRegistrationSuccess'/>;
        }

        return(
            <Scaffold 
                showSnackbar={
                    this.viewModel().errorMessage ? 
                    new ShowSnackbarModel({
                        message: this.viewModel().errorMessage ?? "",
                    }) : undefined
                }>
                
                <div id="CreatorRegistrationSuccess" className="container">
                   
                    <div className="row">
                        <div className="col mtop30p text-center">
                                <h4 className="font18"><b>Terima kasih</b></h4>
                                <h4 className="font14">Kamu telah sukses mendaftar sebagai Creator The Fepi.<br></br> 
                                Kami akan menghubungi kamu dalam 7 hari kerja. Mohon ditunggu ya!</h4>
                        </div>
                    </div>
                </div>
            </Scaffold>
        );
    }
} 