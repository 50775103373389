import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import PaymentResponse from "./Models/PaymentResponse";
import { ShowErrorModel, ShowSnackbarModel } from "../../CommonComponents/UI/Scaffold/Scaffold";
import { Optional } from "../../CommonTypes";
import {
    WithCreatorLinkProps,
    getDeviceModel,
    getLocationQueries,
    isResellerDomain,
} from "../../CommonComponents/Utils";
import ViewModel from "../../CommonComponents/Base/ViewModel";
import { eventManager } from "../../Analytics/Events/Common";
import { AddPaymentInfoEvent } from "../../Analytics/Events/GA4/AddPaymentInfoEvent";
import PaymentMethodListResponse, { PaymentMethod } from "./Models/PaymentMethodListResponse";

export default class PaymentViewModel extends ViewModel {
    isLoading: boolean = true;
    isLoadingButtonSubmit: boolean = false;
    errorModel: ShowErrorModel | null = null;
    errorSnackBarModel: ShowSnackbarModel | null = null;
    errorMessage: string | null = null;
    Message: string | null = null;
    isSuccess: boolean = false;
    snackbarMessage: ShowSnackbarModel | null = null;
    isEnableBtn: boolean = false;
    navBarTransparency: number = 1;
    numberInCart: number = 0;
    fpAuth = localStorage.getItem("fpAuth");
    deviceId = localStorage.getItem("uuid");
    orderId: Optional<string>;
    point: Optional<number>;
    couponId: Optional<string>;
    couponCode: Optional<string>;
    shippingAddressId: Optional<string>;
    includeInsurances: Optional<string[]>;
    courierIds: Optional<string[]>;
    deliveryNotes: Optional<string>;
    creatorLink?: string;
    isSignedIn: boolean = Boolean(localStorage.fpAuth);
    paymentMethods?: PaymentMethod[];

    oldProps = {
        courierId: localStorage.getItem("courier_id"),
        orderId: localStorage.getItem("orderId"),
        point: localStorage.getItem("point"),
        deliveryNotes: localStorage.getItem("deliveryNotes"),
        includeShippingInsurance: localStorage.getItem("includeShippingInsurance"),
        shippingAddressId: localStorage.getItem("address_id"),
    };

    constructor(args: WithCreatorLinkProps) {
        super();
        this.creatorLink = args.creatorLink;
        const params = new URLSearchParams(window.location.search);
        this.orderId = params.get("orderId");
        this.point = parseInt(params.get("point") || "0");
        this.couponId = params.get("couponId");
        this.couponCode = params.get("couponCode");
        this.shippingAddressId = params.get("shippingAddressId");
        this.includeInsurances = params.get("includeInsurances")?.split(",") || [];
        this.courierIds = params.get("courierIds")?.split(",") || [];
        this.deliveryNotes = params.get("deliveryNotes");
        this.preloadPaymentList();
    }

    changeNavBarTransparency(value: number) {
        this.navBarTransparency = value;
        this.emit(() => {});
    }

    handleSubmitBCA() {
        eventManager.add({
            paymentType: "BCA",
        });
        eventManager.fire(AddPaymentInfoEvent);
        requestAPI(this.isSignedIn ? Endpoints.directBCA : Endpoints.directBCADevice, {
            parameters: this.isSignedIn
                ? {
                      fpAuth: localStorage.fpAuth,
                      deviceId: localStorage.uuid,
                      point: this.point,
                      couponId: this.couponId,
                      shippingAddressId: this.shippingAddressId,
                      courierIds: this.courierIds,
                      includeInsurances: this.includeInsurances,
                      deliveryNotes: this.deliveryNotes,
                      orderId: this.orderId,
                  }
                : {
                      fpAuth: localStorage.fpAuth,
                      deviceId: localStorage.uuid,
                      orderId: this.oldProps.orderId,
                      shippingAddressId: getLocationQueries()["shippingAddressId"],
                  },
            onSuccess: (response: BaseResponse<PaymentResponse>) => {
                eventManager.flush();       
                window.location.href = `${isResellerDomain() ?  `/shop/${this.creatorLink}/OrderReceipt/${this.orderId}` : '/OrderReceipt'}`;
            },
            onFailure: (error: string) => {
                console.log(error);
                this.emit(() => {
                    this.errorMessage = error;
                });
                this.emit(() => {
                    this.snackbarMessage = new ShowSnackbarModel({
                        message: error,
                        type: "error",
                    });
                });
            },
        });
    }

    handleSubmitMidtrans() {
        eventManager.add({
            paymentType: "MIDTRANS",
        });
        eventManager.fire(AddPaymentInfoEvent);

        requestAPI(localStorage.fpAuth === undefined ? Endpoints.checkoutOrderDevice : Endpoints.checkoutOrder, {
            parameters: {
                fpAuth: localStorage.fpAuth,
                deviceId: localStorage.uuid,
                point: this.point,
                couponCode: this.couponCode,
                couponId: this.couponId,
                shippingAddressId: getLocationQueries()["shippingAddressId"],
                courierIds: this.courierIds,
                includeInsurances: this.includeInsurances,
                deliveryNotes: this.deliveryNotes,
                orderId: this.orderId,
            },
            onSuccess: (response: BaseResponse<PaymentResponse>) => {
                eventManager.flush();
                process.env.REACT_APP_ENV === "production"
                    ? (window.location.href = "https://app.midtrans.com/snap/v3/redirection/" + response.data.token)
                    : (window.location.href =
                          "https://app.sandbox.midtrans.com/snap/v3/redirection/" + response.data.token);
            },
            onFailure: (error: string) => {
                this.emit(() => {
                    this.snackbarMessage = new ShowSnackbarModel({
                        message: error,
                        type: "error",
                    });
                });
            },
        });
    }

    handleSubmitShopeePayLater() {
        eventManager.add({
            paymentType: "SHOPEEPAY",
        });
        eventManager.fire(AddPaymentInfoEvent);

        requestAPI(localStorage.fpAuth === undefined ? Endpoints.checkoutOrderDevice : Endpoints.checkoutOrder, {
            parameters: {
                fpAuth: localStorage.fpAuth,
                deviceId: localStorage.uuid,
                orderId: this.orderId,
                point: this.point,
                couponId: this.couponId,
                couponCode: this.couponCode,
                shippingAddressId: getLocationQueries()["shippingAddressId"],
                includeInsurances: this.includeInsurances,
                courierIds: this.courierIds,
                deliveryNotes: this.deliveryNotes,
            },
            onSuccess: (response: BaseResponse<PaymentResponse>) => {
                console.log("device model",getDeviceModel(2))
                eventManager.flush();
                process.env.REACT_APP_ENV === "production"
                    ? (window.location.href =
                          "https://app.midtrans.com/snap/v3/redirection/" + response.data.token + "#/shopeepay")
                    : (window.location.href =
                          "https://app.sandbox.midtrans.com/snap/v3/redirection/" +
                          response.data.token +
                          (getDeviceModel(2) === "DesktopWeb" ? "#/shopeepay-qris" : "#/shopeepay"));
            },
            onFailure: (error: string) => {
                this.isLoading = false;
                this.emit(() => {
                    this.snackbarMessage = new ShowSnackbarModel({
                        message: error,
                        type: "error",
                    });
                });
            },
        });
    }

    handleSubmitPayment(paymentType: string) {
        if (paymentType === "BCA") {
            this.handleSubmitBCA();
        } else if (paymentType === "MIDTRANS") {
            this.handleSubmitMidtrans();
        } else if (paymentType === "SHOPEEPAY") {
            this.handleSubmitShopeePayLater();
        } else {
            this.emit(() => {
                this.snackbarMessage = new ShowSnackbarModel({
                    message: "unsupported payment type",
                    type: "error",
                });
            });
        }
    }

    onSnackbarIsFinished() {
        this.errorMessage = null;
        this.emit(() => {});
    }

    preloadPaymentList() {
        this.isLoading = true;
        requestAPI(this.isSignedIn ? Endpoints.paymentList : Endpoints.paymentListDevice, {
            parameters: {
                fpAuth: this.isSignedIn ? localStorage.fpAuth : "",
                deviceId: localStorage.uuid,
                deviceModel: getDeviceModel(2),
                orderId: this.orderId,
                language: "",
                appVersion: "",
            },
            onSuccess: (response: BaseResponse<PaymentMethodListResponse>) => {
                this.paymentMethods = response.data.paymentMethods;
                this.emit(() => {});
                this.isLoading = false;
            },
            onFailure: (error: string) => {
                this.isLoading = false;
                this.emit(() => {
                    this.snackbarMessage = new ShowSnackbarModel({
                        message: error,
                        type: "error",
                    });
                });
            },
        });
    }
}
