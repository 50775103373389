import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import ViewModel from "../../CommonComponents/Base/ViewModel";
import { ShowSnackbarModel } from "../../CommonComponents/UI/Scaffold/Scaffold";
import { TrackResellersOrderResponse } from "./Models/TrackResellersOrderResponse";

export default class ResellerStorefrontViewModel extends ViewModel {
  id?: string;
  loadTrackingResult?: TrackResellersOrderResponse;
  isLoadingTrackingResult: boolean = false;
  snackbar?: ShowSnackbarModel;

  constructor() {
    super();
    const params = new URLSearchParams(window.location.search);
    this.id = params.get("id") || "";
    if (this.id) {
      this.isLoadingTrackingResult = true;
      this.loadTracking();
    }
  }

  changeId(val?: string) {
    this.id = val;
    this.emit(() => {});
  }

  loadTracking() {
    this.emit(() => {
      this.isLoadingTrackingResult = true;
    });
    requestAPI(Endpoints.trackResellersOrder, {
      onSuccess: (result: BaseResponse<TrackResellersOrderResponse>) => {
        this.loadTrackingResult = result.data;
        this.isLoadingTrackingResult = false;
        this.emit(() => {});
      },
      onFailure: (message) => {
        this.emit(() => {
          this.isLoadingTrackingResult = false;
          this.snackbar = new ShowSnackbarModel({
            type: "error",
            message,
          });
        });
      },
      parameters: {
        fpAuth: localStorage.getItem("fpAuth"),
        orderId: this.id,
      },
    });
  }
}
