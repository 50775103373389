import React from "react";
import { Optional } from "../../../../CommonTypes";
import "./CheckoutCouponWidget.css";
import icChevronRight from "../../../../Assets/Images/ic_right_chevron_black.svg";

type CheckoutCouponWidgetProps = {
  availableCoupons?: number;
  couponName: Optional<string>;
  onClick?: () => void;
  onUnsetCoupon?: () => void;
};

export default class CheckoutCouponWidget extends React.Component<CheckoutCouponWidgetProps> {
  render() {
    const {
      availableCoupons = 0,
      couponName = "",
      onClick = () => {},
    } = this.props;

    return (
      <div className="container" id="CheckoutCouponWidget" role="button" onClick={onClick}>
        <div className="title" style={{ flex: 1 }}>
          Coupon
        </div>
        <p className="point" style={{
          color: couponName ? 'var(--red)' : 'inherit'
        }}>
          {couponName || `${availableCoupons} coupon(S)`}
        </p>
        {!couponName ? <img alt= "" src={icChevronRight} className="rightIcon"/> :  <img onClick={(e) => {
          e.stopPropagation();
          this.props.onUnsetCoupon?.();
        }} width="12" style={{marginLeft: 8}} src={global.ImgUrl+'shopable/btInputCancel@3x.png'} alt="btn_cancel"></img>}
      </div>
    );
  }
}
